import { useEffect, useState } from "react";
import { toast } from 'react-toastify';
import boletin from "../../img/BoletinIMG.png";
import previewcorte from "../../img/previewcorte.png";
import previewcorte3 from "../../img/previewcorte3.png";
import previewcorte2 from "../../img/previewcorte2.png";
import reporteCurso from "../../img/ReporteNotasCurso.png";
import RepoBoletinSemi from "./Boletines/boletin-semi";
import RepoCortePreventivo from "./CortePreventivo/cortePreventivo";
import RepoBoletinIntermedioLista from "./Boletines/boletin-intermedio-lista";
import ReporteNotasCurso from "./repo-notas-curso";
import AsignaturaPerdidaRepo from "./AsignaturaPerdida/AsignaturaPerdidaRepo";
import ReporteRankingCurso from "./repo-ranking-curso";
import utils from '../../utils/generalFunctions';
import types from "../../services/types";
import RepoObservaciones from "./Observaciones/observaciones";
import ReporteNotasAcumuladoCurso from "./repo-notas-curso-acumulado";
import ReporteNotasRecuperacionCurso from "./repo-notas-recuperacion-curso";

const DashboardInformesRepo = () => {
    const [reporteSelected, setReporteSelected] = useState(null); 
    const [showRanking, setShowRanking] = useState(false); 
    const [showFinalizacionPeriodo, setShowFinalizacionPeriodo] = useState(false); 

    const volverLista =  async () => {
        setReporteSelected(null);
    };

    useEffect(
        ()=>{
            cargarInformacionUsuario();
        },[])

    const cargarInformacionUsuario = () => {
        const user= utils.getUserInformation();
        if(user){
            const typeId=Number(user.userTypeId);
            if(typeId===types.userTypes.ADMIN ||
                typeId===types.userTypes.COODINATOR ||
                typeId===types.userTypes.WORKER ){
                setShowRanking(true);
                setShowFinalizacionPeriodo(true);
            }
        }
    };



    const showMensajeInfo = () => {
        toast.info("Este reporte se habilitara proximamente", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
    }
      
    return (
        <>
        {reporteSelected===null && (
          <div className="card p-3 filter-container">
            <h1>Informes</h1>
            <p>Selecciona el informe a consultar, genérelo para visualizar y proceda a imprimirlo.</p>
            
                <div className="row">
                    <div class="col-12 col-md-6 col-lg-3">
							<div class="card">

								<img class="card-img-top" src={boletin} alt="Boletin" />

								<div class="card-header px-4 pt-4">
									<h5 class="card-title mb-0">Boletines</h5>
									<div class="badge bg-warning my-2"></div>
								</div>
								<div class="card-body px-4 pt-2">
									<p className="text-muted card-text">Genera e imprime boletines escolares.</p>
								</div>
			    	  		    <div className="d-grid">
                                    <button className="btn btn-primary" onClick={()=>{ setReporteSelected(2); }}> Ver Informe </button>
                                </div> 
							</div>
					</div>
                    <div class="col-12 col-md-6 col-lg-3">
							<div class="card">

								<img class="card-img-top" src={previewcorte2} alt="Corte Intermedio" />

								<div class="card-header px-4 pt-4">
									<h5 class="card-title mb-0">Corte intermedio</h5>
									<div class="badge bg-warning my-2"></div>
								</div>
								<div class="card-body px-4 pt-2">
									<p className="text-muted card-text">Imprime informes de corte intermedio.</p>
								</div>
			    	  		    <div className="d-grid">
                                    <button className="btn btn-primary" onClick={()=>{ setReporteSelected(4); }}> Ver Informe </button>
                                </div> 
							</div>
					</div>
                    <div class="col-12 col-md-6 col-lg-3">
							<div class="card">

								<img class="card-img-top" src={previewcorte} alt="Corte Preventivo" />

								<div class="card-header px-4 pt-4">
									<h5 class="card-title mb-0">Corte preventivo</h5>
									<div class="badge bg-warning my-2"></div>
								</div>
								<div class="card-body px-4 pt-2">
									<p className="text-muted card-text">Imprime informes preventivos estudiantiles.</p>
								</div>
			    	  		    <div className="d-grid">
                                    <button className="btn btn-primary" onClick={()=>{ setReporteSelected(3); }}> Ver Informe </button>
                                </div> 
							</div>
					</div>
                    <div class="col-12 col-md-6 col-lg-3">
							<div class="card">

								<img class="card-img-top" src={previewcorte} alt="Boletin" />

								<div class="card-header px-4 pt-4">
									<h5 class="card-title mb-0">Reporte Observador</h5>
									<div class="badge bg-warning my-2"></div>
								</div>
								<div class="card-body px-4 pt-2">
									<p className="text-muted card-text">Genera informe del observador por curso.</p>
								</div>
			    	  		    <div className="d-grid">
                                    <button className="btn btn-primary" onClick={()=>{ setReporteSelected(8); }}> Ver Informe </button>
                                </div> 
							</div>
					</div>
                    <div class="col-12 col-md-6 col-lg-3">
							<div class="card">

								<img class="card-img-top" src={reporteCurso} alt="Boletin" />

								<div class="card-header px-4 pt-4">
									<h5 class="card-title mb-0">Notas por curso</h5>
									<div class="badge bg-warning my-2"></div>
								</div>
								<div class="card-body px-4 pt-2">
									<p className="text-muted card-text">Genera informes de notas por curso.</p>
								</div>
			    	  		    <div className="d-grid">
                                    <button className="btn btn-primary" onClick={()=>{ setReporteSelected(5); }}> Ver Informe </button>
                                </div> 
							</div>
					</div>
                    <div class="col-12 col-md-6 col-lg-3">
							<div class="card">

								<img class="card-img-top" src={reporteCurso} alt="Boletin" />

								<div class="card-header px-4 pt-4">
									<h5 class="card-title mb-0">Notas acumuladas por curso</h5>
									<div class="badge bg-warning my-2"></div>
								</div>
								<div class="card-body px-4 pt-2">
									<p className="text-muted card-text">Genera informes de notas acumuladas por curso.</p>
								</div>
			    	  		    <div className="d-grid">
                                    <button className="btn btn-primary" onClick={()=>{ setReporteSelected(9); }}> Ver Informe </button>
                                </div> 
							</div>
					</div>
                    <div class="col-12 col-md-6 col-lg-3">
							<div class="card">

								<img class="card-img-top" src={reporteCurso} alt="Boletin" />

								<div class="card-header px-4 pt-4">
									<h5 class="card-title mb-0">Notas de recuperación por curso</h5>
									<div class="badge bg-warning my-2"></div>
								</div>
								<div class="card-body px-4 pt-2">
									<p className="text-muted card-text">Genera informes de notas de recuperación.</p>
								</div>
			    	  		    <div className="d-grid">
                                    <button className="btn btn-primary" onClick={()=>{ setReporteSelected(10); }}> Ver Informe </button>
                                </div> 
							</div>
					</div>
                    {showFinalizacionPeriodo && (
                    <div class="col-12 col-md-6 col-lg-3">
							<div class="card">

								<img class="card-img-top" src={previewcorte3} alt="Asignaturas perdidas" />

								<div class="card-header px-4 pt-4">
									<h5 class="card-title mb-0">Reporte de asignaturas perdidas</h5>
									<div class="badge bg-warning my-2"></div>
								</div>
								<div class="card-body px-4 pt-2">
									<p className="text-muted card-text">Imprime informe de asignaturas perdidas.</p>
								</div>
			    	  		    <div className="d-grid">
                                    <button className="btn btn-primary" onClick={()=>{ setReporteSelected(6); }}> Ver Informe </button>
                                </div> 
							</div>
					</div>
                    )}

                    {showRanking && (
                         <div class="col-12 col-md-6 col-lg-3">
                                <div class="card">

                                    <img class="card-img-top" src={reporteCurso} alt="Boletin" />

                                    <div class="card-header px-4 pt-4">
                                        <h5 class="card-title mb-0">Ranking de estudiantes por curso</h5>
                                        <div class="badge bg-warning my-2"></div>
                                    </div>
                                    <div class="card-body px-4 pt-2">
                                        <p className="text-muted card-text">Genera el ranking de estudiantes notas por curso.</p>
                                    </div>
                                    <div className="d-grid">
                                        <button className="btn btn-primary" onClick={()=>{ setReporteSelected(7); }}> Ver Informe </button>
                                    </div> 
                                </div>
                        </div>
                    )}

                  
                   
                               
                    {/* <div className="col-md-6 col-xxl-3 d-flex pointer" onClick={()=>{ setReporteSelected(2); }}>
                        <div class="card illustration flex-fill">
                            <div class="card-body p-0 d-flex flex-fill">
                                <div class="row g-0 w-100">
                                    <div class="col-6">
                                        <div class="illustration-text p-3 m-1">
                                            <h4 class="illustration-text">Consolidado total asistencias</h4>
                                            <p class="mb-0">por curso</p>
                                        </div>
                                    </div>
                                    <div class="col-6 align-self-end text-end">
                                        <i class="fa-solid fa-person-shelter font-size-80"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
          </div>
        )}

          {reporteSelected===3 && (
            <div>
              <button className="btn btn-secondary"  onClick={()=>{volverLista()}}><i className="fas fa-arrow-left"></i> Volver</button>
                <RepoCortePreventivo  />
            </div>
            )}

            {reporteSelected===2 && (
            <div>
              <button className="btn btn-secondary"  onClick={()=>{volverLista()}}><i className="fas fa-arrow-left"></i> Volver</button>
                <RepoBoletinSemi  />
            </div>
            )}

            {reporteSelected===4 && (
            <div>
              <button className="btn btn-secondary"  onClick={()=>{volverLista()}}><i className="fas fa-arrow-left"></i> Volver</button>
                <RepoBoletinIntermedioLista  />
            </div>
            )}

            {reporteSelected===5 && (
            <div>
              <button className="btn btn-secondary"  onClick={()=>{volverLista()}}><i className="fas fa-arrow-left"></i> Volver</button>
                <ReporteNotasCurso  />
            </div>
            )}

            {reporteSelected===6 && (
            <div>
              <button className="btn btn-secondary"  onClick={()=>{volverLista()}}><i className="fas fa-arrow-left"></i> Volver</button>
                <AsignaturaPerdidaRepo  />
            </div>
            )}

            {reporteSelected===7 && (
            <div>
              <button className="btn btn-secondary"  onClick={()=>{volverLista()}}><i className="fas fa-arrow-left"></i> Volver</button>
                <ReporteRankingCurso  />
            </div>
            )}

            {reporteSelected===8 && (
            <div>
              <button className="btn btn-secondary"  onClick={()=>{volverLista()}}><i className="fas fa-arrow-left"></i> Volver</button>
                <RepoObservaciones  />
            </div>
            )}

            {reporteSelected===9 && (
            <div>
              <button className="btn btn-secondary"  onClick={()=>{volverLista()}}><i className="fas fa-arrow-left"></i> Volver</button>
                <ReporteNotasAcumuladoCurso  />
            </div>
            )}

            {reporteSelected===10 && (
            <div>
              <button className="btn btn-secondary"  onClick={()=>{volverLista()}}><i className="fas fa-arrow-left"></i> Volver</button>
                <ReporteNotasRecuperacionCurso  />
            </div>
            )}

        </>
    );
  };
  
  export default DashboardInformesRepo;