import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { MDBModal, MDBModalDialog, MDBModalContent, MDBModalHeader, MDBModalTitle, MDBModalBody, MDBModalFooter } from 'mdb-react-ui-kit';
import DescuentoForm from '../components/Forms/DescuentoForm';
import EstudianteFilter from '../components/Filters/EstudianteFilter';
import discountsService from '../services/discountsService';
import JornadaAnioNivelCurso from '../components/Filters/JornadaAnioNivelCurso';
import moment from 'moment';
import Loading from '../components/Loading';
import utils from '../utils/generalFunctions';
import useCustomDataTableStyles from '../components/estilosDeDataTable';
import Swal from "sweetalert2";

const DescuentosEstudiantes = () => {
    const [descuentos, setDescuentos] = useState([]); // Estado para almacenar los descuentos
    const [modalOpen, setModalOpen] = useState(false); // Estado para manejar la visibilidad del modal
    const [selectedDescuento, setSelectedDescuento] = useState(null);
    const [filterText, setFilterText] = useState('');
    const [showLoading, setShowLoading] = useState(false);
    const [showSearch, setShowSearch] = useState(true);
    const [BtnVer, setBtnVer] = useState('');
    const [filters, setFilters] = useState({ // Estado para los filtros aplicados
        studentId: '0',
        journeyId: '0',
        schoolYearId: '0',
        educationLevelId: '0',
        courseId: '0',
    });

    const toggleModal = () => {
      setModalOpen(!modalOpen);
      setSelectedDescuento(null);
    };

    const openMaximizeModal = (billingConcept) => {
      setSelectedDescuento(billingConcept);
      setModalOpen(true);
      setBtnVer(true);
    };

    const openEditModal = (billingConcept) => {
      setSelectedDescuento(billingConcept);
      setModalOpen(true);
      setBtnVer(false);
    };

    const openNewDescuentoModal = () => {
        setShowSearch(true);
        setModalOpen(true);
    };

    const closeModal = () => {
      setModalOpen(false);
    };

    const deleteDescuento = async(discount) => {
        Swal.fire({
            title: '¿Está seguro que desea eliminar este descuento?',
            text: "",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, eliminar!'
          }).then(async(result) => {
            if (result.isConfirmed) {
              try {
                await discountsService.deleteBillingItem(discount.billingDiscountId);
                Swal.fire(
                  '¡Felicidades!',
                  'Descuento eliminado exitosamente!',
                  'success'
                );
                await buscarDescuentos();
              } catch(error) {
                Swal.fire(
                    "Ha ocurrido un error",
                    "Por favor intentelo más tarde",
                    "error"
                );
              }
            }
          })
    }

    const fetchDescuentos = async () => {
        try {
            const response = await discountsService.getAllBillingDescuentos(filters.studentId,  filters.journeyId, filters.schoolYearId, filters.educationLevelId, filters.courseId);
            setDescuentos(response); // Actualiza el estado con los descuentos obtenidos
        } catch (error) {
            console.error("Error al obtener los descuentos:", error);
        }
    };

    const handleFilterChange = (newFilters) => {
        setFilters(prev => ({
            ...prev,
            ...newFilters
        }));
    };

    const columns = [
        { name: "Estudiante", selector: row => row.studentName, sortable: true, grow: 2 },
        { name: "Curso", selector: row => row.courseName, sortable: true },
        { name: "Concepto", selector: row => row.billingConceptName, sortable: true },
        { name: "Descuento", selector: row => row.discountValue, format: row => `${utils.formatNumber.new(row.discountValue, (row.discountTypeName!=="Porcentaje")?"$ ":"% ")}`, sortable: true },
        { name: "Tipo", selector: row => row.discountTypeName, sortable: true },
        { name: "Año", selector: row => row.year, sortable: true },
        { name: "Inicio", selector: row => row.startDate, format: row => moment(row.startDate).format('DD-MM-YYYY'), sortable: true },
        { name: "Final", selector: row => row.endDate, format: row => moment(row.endDate).format('DD-MM-YYYY'), sortable: true },
        {
          name: "Acciones",
          cell: (row) => (
                <div className="btn-group btn-group-sm">
                <button
                  onClick={() => openMaximizeModal(row)}
                  className="btn btn-outline-success me-1"
                  title="Maximizar información"
                >
                  <i className="fas fa-eye"></i>
                </button>
                <button
                  onClick={() => openEditModal(row)}
                  className="btn btn-outline-info me-1"
                  title="Editar"
                >
                  <i className="icon-edit fas fa-pencil-alt"></i>
                </button>
                <button
                  onClick={() => deleteDescuento(row)}
                  className="btn btn-outline-danger me-1"
                  title="Eliminar"
                >
                  <i className="icon-deactivate fas fa-times"></i>
                </button>
              </div>
          ),
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
        }
    ];

    const buscarDescuentos = async (e) => {
      e.preventDefault();
      setShowLoading(true);
      await fetchDescuentos();
      setShowLoading(false);
      setShowSearch(true);
    };

    const customStyles = useCustomDataTableStyles();

    const normalizeString = (str) => {
      return str
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase();
    };

    const filteredItems = descuentos?.filter((item) => {
      if (item) {
        const allData = `${item.fullName} ${item.identification} ${item.grade} ${item.email} ${item.cellPhone} ${item.parentName} ${item.parentCellPhone} ${item.parentEmail}`;
        const normalizedFilterText = normalizeString(filterText);
        const normalizedItemName = normalizeString(allData);
        return normalizedItemName.includes(normalizedFilterText);
      }
      return item;
    });

    return (
      <>
        <div className="col-md-12">
          <div className="card p-3">
            <div className="card-header">
              <h2>Gestión de Descuentos por Estudiantes</h2>
              <h5 className="card-subtitle text-muted">Vista extendida de los descuentos anuales para los estudiantes.</h5>
            </div>
            <div className="card-body">
              <form className="row mb-3">
                <EstudianteFilter
                  callback={handleFilterChange}
                  properties={{
                    className: 'col-md-4 mb-2',
                  }}
                />
                <JornadaAnioNivelCurso
                  callback={handleFilterChange}
                  properties={{
                    className: 'col-md-4 mb-2',
                    id: 'formTest',
                    filtro: true,
                  }}
                />
                <div className="col-md-4 mb-2 d-flex align-items-end">
                  <button
                    onClick={buscarDescuentos}
                    className="btn btn-primary w-100"
                  >
                    Buscar <i className="fa-solid fa-search"></i>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div className="card p-3">
          <div className="row">
            <div className="text-end mb-3">
              <button className="btn btn-primary" onClick={openNewDescuentoModal}>Crear Descuento <i className="fa-solid fa-circle-plus"></i></button>
            </div>

            {showLoading && <Loading />}

            {showSearch && (
              <>
                {!showLoading && (
                  <>
                    {/* Tabla de descuentos */}
                    <div >
                      <DataTable
                          className="students-datatable shadow-sm rounded"
                          noDataComponent="No hay descuentos que mostrar"  
                          columns={columns}
                          data={filteredItems}
                          customStyles={customStyles}
                          fixedHeader
                          fixedHeaderScrollHeight="450px"
                          // Añade aquí más propiedades si es necesario
                      />
                    </div>
                    {/* Modal para el formulario de descuento */}
                    <MDBModal show={modalOpen} setShow={setModalOpen} tabIndex='-1'>
                      <MDBModalDialog className="modal-xl-custom">
                        <MDBModalContent>
                          <MDBModalHeader>
                            <MDBModalTitle>Nuevo Descuento</MDBModalTitle>
                            <button className='btn-close' color='none' onClick={closeModal}></button>
                          </MDBModalHeader>
                          <MDBModalBody>
                            <div className=''>
                              {selectedDescuento && (
                                  <DescuentoForm
                                      key={Date.now()}
                                      billingDiscountId={selectedDescuento.billingDiscountId}
                                      onClose={() => {
                                          setModalOpen(false); 
                                          fetchDescuentos();
                                      }}
                                      ReadOnly={BtnVer}
                                  />
                              )}

                              {!selectedDescuento && (
                                  <DescuentoForm
                                      key={Date.now()}
                                      billingDiscountId={0}
                                      onClose={() => {
                                          setModalOpen(false); 
                                          fetchDescuentos();
                                      }}
                                      ReadOnly={false}
                                  />
                              )}
                            </div>
                          </MDBModalBody>
                          <MDBModalFooter>
                            <button className="btn btn-secondary" onClick={closeModal}>Cerrar</button>
                          </MDBModalFooter>
                        </MDBModalContent>
                      </MDBModalDialog>
                    </MDBModal>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </>
    );
};

export default DescuentosEstudiantes;
