import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import "../css/estudiantes.css";
import {
    MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
} from 'mdb-react-ui-kit';
import GradeForm from "../components/Forms/FormGrado"; // Asegúrate de tener este componente creado
import Swal from "sweetalert2";
import gradesService from "../services/gradesService";
import useCustomDataTableStyles from "../components/estilosDeDataTable";

const ConfigureGrades = () => {

    const [grades, setGrades] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedGrade, setSelectedGrade] = useState(null);
    const [filterText, setFilterText] = useState('');
    const [BtnVer, setBtnVer] = useState('');

    
    const toggleModal = () => {
        setModalOpen(!modalOpen);
        setSelectedGrade(null);
    };

    const openMaximizeModal = (grade) => {
        setSelectedGrade(grade);
        setModalOpen(true);
        setBtnVer(true);
    };

    const openEditModal = (grade) => {
        setSelectedGrade(grade);
        setModalOpen(true);
        setBtnVer(false);
    };

    const deleteGrade = async (grade) => {
      Swal.fire({
        title: 'Esta seguro que desea eliminar este grado?',
        text: "",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, eliminar!'
      }).then(async(result) => {
        if (result.isConfirmed) {
          try{
          await gradesService.deleteGrade(grade.gradeId);
          Swal.fire(
            'Felicidades!',
            'Grado eliminado exitosamente!',
            'success'
          );
          await fetchGrades();
          }catch(error){
            Swal.fire(
                "Ha ocurrido un error",
                "Por favor intentelo mas tarde",
                "error"
            );
          }
        }
      })
    };

    const fetchGrades = async () => {
      try {
        const response = await gradesService.getAllGrades();
        setGrades(response);
      } catch (error) {
          console.error("Error al obtener los grados academicos:", error);
      }
    };

    useEffect(() => {
        fetchGrades();
    }, []);

    const columns = [
      { name: "Número", selector: "consecutive", sortable: true, 
      cell: (row, index) => <span>{index + 1}</span>, },      
        { name: "Grado", selector: "name", sortable: true },
        { name: "Abreviación", selector: "abbreviation", sortable: true },
        { name: "Orden", selector: "order", sortable: true },
        { name: "Jornada", selector: "journeyName", sortable: true },
        { name: "Activo", selector: "isActive", sortable: true,
        cell: (row) => (
        <>
        {row.isActive ? <span>Si</span> : <span>No</span>}
        </>
        ), },
        {
            name: "Acciones",grow:"3",
            selector: "actions",
            cell: (row) => (
              <div className="btn-group btn-group-sm">
                <button
                  onClick={() => openMaximizeModal(row)}
                  className="btn btn-outline-success me-1"
                  title="Maximizar información"
                >
                  <i className="fas fa-eye"></i>
                </button>
                <button
                  onClick={() => openEditModal(row)}
                  className="btn btn-outline-info me-1"
                  title="Editar"
                >
                  <i className="icon-edit fas fa-pencil-alt"></i>
                </button>
                <button
                  onClick={()=> deleteGrade(row)}
                  className="btn btn-outline-danger me-1"
                  title="Eliminar"
                >
                  <i className="icon-deactivate fas fa-times"></i>
                </button>
              </div>
            ),
          },
    ];
    const normalizeString = (str) => {
        return str
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toLowerCase();
      };

    const filteredItems = grades?.filter((item) => {
        if (item) {
          const allData = `${item.name} ${item.abbreviation} ${item.order} ${item.journeyName} ${item.isActive}`;
          const normalizedFilterText = normalizeString(filterText);
          const normalizedItemName = normalizeString(allData);
          return normalizedItemName.includes(normalizedFilterText);
        }
        return item;
      });

      const customStyles = useCustomDataTableStyles();

      const paginationPerPage = 50; // Ajusta este número según tus necesidades

    return (
      <div className="col-md-12">
      <div className="card">
        <div className="card-header">
          <h2 className="">Configuración de Grados</h2>
          <h5 className="card-subtitle text-muted">Esta es la vista de configuración de grados Escolares.</h5>
        </div>
        <div className="card-body">
          <form className="row mb-3">
            <div className="col-md-6 col-xl-4 mb-2 mb-md-0">
              <input
                type="text"
                placeholder="Buscar"
                value={filterText}
                onChange={e => setFilterText(e.target.value)}
                className="form-control"
              />
            </div>
            <div className="col-md-6 col-xl-8">
              <div className="text-end">
                <button onClick={() => openEditModal(null)} className="btn btn btn-primary mb-2">
                  Agregar Nuevo Grado <i className="fa-solid fa-circle-plus"></i>
                </button>
              </div>
            </div>
          </form>
        </div>
    
            <DataTable
                title="Lista de Grados"
                noDataComponent="No hay información que mostrar, por favor añadela"   
                className="students-datatable shadow-sm rounded"
                columns={columns}
                data={filteredItems}
                pagination
                fixedHeader
                fixedHeaderScrollHeight="450px"
                customStyles={customStyles}
                paginationPerPage={paginationPerPage} // Agrega esta propiedad para controlar las filas por página
                paginationRowsPerPageOptions={[50, 100]}

            />
            <>
            <MDBModal tabIndex='-1' show={modalOpen} setShow={setModalOpen}>
                <MDBModalDialog className="modal-xl-custom">
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>DETALLE DEL GRADO</MDBModalTitle>
                            <MDBBtn className='btn-close' color='none' onClick={toggleModal}></MDBBtn>
                        </MDBModalHeader>
                        <MDBModalBody>
                            <div className='card'>
                                {selectedGrade && (
                                    <GradeForm
                                        key={Date.now()}
                                        gradoItem={selectedGrade.gradeId}
                                        onClose={() =>{
                                            setModalOpen(false); 
                                            fetchGrades();
                                        }}
                                        ReadOnly={BtnVer}
                                    />
                                )}

                                { !selectedGrade && (
                                    <GradeForm
                                        key={Date.now()}
                                        gradoItem={0}
                                        onClose={() =>{
                                            setModalOpen(false); 
                                            fetchGrades();
                                        }}
                                        ReadOnly={false}
                                    />
                                )}
                            </div>
                        </MDBModalBody>

                        <MDBModalFooter>
                        <button type='button' className='btn-footer-close btn-primary' onClick={toggleModal}>
                        Cerrar
                    </button>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
            </>
            </div>

        </div>
    );
};

export default ConfigureGrades;
