import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import "../css/estudiantes.css";
import estudiantesService from "../services/estudiantesService";
import {
    MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
  } from 'mdb-react-ui-kit';
import StudentForm from '../components/Forms/StudentForm';
import Loading from '../components/Loading';
import utils from "../utils/generalFunctions";
import useCustomDataTableStyles from "../components/estilosDeDataTable";



const Estudiantes = () => {
  
    const [fullscreenXlModal, setFullscreenXlModal] = useState(false);
    const [students, setStudents] = useState([]);
    const [selectedStudent, setSelectedStudent] = useState(null);
    const [filterText, setFilterText] = useState('');
    const [BtnVer, setBtnVer] = useState('');
    const [showEditButton, setShowEditButton] = useState(false);


    const toggleShow = () => {
        setFullscreenXlModal(!fullscreenXlModal);
        setSelectedStudent(null); 
    }

    const openMaximizeModal = (student) => {
        setSelectedStudent(student);
        setFullscreenXlModal(true);
        setBtnVer(true);
    };

    const openEditingModal = async (student) => {
        setSelectedStudent(student);
        setFullscreenXlModal(true);
        setBtnVer(false);
    };
    
    const openAddingModal = async (student) => {
        setSelectedStudent(student);
        setFullscreenXlModal(true);
        setBtnVer(false);
    };

    const fetchStudents = async () => {
        try {
            validarPermisos();
            const response = await estudiantesService.getEstudiantes();
            setStudents(response);
        } catch (error) {
            console.error("Error al obtener los estudiantes:", error);
        }
    };

    const validarPermisos = ()=> {
      const user = utils.getUserInformation();
      if(user && user.userTypeId<=4){
          setShowEditButton(true);
      }

    }

    useEffect(() => {
        fetchStudents();
    }, []);

  const columns = [
    { name: "ID", selector: "identification", sortable:true,grow:"2.5",},
    { name: "Nombre completo", selector: "fullName", sortable:true,grow:"6"}, // Asumiendo que 'nombre' contiene tanto el nombre como el apellido
    { name: "Grado", selector: "grade", sortable:true },
    { name: "Email", selector: "email", sortable:true,grow:"3", },
    { name: "Celular", selector: "cellPhone", sortable:true,grow:"2.5", },
    { name: "Acudiente", selector: "parentName", sortable:true,grow:"6", },
    { name: "Cel Acudiente", selector: "parentCellPhone", sortable:true,grow:"2.5", },
    { name: "Email Acudiente", selector: "parentEmail", sortable:true,grow:"3", },
    {
      name: "Acciones",grow:"3",
      selector: "actions",
      cell: (row) => (
        <div className="btn-group btn-group-sm">
          <button
            onClick={() => openMaximizeModal(row)}
            className="btn btn-outline-success me-1"
            title="Maximizar información"
          >
            <i className="fas fa-eye"></i>
          </button>
          {showEditButton && (
            <button
              onClick={() => openEditingModal(row)}
              className="btn btn-outline-info me-1"
              title="Editar"
            >
              <i className="icon-edit fas fa-pencil-alt"></i>
            </button>
          )}
        </div>
      ),
    },
  ];

 
  const normalizeString = (str) => {
    return str
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase();
  };

  const filteredItems = students?.filter((item) => {
    if (item) {
      const allData = `${item.fullName} ${item.identification} ${item.grade} ${item.email} ${item.cellPhone} ${item.parentName} ${item.parentCellPhone} ${item.parentEmail}`;
      const normalizedFilterText = normalizeString(filterText);
      const normalizedItemName = normalizeString(allData);
      return normalizedItemName.includes(normalizedFilterText);
    }
    return item;
  });

  const customStyles = useCustomDataTableStyles();

  const paginationPerPage = 50; // Ajusta este número según tus necesidades

  const actionsMemo = React.useMemo(() =>
  <button className=" btn btn-light btn me-2" onClick={() => { exportStudents(); }}>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" data-lucide="download" class="lucide lucide-download align-middle me-2"><path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path><polyline points="7 10 12 15 17 10"></polyline><line x1="12" x2="12" y1="15" y2="3"></line></svg> Exportar estudiantes
  </button>, []);

const exportStudents = async () => {
  const response = await estudiantesService.getEstudiantes();
  const dataMapped = response.map(item => ({
    "Identificación": item.identification??'',
    "Fecha de nacimiento":item.birthDate??'',
    "Primer apellido": item.firstLastName??'',
    "Segundo apellido": item.secondLastName??'',
    "Primer nombre": item.firstName??'',
    "Segundo nombre": item.secondName??'',
    "Nombre completo": item.fullName??'',
    "Grado": item.grade??'',
    "Año": item.schoolYear??'',
    "Jornada": item.journey??'',
    "Email":item.email??'',
    "Celular":item.cellPhone??'',
    "Acudiente":item.parentName??'',
    "Identificación acudiente":item.parentIdentification??'',
    "Cel Acudiente":item.parentCellPhone??'',
    "Email Acudiente":item.parentEmail??'',
  }));

  utils.exportDataToExcel(dataMapped, 'Lista de estudiantes');
};

  return (
    <>
    <div className="card p-3">
    <div className="card-header">
        <h2 className="">Administración de estudiantes</h2>
        <h5 className="card-subtitle text-muted">Esta es la vista de los estudiantes de todo el plantel educativo.</h5>
      </div>
      
      <div className="card-body">
      <form className="row mb-3">
      <div className="col-md-8 col-xl-4 mb-md-0">

      <input
                    type="text"
                    placeholder="Buscar estudiante"
                    value={filterText}
                    onChange={e => setFilterText(e.target.value)}
                    className="form-control "
                />            </div>

                {showEditButton && (
                  <div className="col-md-4 col-xl-8 ">

                <div className="text-end">
                  
                    <button className="btn btn-Estudiante btn-primary btn"  onClick={() => openAddingModal(null)} ><i className="fa-solid fa-circle-plus"></i> Nuevo estudiante</button>
                </div>
                </div>
                )}
                </form>
            </div>
            <hr class="my-4"></hr>
            <div>
            <DataTable
                title="Lista de estudiantes"
                className="students-datatable shadow-sm rounded"
                columns={columns}
                actions={actionsMemo}
                noDataComponent="No hay información que mostrar, por favor añadela"   
                data={filteredItems}
                pagination
                fixedHeader
                fixedHeaderScrollHeight="450px"
                customStyles={customStyles}
                paginationPerPage={paginationPerPage} // Agrega esta propiedad para controlar las filas por página
                paginationRowsPerPageOptions={[50, 100]}                

            />

</div>

             {/* Renderiza el modal y el formulario aquí */}
            <>
                <MDBModal tabIndex='-1' show={fullscreenXlModal} setShow={setFullscreenXlModal}>
                <MDBModalDialog className="modal-xl-custom">
                    <MDBModalContent>
                    <MDBModalHeader>
                        <MDBModalTitle>DETALLES DEL ESTUDIANTE</MDBModalTitle>
                        <MDBBtn
                        type='button'
                        className='btn-close'
                        color='none'
                        onClick={toggleShow}
                        ></MDBBtn>
                    </MDBModalHeader>
                    <MDBModalBody>
                        <div className='card'>
                            {selectedStudent && (
                                <StudentForm
                                    key={Date.now()}
                                    student={selectedStudent.identification}
                                    hideBtnInscription={true}
                                    onClose={() =>{
                                         setFullscreenXlModal(false); 
                                         fetchStudents();
                                    }}
                                    ReadOnly={BtnVer}
                                />
                            )}

                            { !selectedStudent && (
                                <StudentForm
                                    key={Date.now()}
                                    student={0}
                                    hideBtnInscription={true}
                                    onClose={() =>{
                                         setFullscreenXlModal(false); 
                                         fetchStudents();
                                    }}
                                    ReadOnly={false}
                                />
                            )}
                            </div>
                    </MDBModalBody>
                    <MDBModalFooter>
                    <button type='button' className='btn-footer-close btn-primary' onClick={toggleShow}>
                        Cerrar
                    </button>
                    </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
                </MDBModal>
            </>
            

        </div>
        </>
    );
};

export default Estudiantes;
