import React, { useEffect, useState } from 'react';
import userService from '../services/userService';
import userProfileImage from "../img/user-profile.png";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import Loading from '../components/Loading';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const UserProfile = () => {
  
  const [activeTab, setActiveTab] = useState('profile');
  const [showLoading, setShowLoading] = useState(false);
  const [userProfile, setUserProfile] = useState({
    userId:0,
    userName: '',
    identification: '',
    contraseña: '', // Asumiendo que este campo solo se muestra
    email: '',
    institutionalEmail: '',
    userTypeId:0,
    tipoUsuario: '',
    urlImage:null,
    showImageProfile:userProfileImage
  });

  const [passwords, setPasswords] = useState({
    currentPassword: '',
    newPassword: '',
    verifyPassword: '',
  });

  const cargarInformacionUsuario = async ()=>{
    const userBackend = await userService.getUserInformation();
    if(userBackend){
        setUserProfile((prevState) => ({ ...prevState, ...userBackend }));
        if (userBackend.urlImage) {
          setUserProfile((prevState) => ({
            ...prevState,
            showImageProfile: userBackend.urlImage,
          }));
        }
    }
  }

  useEffect(() => {
    cargarInformacionUsuario();
  }, []);
  // Manejo de cambios en el perfil del usuario
  const handleChangeProfile = (e) => {
    const { name, value } = e.target;
    setUserProfile(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Manejo del cambio de la imagen de perfil
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        document.getElementById("profileImg").src = event.target.result;
        setUserProfile(prevState => ({
          ...prevState,
          urlImage: file,
        }));
      };
      reader.readAsDataURL(file);
    }
  };
  // Manejo de cambios en las contraseñas
  const handleChangePassword = (e) => {
    const { name, value } = e.target;
    setPasswords(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Envío de datos del perfil del usuario
  const handleSubmitProfile = async (e) => {
    e.preventDefault();

    if (!userProfile.identification || // Identificación del docente
      !userProfile.userName || // Primer nombre
      !userProfile.institutionalEmail) {
      toast.error("Por favor, completa todos los campos obligatorios.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }

      setShowLoading(true);
      try {
        let userId = 0;
        if (userProfile.userId > 0) {
          const response = await userService.updateUserInformation(userProfile);
          userId = response.userId;
        } else {
          // const response = await userService.createUserInformation(formData);
          //userId = response.userId;
          //userId = 0;
        }
        
        if (
          userProfile.urlImage &&
          userProfile.urlImage !== userProfile.showImageProfile &&
          userId > 0
        ) {
            try {
              const imgResponse = await userService.subirImagenUsuario(
                userProfile.urlImage,
                userId
              );
              console.log(imgResponse);
            } catch (error) {
              console.error("Hubo un error al subir imagen :",error);
            }
        }
        
        Swal.fire(
          "Felicidades!",
          "Información guardada con éxito!",
          "success"
        ).then(async(result)=>{
          if (result.isConfirmed) {
              const userToken  = await userService.getUserToken(userId);
              const userAccount  = await userService.getUsersByAccount();
              const result = userToken.data;
              const token = result.token;
      
              const users =  userAccount.data;
              const uData = btoa(JSON.stringify(users));
              localStorage.setItem('_data', uData);
              localStorage.setItem('token', token);
              window.location.reload();
          }
        });
      } catch (error) {
        console.error("Hubo un error al guardar la información:", error);
        Swal.fire(
          "Ha ocurrido un error",
          "Por favor intentelo mas tarde",
          "error"
        );
      }
      setShowLoading(false);
  };

  // Envío del formulario de cambio de contraseña
  const handleSubmitPassword = async (e) => {
    e.preventDefault();
    
    if (passwords.newPassword !== passwords.verifyPassword) {
      toast.error("Por favor, verifica la nueva contraseña, las contraseñas no coinciden.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }

    setShowLoading(true);
    try {
      const result = await userService.updateUserPassword(passwords);
      if(result.data){
        Swal.fire(
          "Felicidades!",
          "Contraseña guardada con éxito!",
          "success"
        );
        setPasswords(prevState => ({
          ...prevState,
          currentPassword: '',
          newPassword: '',
          verifyPassword: '',
        }));
      }else{
        Swal.fire(
          "Ha ocurrido un error",
          "Por favor intentelo mas tarde",
          "error"
        );
      }
    } catch (error) {
      const message = error?.response?.data?.message??'Intente más tarde.';
      Swal.fire("Ha ocurrido un error",message,"error");
      console.error("Hubo un error al actualizar contraseña:",error);
    }
    setShowLoading(false);
    // Lógica para enviar los datos de cambio de contraseña
  };

  return (
    <div className="">
        <h1 className="h3 mb-3">Ajustes Generales</h1>

        <div className="container-fluid p-0">

            <div className="row">
              {/* Sidebar */}
              {/* Main content */}
                <div className="col-md-9 col-xl-12">
                    <div className="tab ">
                          <ul className="nav nav-tabs" role="tablist">
                            <li className="nav-item" role="presentation">
                              <button 
                                className={`nav-link ${activeTab === 'profile' ? 'active' : ''}`}
                                onClick={() => setActiveTab('profile')}
                              >
                                Perfil del Usuario
                              </button>
                            </li>
                            <li className="nav-item" role="presentation">
                              <button
                                className={`nav-link ${activeTab === 'password' ? 'active' : ''}`}
                                onClick={() => setActiveTab('password')}
                              >
                                Cambio de Contraseña
                              </button>
                            </li>
                          </ul>
                          <div className="tab-content">
                            {/* Perfil del Usuario */}
                              <div className={`tab-pane ${activeTab === 'profile' ? 'active show' : ''}`} id="profile" role="tabpanel">
                                  {/* Contenido del perfil de usuario */}
                                  <div className="">
                                    <div className="card-header">
                                      <h5 className="card-title mb-0">Datos del Usuario</h5>
                                    </div>
                                    <div className="card-body">
                                    {showLoading && <Loading />}

                                      {!showLoading && (
                                        <form onSubmit={handleSubmitProfile}>
                                          <div className="row">
                                            <div className="col-md-8">
                                              <div className="mb-3">
                                                <label htmlFor="identification" className="col-form-label  text-sm-right">Identificación</label>
                                                <input type="text" className="form-control" id="identification" name="identification" value={userProfile.identification} onChange={handleChangeProfile} placeholder="Identificación" disabled={true}/>
                                              </div>
                                              <div className="mb-3">
                                                <label htmlFor="userName" className="col-form-label  text-sm-right">Nombre de Usuario</label>
                                                <input type="text" className="form-control" id="userName" name="userName" value={userProfile.userName} onChange={handleChangeProfile} placeholder="Nombre de Usuario" />
                                              </div>
                                              <div className="mb-3">
                                                <label htmlFor="email" className="col-form-label  text-sm-right">Email</label>
                                                <input type="email" className="form-control" id="email" name="email" value={userProfile.email} onChange={handleChangeProfile} placeholder="Email" />
                                              </div>
                                              <div className="mb-3">
                                                <label htmlFor="institutionalEmail" className="col-form-label  text-sm-right">Email Institucional</label>
                                                <input type="email" className="form-control" id="institutionalEmail" name="institutionalEmail" value={userProfile.institutionalEmail} onChange={handleChangeProfile} placeholder="Email Institucional" />
                                              </div>
                                              <div className="mb-3 display-none">
                                                <label htmlFor="userTypeId" className="col-form-label  text-sm-right">Tipo de Usuario</label>
                                                <select className="form-select" id="userTypeId" name="userTypeId" value={userProfile.userTypeId} onChange={handleChangeProfile} disabled={true}>
                                                  <option value="">Seleccione...</option>
                                                  <option value="estudiante">Estudiante</option>
                                                  <option value="docente">Docente</option>
                                                  <option value="administrativo">Administrativo</option>
                                                  {/* Agrega más opciones de tipo de usuario según sea necesario */}
                                                </select>
                                              </div>
                                            </div>
                                            <div className="col-md-4">
                                              <div className="text-center">
                                              <LazyLoadImage
                                                  id="profileImg"
                                                  alt="Imagen de Perfil"
                                                  src={userProfile.showImageProfile ?? "img/avatars/avatar.jpg"}  // Asegúrate de que la imagen de respaldo exista en el path especificado
                                                  className="rounded-circle img-responsive mt-2"  // Reutiliza las clases existentes para mantener el estilo
                                                  width={128}  // Establece el ancho de la imagen
                                                  height={128}  // Establece la altura de la imagen
                                                  effect="blur"  // Opcional: añade un efecto de desenfoque mientras la imagen está cargando
                                                />
                                                <div className="mt-2">
                                                  <label htmlFor="imagenPerfil" className="btn btn-primary"><i className="fas fa-upload"></i> Subir</label>
                                                  <input type="file" className="form-control" id="imagenPerfil" name="imagenPerfil" onChange={handleImageChange} style={{display: 'none'}} />
                                                </div>
                                                <small>Para mejores resultados, utiliza una imagen de al menos 128px por 128px en formato .jpg</small>
                                              </div>
                                            </div>
                                          </div>
                                          <button type="submit" className="btn btn-primary">Guardar Cambios</button>
                                        </form>
                                      )}
                                    </div>
                                  </div>
                              </div>
                
                                <div className={`tab-pane ${activeTab === 'password' ? 'active show' : ''}`} id="password" role="tabpanel">
                                  <div className="">
                                    <div className="card-body">
                                      <h5 className="card-title">Cambio de Contraseña</h5>
                                      {showLoading && <Loading />}

                                      {!showLoading && (
                                      <form onSubmit={handleSubmitPassword}>
                                        <div className="mb-3">
                                          <label htmlFor="currentPassword" className="col-form-label  text-sm-right">Contraseña Actual</label>
                                          <input type="password" className="form-control" id="currentPassword" name="currentPassword" value={passwords.currentPassword} onChange={handleChangePassword} />
                                        </div>
                                        <div className="mb-3">
                                          <label htmlFor="newPassword" className="col-form-label  text-sm-right">Nueva Contraseña</label>
                                          <input type="password" className="form-control" id="newPassword" name="newPassword" value={passwords.newPassword} onChange={handleChangePassword} />
                                        </div>
                                        <div className="mb-3">
                                          <label htmlFor="verifyPassword" className="col-form-label  text-sm-right">Verificar Nueva Contraseña</label>
                                          <input type="password" className="form-control" id="verifyPassword" name="verifyPassword" value={passwords.verifyPassword} onChange={handleChangePassword} />
                                        </div>
                                        <button type="submit" className="btn btn-primary">Guardar Cambios</button>
                                      </form>
                                      )}
                                    </div>
                                  </div>
                                </div>
                            </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
};

export default UserProfile;