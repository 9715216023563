import FullCalendar from '@fullcalendar/react'; // Importar FullCalendar
import dayGridPlugin from '@fullcalendar/daygrid'; // Plugin para la vista de mes
import timeGridPlugin from '@fullcalendar/timegrid'; // Plugin para la vista de semana/día
import interactionPlugin from '@fullcalendar/interaction'; // Para interacciones como clic en día/hora
import { useEffect, useState } from 'react';

const CalendarioEventos = () => {
    // Estado para almacenar los eventos
    const [eventos, setEventos] = useState([]);

    useEffect(() => {
        // Aquí deberías cargar tus eventos desde una API o fuente de datos
        const fetchEventos = async () => {
            // Supongamos que obtienes tus eventos de una API
            const eventosObtenidos = await fetch('/api/eventos');
            setEventos(eventosObtenidos);
        };

        fetchEventos();
    }, []);

    const handleDateClick = (arg) => {
        // Mostrar un modal para agregar nuevo evento o mostrar detalles
        alert(arg.dateStr);
    };

    return (
        <div className="card-body card p-3 p-4">
            <FullCalendar
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                initialView="dayGridMonth"
                weekends={true}
                events={eventos}
                dateClick={handleDateClick}
            />
        </div>
    );
};

export default CalendarioEventos;
