import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import SelectSearch from 'react-select-search';
import estudiantesService from '../../services/estudiantesService';

const EstudianteFilter =  forwardRef((props, ref) => {
    const { callback, isMatriculado, properties, ReadOnly, callbackStudentInfo } = props; 
    const initData ={
        studentId: '0' 
    };
    const [formData, setFormData] = useState(initData);    
    const [studentList, setStudentList] = useState([]);
    const [originalStudents, SetOriginalStudents] = useState([]);
    useImperativeHandle(ref, () => ({
        setFormValues(data) {
          setFormData(prevState => ({
            ...prevState,
            ...data
        }));
        }
    }));
    const  handleSelectChange =  (selectedValue, selectedOption, optionSnapshot) => {
        const { name, value } = {name:'studentId', value:selectedValue};
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    useEffect(() => {
        if(callback){
            callback(formData);
        }
        if(callbackStudentInfo){
            const student = originalStudents.find(s=> String(s.studentId) === String(formData.studentId));
            callbackStudentInfo(student);
        }
      }, [formData]);

    const clearForm = (e) =>{
        setFormData(prevState => ({...prevState, studentId: '0'}))
    }

    useEffect(() => {
        const fetchStudents = async () => {
            try {
                const estudentResp = await estudiantesService.getEstudiantes();
                SetOriginalStudents(estudentResp);
                const students = estudentResp.map((student, index, array) => {
                    return {name: student.identification+ ' '+ student.fullName, value: student.studentId}
                });
                setStudentList(students);
            } catch (error) {
                console.error("Error al obtener listas de formulario:", error);
            }
        };
        fetchStudents();
    }, []);


    return (
        <>
        <div className={(properties && properties.className)?properties.className:""}>
            <label htmlFor="studentId" className="">Estudiante:</label>
            <div className=''>
                <SelectSearch options={studentList} 
                    id={(properties && properties.id)?`cmbEstudiante-${properties.id}`:"cmbEstudiante"}
                    value={formData.studentId}  name="fullName" search placeholder="Selecciona a un estudiante" 
                    onChange={handleSelectChange}
                    readOnly={ReadOnly} 
                    disabled={ReadOnly} />
                     {formData.studentId !== '0' && !ReadOnly && (
                        <i className="fas fa-xmark clear-button-searcher" aria-hidden="true" onClick={clearForm}></i>
                     )}
            </div>
        </div>
        </>
    );
});

export default EstudianteFilter;
