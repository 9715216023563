import React, { useEffect, useRef, useState } from 'react';
import billingService from "../../services/billingService";
import billingConceptService from '../../services/billingConceptService';
import Swal from 'sweetalert2';
import academicService from '../../services/academicService';
import Loading from '../Loading';
import collectionsService from '../../services/collectionsService';
import DataTable from 'react-data-table-component';
import { toast } from 'react-toastify';
import utils from '../../utils/generalFunctions';
import EstudianteFilter from '../Filters/EstudianteFilter';
import types from '../../services/types';
import ReciboPago from '../Reports/reciboPago';
import SelectSearch from 'react-select-search';
import useCustomDataTableStyles from '../estilosDeDataTable';

const BillingForm = (props) => {
    const { billingId, onClose, ReadOnly } = props; 
    const initData ={
        billingId: 0,
        billingStatusId: 0,
        schoolYearId: 0,
        consecutive: 0,
        studentId: 0,
        paymentTypeId:0,
        billingDate: Date(),
        duedate: Date(),
        billingReference:"",
        details:[]
    };

    const [formData, setFormData] = useState(initData);
    const [schoolYearList, setSchoolYearList] = useState([]);
    const [billingStatusList, setBillingStatusList] = useState([]);
    const [paymentTypeList, setPaymentTypeList] = useState([]);
    const [showLoading, setShowLoading] = useState(true);
    const [showRecibo, setShowRecibo] = useState(false);

    const [billingConcepts, setBillingConcepts] = useState([]);
    const [billingConceptList, setBillingConceptList] = useState([]);
    const [billingObligationStatus, setBillingObligationStatus] = useState([]);
    const [billingSelectedConceptList, setBillingSelectedConceptList] = useState([]);
    const [billingMonths, setBillingMonths] = useState([]);
    let concepts = [];
    const [selectedConcept, setSelectedConcept] = useState({});
    const filteReference = useRef(null);
    
    const updateForm = (data) => {
        setFormData(prevState => ({
            ...prevState,
            ...data
          }));
        if(data.studentId !== "0"){
            fetchBillingObligationStatus(data.studentId);
        }else{
            setBillingObligationStatus([]);
        }
    }
    const studentInfoChange = (student) => {
        if(student){
            setFormData(prevState => ({
                ...prevState,
                schoolYearId: student.schoolYearId,
              }));
              LoadBillingConcepts(student.schoolYearId, student.educationLevelId);
        }else{
            setFormData(prevState => ({
                ...prevState,
                schoolYearId: 0,
              }));
              LoadBillingConcepts("0");
        }
        
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
        if(name==='schoolYearId'){
            LoadBillingConcepts(value);
        }
    };

    const  handleConceptSelectChange =  (data) => {
        setBillingSelectedConceptList(data);
    };

    const LoadBillingConcepts = (schoolYearId, educationLevelId) => {
        if(schoolYearId !== "0"){
            if(concepts.length > 0){
                let data = concepts.filter((op) => (String(op.schoolYearId) === String(schoolYearId) || !op.schoolYearId));
                if(educationLevelId) data = data.filter((op) => (educationLevelId && String(op.educationLevelId) === String(educationLevelId)) || !op.educationLevelId);
                const sdata = data.map((concept, index, array) => {
                    return {name: concept.description, value: concept.billingConceptId}
                });
                setBillingConcepts(sdata);
            }else if (billingConceptList.length > 0) {
                let data = billingConceptList.filter((op) => (String(op.schoolYearId) === String(schoolYearId) || !op.schoolYearId));
                if(educationLevelId) data = data.filter((op) => (educationLevelId && String(op.educationLevelId) === String(educationLevelId)) || !op.educationLevelId);
                const sdata = data.map((concept, index, array) => {
                    return {name: concept.description, value: concept.billingConceptId}
                });
                setBillingConcepts(sdata);
            }
        }else{
            const sdata = billingConceptList.map((concept, index, array) => {
                return {name: concept.description, value: concept.billingConceptId}
            });
            setBillingConcepts(sdata);
        }
    };

    const fetchSchoolYears = async () => {
        try {
            const parameters = await collectionsService.getParameters();
            const consecutivo = parameters.find((op) => String(op.keyName) === "consecutivo");
            const currentAnio = parameters.find((op) => String(op.keyName) === "AnioElectivo");
            if(consecutivo){
                setFormData((prevState) => ({ ...prevState, consecutive:Number(consecutivo.value) }));
            }
            if(currentAnio){
                setFormData((prevState) => ({ ...prevState, schoolYearId:Number(currentAnio.value) }));
                LoadBillingConcepts(currentAnio.value);
            }
            const yearsResp = await academicService.getYears();
            setSchoolYearList(yearsResp);
            const paymentTypeRes = await collectionsService.getCollection(types.collections.PAYMENT_TYPE);
            setPaymentTypeList(paymentTypeRes);
            const respStatus = await billingService.getBillingStatus();
            const status = respStatus.filter((p) => p.description.toLowerCase() !== "pendiente" );
            setFormData((prevState) => ({ ...prevState, billingStatusId:(status && status.length>0)?status[0].billingStatusId:0 }));
            setBillingStatusList(status);
            setShowLoading(false);
            if (billingId) {
                const response = await billingService.getBillingDetailsById(billingId);
                setFormData((prevState) => ({ ...prevState, ...response }));
                filteReference?.current?.setFormValues({studentId:response.studentId});
            }
        } catch (error) {
            console.error("Error al obtener listas de formulario:", error);
        }
    };

    const fetchBillingConcepts = async () => {
        concepts = await billingConceptService.getAllBillingItems();
        concepts = concepts.filter(c=>!c.description.toLowerCase().includes("descuento"));
        setBillingConceptList(concepts);
        setBillingConcepts(concepts);
        await fetchSchoolYears();
        addingDisplayConcepList("none");
    };

    const fetchBillingObligationStatus = async (studentId) => {
        const obligations = await billingService.getBillingObligationStatus(studentId,"true");
        setBillingObligationStatus(obligations);
        console.log(obligations);
        const monthList = utils.removeDuplicates(obligations.filter(o=> o.deudaTotal>0).map((o) => {return {numero: o.numero, mes: o.mes}}));
        setBillingMonths(monthList);
    };

    useEffect(() => {
        fetchBillingConcepts();
    }, [billingId]);

    useEffect(() => {
        addingDisplayConcepList("none");
        setBillingSelectedConceptList([]);
    }, [billingConcepts]);

    const validateRequest = () => {
        const requiredFields = ['billingStatusId', 'schoolYearId', 'studentId','paymentTypeId','billingDate' ];
        for (let field of requiredFields) {
            if (!formData[field] || formData[field]==='0') {
                toast.error("Por favor, completa todos los campos obligatorios.", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                  });
                return false;
            }
        }
        if(formData.details.length<=0){
            toast.error("Por favor, agrega un concepto.", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
              return false;
        }
        if(formData.details.length>0){
            for(let i=0; i<formData.details.length; i++){
                const item = formData.details[i];
                if(item.paymentFrequencyId === 45){
                    if(item.month === ""){
                        toast.error("Por favor, selecciona més a pagar.", {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                          });
                          return false;
                    }
                    const conceptMonths = formData.details.filter(c=>c.month === item.month);
                    if(conceptMonths.length>1){
                        toast.error("No es posible registrar pago duplicado para un mismo més.", {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                          });
                          return false;
                    }
                }
 
                if(item.toPay > (item.price * item.amount) ){
                    toast.error("Precio de concepto no valido, por favor verifica el valor a pagar.", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                      });
                      return false;
                }
                
            }
        }
        return true;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const isValid = validateRequest();
        if(isValid){
            try {
                setShowLoading(true);
                let response;
                if (formData.billingId === 0) {
                    const total = formData.details.reduce((acc, concept) => acc + concept.total, 0);
                    formData.billingReference = (formData.billingReference==='')?null:formData.billingReference;
                    const item = {...formData,total: total};
                    response = await billingService.addBilling(item);
                }
                if (response && response.billingId>0) {
                    for (let i = 0; i < formData.details.length; i++) {
                        const element = formData.details[i];
                        const item = {...element,billingId: response.billingId};
                        await billingService.addBillingDetails(item);
                    }
                    Swal.fire("¡Éxito!", "Recibo de pago guardado con éxito.", "success");
                    const data = await billingService.getBillingDetailsById(response.billingId);
                    setFormData((prevState) => ({ ...prevState, ...data }));
                    setShowRecibo(true);
                    onClose();
                } else {
                    Swal.fire("Error", "No se pudo generar el recibo de pago.", "error");
                }
            } catch (error) {
                console.error('Error al enviar el formulario:', error);
                Swal.fire("Error", "No se pudo generar el recibo de pago.", "error");
            }
        }
        setShowLoading(false);
    };

    const handleAddConcept = () => {
        if (!billingSelectedConceptList || billingSelectedConceptList.length===0) {
            toast.error("Seleccione un concepto.");
            return;
        }
        
        let details = [];
        const concepFiltred = billingConceptList.filter((b) => billingSelectedConceptList.filter((id) => b.billingConceptId === id).length > 0 );
        for (let i = 0; i < concepFiltred.length; i++) {
            const concepItem = concepFiltred[i];
            const totalValue = concepItem.value;
            const useMonths = (billingObligationStatus.length>0 && billingMonths.length>0);
            const obliga = (useMonths)? billingObligationStatus.find(o=> 
                Number(o.numero)===Number(billingMonths[0].numero) && 
                Number(o.billingConceptId) === Number(concepItem.billingConceptId)):null; 
            const newDetail = {
                billingConceptName: concepItem.description,
                billingConceptId: concepItem.billingConceptId,
                paymentFrequencyId: concepItem.paymentFrequencyId,
                amount: 1,
                price: (obliga)?obliga.deudaTotal:concepItem.value,
                isParcial: false,
                haveDiscount: false,
                discount: 0,
                mora: (obliga)?obliga.mora??0:0,
                toPay:  (obliga)?obliga.deudaTotal:concepItem.value,
                total:  (obliga)?obliga.deudaTotal:totalValue,
                month: (useMonths)?billingMonths[0].numero:"",
                obligations:(concepItem.paymentFrequencyId===45)?billingObligationStatus:[]
            };
            details = [...details,newDetail ];
        }
        setFormData({
            ...formData,
            details: [...formData.details, ...details]
        });
        
    };
  
    const handleRemoveConcept = (index) => {
        const removedElement = formData.details[index];
        if(removedElement.billingConceptName === "Descuento"){
            formData.details.map((element, index) => {
                    if(element.price > element.total && !element.isParcial){
                        element.toPay = element.price;
                        element.total = element.price;
                    }
            });
        }
        const updatedDetails = formData.details.filter((_, i) => i !== index);
        setFormData({ ...formData, details: updatedDetails });
    };

    const aplicarDescuento = (e, index) => {
        const element = formData.details.find((_, i) => i === index);
        let value = e.target.value.replace("$","");
        value = value.replace(".","");
        value = Number(value);
        if(value>0){
            value = value * -1;
        }
        element.price = value;
        element.total = value;
        setFormData({ ...formData });
    };

    const handlePaymentConcept = (e, index) => {
        const element = formData.details.find((_, i) => i === index);
        let value = e.target.value.replace("$","");
        value = value.replace(".","");
        value = Number(e.target.value);
        if(value<0){
            value = value * -1;
        }
        if(value>element.price){
            value = element.price;
        }
        element.toPay = value;
        element.total = value;
        setFormData({ ...formData });
    };

    const handleDiscountConcept = (e, index) => {
        const element = formData.details.find((_, i) => i === index);
        let value = e.target.value.replace("$","");
        value = value.replace(".","");
        value = Number(e.target.value);
        if(value<0){
            value = value * -1;
        }
        if((element.price-value)<0){
            value = element.price;
        }
        element.discount = value;
        element.toPay = (element.price-value);
        element.total = (element.price-value);
        setFormData({ ...formData });
    };

    const handleTruncaPaymentConcept = (e, index) => {
        const element = formData.details.find((_, i) => i === index);
        let value = e.target.value.replace("$","");
        value = value.replace(".","");
        value = Number(e.target.value);
        if(value<0){
            value = value * -1;
        }
        if(element.toPay<element.price && !element.isParcial){
            const descuento = element.price - element.toPay;
            Swal.fire({
                title:  `¿Desea generar descuento por ${utils.formatNumber.new(descuento, "$")}?`,
                text: "Usted esta ingresando un valor inferior al precio general",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Si',
                cancelButtonText: 'No'
              }).then(async(result) => {
                if (result.isConfirmed) {
                    element.haveDiscount = true;
                    element.discount = descuento;
                    element.toPay = element.price - element.discount;
                    element.total = element.price - element.discount;
                   setFormData({
                    ...formData,
                    details: [...formData.details]
                });
                }else{
                    element.isParcial = true;
                    setFormData({ ...formData });
                }
              })
        }
    };

    const handleInputDetailChange = (e, index, field) => {
        const element = formData.details.find((_, i) => i === index);
        element[field] = e.target.value;
        if(field==='amount'){
            element.total = (Number(element.price) * Number(element.amount)) - element.discount;
        }else if(field==='month'){
            const obligation = billingObligationStatus.find(o=> 
                Number(o.numero) === Number(e.target.value) &&  
                Number(o.billingConceptId)=== Number(element.billingConceptId));
            
            element.mora = (obligation && (obligation.mora??0)>0)? obligation.mora: 0;
            element.price = (obligation)?obligation.deudaTotal : element.price;
            element.total = (Number(element.price) * Number(element.amount)) - element.discount;
            element.toPay = (Number(element.price) * Number(element.amount)) - element.discount;
        }
        setFormData({ ...formData, [field]:e.target.value });
    };

    const mostarAbono = (e, index) => {
        const element = formData.details.find((_, i) => i === index);
        const showAbono= e.target.checked;
        element.isParcial = showAbono;
        if(!showAbono){
            element.toPay = Number(element.price)
            element.total = Number(element.price) * Number(element.amount)
        }
        setFormData({ ...formData });
    };

    const mostarDescuento = (e, index) => {
        const element = formData.details.find((_, i) => i === index);
        const showDescuento= e.target.checked;
        element.haveDiscount = showDescuento;
        if(!showDescuento){
            element.toPay = Number(element.price)
            element.total = Number(element.price) * Number(element.amount)
        }else{
            element.total = (Number(element.price) * Number(element.amount)) - element.discount;
            element.toPay = (Number(element.price) * Number(element.amount)) - element.discount;
        }
        setFormData({ ...formData });
    };

    // Calcular el total de la factura
    const calculateTotal = () => {
        return formData.details.reduce((acc, concept) => acc + concept.total, 0);
    };

    const columns = [
        { name: "Concepto", selector: "billingConceptName", grow:2},
        { name: "Precio", selector: "price", grow:1,  cell: (row, index) => (
            <>
                {row.billingConceptName.toLowerCase()!=='descuento' &&
                    <>
                    <div>{utils.formatNumber.new(row.price, "$")}</div>
                    {(row.mora > 0) && (
                    <div>
                        <span class="badge rounded-pill bg-danger">Mora: {utils.formatNumber.new(row.mora, "$")}</span>
                    </div>
                    )}
                    </>
                }
                {row.billingConceptName.toLowerCase()==='descuento' &&

                    <>
                    {!(billingId>0) && (
                       <div>
                            <input
                                type="number"
                                className="form-control"
                                value={row.price}
                                onChange={(e) => aplicarDescuento(e, index)}
                        /></div>)}

                    {(billingId>0) && (utils.formatNumber.new(row.price, "$"))}
                    </>
                    
                }
            </>
        ),conditionalCellStyles:[{
            when: row => {
                return (row.mora > 0)
            },
            style:{
                flexDirection: "column",
                alignContent: "flex-start",
                alignItems: "flex-start",
                justifyContent: "space-evenly"
            }
        }] },
        { name: "Cant.", selector: "amount", grow:0.5, cell: (row, index) => (
            <>
                {(row.billingConceptName.toLowerCase()==='descuento' || (billingId>0)) &&
                    <div>{utils.formatNumber.new(row.amount, "")}</div>
                }
                {(row.billingConceptName.toLowerCase()!=='descuento' && (!billingId) && 
                  row.paymentFrequencyId !== 45 ) &&                  
                    <div>
                        <input
                            type="number"
                            className="form-control"
                            value={row.amount}
                            onChange={(e) => handleInputDetailChange(e, index,'amount')}
                    /></div>
                }
                {( (!billingId) && row.paymentFrequencyId === 45 ) &&     
                    <div className="col-md-12 mb-2">
                        <select
                            className="form-control form-select validarForm"
                            name="paymentTypeId"
                            value={row.month}
                            onChange={(e) => handleInputDetailChange(e, index,'month')}
                            readOnly={ReadOnly}
                            required
                        >   
                            <option value="0" disabled={ReadOnly}> Seleccione</option>
                            {billingMonths.map((option) => (
                            <option key={option.numero} value={option.numero} disabled={ReadOnly}>
                                {option.mes}
                            </option>
                            ))}
                        </select>
                    </div>                   
                }
            </>
        ), },
        { name: "Abono", selector: "isParcial", grow:1, cell: (row, index) => (
            <>
                {(row.billingConceptName.toLowerCase()!=='descuento' && 
                    <div className='container-searcher-general'>
                        <div className="form-check">
                                <input type="checkbox" className="form-check-input" id={"isParcial"+index} name={"isParcial"+index}
                                    checked={row.isParcial} onChange={(e)=>{
                                        mostarAbono(e, index)
                                    }} disabled={(billingId>0)} />
                            </div>
                            {row.isParcial && (
                                <>
                                    {!(billingId>0) && (
                                        <input
                                            type="number"
                                            className="form-control"
                                            value={row.toPay}
                                            onChange={(e) => handlePaymentConcept(e, index)}
                                    />)}

                                    {(billingId>0) && (utils.formatNumber.new(row.total, "$"))}
                                </>
                            )}
                    </div>
                    )}
                </>
        ),},
        { name: "Descuento", selector: "haveDiscount",  grow:1, cell: (row, index) => (
            <>
                {(row.billingConceptName.toLowerCase()!=='descuento' && 
                    <div className='container-searcher-general'>
                        <div className="form-check">
                                <input type="checkbox" className="form-check-input" id={"haveDiscount"+index} name={"haveDiscount"+index}
                                    checked={row.haveDiscount} onChange={(e)=>{
                                        mostarDescuento(e, index)
                                    }} disabled={(billingId>0)} />
                            </div>
                            {row.haveDiscount && (
                                <>
                                    {!(billingId>0) && (
                                        <input
                                            type="number"
                                            className="form-control"
                                            value={row.discount}
                                            onChange={(e) => handleDiscountConcept(e, index)}
                                    />)}

                                    {(billingId>0) && (utils.formatNumber.new(row.total, "$"))}
                                </>
                            )}
                    </div>
                    )}
                </>
        ),},
        { name: "Pagado", selector: "price", grow:1, cell: (row, index) => (
            <>
                {(row.billingConceptName.toLowerCase()!=='descuento' && 
                    <div className='container-searcher-general'>
                         {!(billingId>0) && (
                                        <input
                                            type="number"
                                            className="form-control"
                                            value={row.toPay}
                                            onChange={(e) => handlePaymentConcept(e, index)}
                                            onBlur={(e)=> handleTruncaPaymentConcept(e,index)}
                                    />)}
                    </div>
                    )}
            </>
        ),},
        { name: "Total", selector: "total", grow:0.5, cell: (row, index) => (<div id={"lblTotal"+index} >{utils.formatNumber.new(row.total, "$")}</div>) },
        { name: "", selector: "billingConceptName", grow:0.5,omit: (billingId>0) ,  cell: (row, index) => (
            <i className="fas fa-xmark clear-button-searcher" aria-hidden="true" onClick={() => handleRemoveConcept(index)}></i>
        ) },
        
    ];

    const customStyles = useCustomDataTableStyles();


    const addingDisplayConcepList = (display) => {
        const cmb = document.getElementById("cmbBillingConceptId");
        if(cmb){
            const list = cmb.getElementsByClassName("select-search-select")[0];
            if(list)list.style.display = display;   
        }
    }

    return (
        <>
        {showLoading && <Loading />}

        {!showLoading && (
            <div className="container-fluid mt-4">

                {(!billingId && !showRecibo) && (         
                <form onSubmit={handleSubmit} className="d-grid gap-3">
                    <div className="row">
                        
                    <EstudianteFilter
                            callback={updateForm}
                            callbackStudentInfo={studentInfoChange}
                            ref={filteReference}
                            properties={
                                {
                                    className:'col-md-12 mb-10',
                                    id:'formBilling'
                                }
                            }
                            ReadOnly={ReadOnly}
                        />
                        <br></br><br></br>
                        <div className="col-md-2 mb-2">
                            <label htmlFor="consecutive" className="col-form-label  text-sm-right">Consecutivo</label>
                            <input type="text" className="form-control" id="consecutive" name="consecutive" 
                                readOnly={true} value={formData.consecutive} onChange={handleChange} />
                        </div>
                    
                        <div className="col-md-2 mb-2">
                            <label htmlFor="billingDate" className="col-form-label  text-sm-right">Fecha de recibo:*</label>
                            <input
                                type="date"
                                className="form-control validarForm"
                                name="billingDate"
                                value={formData.billingDate}
                                onChange={handleChange}
                                placeholder="dd/mm/aaaa"
                                readOnly={ReadOnly}
                                required
                            />
                        </div>

                        <div className="col-md-2 mb-2">
                            <label className="col-form-label  text-sm-right">Forma de pago:*</label>
                            <select
                                className="form-control form-select validarForm"
                                name="paymentTypeId"
                                value={formData.paymentTypeId}
                                onChange={handleChange}
                                readOnly={ReadOnly}
                                required
                            >   
                                <option value="0" disabled={ReadOnly}> Seleccione</option>
                                {paymentTypeList.map((option) => (
                                <option key={option.id} value={option.id} disabled={ReadOnly}>
                                    {option.text}
                                </option>
                                ))}
                            </select>
                        </div>

                        <div className="col-md-2 mb-2">
                            <label htmlFor="schoolYearId" className="col-form-label  text-sm-right">Año escolar:*</label>
                            <select className="form-control form-select" id="schoolYearId" name="schoolYearId" 
                                value={formData.schoolYearId} onChange={handleChange} required>
                                <option value="0" disabled={ReadOnly}>Seleccione</option>
                                {schoolYearList.map((school) => (
                                    <option key={school.schoolYearId} value={school.schoolYearId} disabled={ReadOnly}>
                                        {school.year}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div className="col-md-2 mb-2">
                            <label htmlFor="billingStatusId" className="col-form-label  text-sm-right">Estado:*</label>
                            <select className="form-control form-select" id="billingStatusId" name="billingStatusId" 
                                value={formData.billingStatusId} onChange={handleChange} required>
                                <option value="" disabled={ReadOnly}>Seleccione</option>
                                {billingStatusList.map((status) => (
                                    <option key={status.billingStatusId} value={status.billingStatusId} disabled={ReadOnly}>
                                        {status.description}
                                    </option>
                                ))}
                            </select>
                        </div>
                        
                        <div className="col-md-2 mb-2">
                            <label htmlFor="billingReference" className="col-form-label  text-sm-right">Referencia de pago</label>
                            <input type="text" className="form-control" id="billingReference" name="billingReference" 
                                readOnly={ReadOnly} value={formData.billingReference} onChange={handleChange} />
                        </div>
                        <br></br><br></br>
                        {/* Selector de conceptos de facturación */}

                    {!ReadOnly && String(formData.studentId)!=="0" && (<>
                        <div className="col-md-4 mb-4">
                            <label htmlFor="concept" className="col-form-label  text-sm-right">Concepto</label>
                            <div className='container-searcher-general align-items-start'>
                                <SelectSearch options={billingConcepts} 
                                    id="cmbBillingConceptId"
                                    multiple={true}
                                    search={false}
                                    value={billingSelectedConceptList}  name="description" placeholder="Seleccionar conceptos" 
                                    onChange={handleConceptSelectChange}
                                    readOnly={ReadOnly} 
                                    disabled={ReadOnly} 
                                    onBlur={()=>{
                                        addingDisplayConcepList("none");
                                    }}
                                    onFocus={()=>{
                                        addingDisplayConcepList("");
                                    }} 
                                    />
                                 {billingSelectedConceptList.length > 0 && !ReadOnly && (
                                        <i className="fas fa-xmark clear-button-searcher margin-top-15" aria-hidden="true" onClick={()=>{
                                            setBillingSelectedConceptList([]);
                                        }}></i>
                                  )}
                            </div>
                            {/* <select
                                className="form-control"
                                value={selectedConcept.billingConceptId}
                                onChange={(e) => setSelectedConcept(billingConcepts.find(concept => concept.billingConceptId === Number(e.target.value)))}>
                                <option value="">Seleccione un concepto</option>
                                {billingConcepts.map((concept) => (
                                    <option key={concept.billingConceptId} value={concept.billingConceptId}>
                                        {concept.description}
                                    </option>
                                ))}
                            </select> */}
                        </div>
                        {/* Botón para añadir el concepto a la lista */}
                        <div className="col-md-3 mb-3 d-flex align-items-start margin-top-48">
                            <button type="button" className="btn btn-success" onClick={handleAddConcept}>
                                Añadir Concepto
                            </button>
                        </div>
                    </>)}

                    {/* Lista de conceptos añadidos */}
                    <DataTable
                        columns={columns}
                        data={formData.details}
                        customStyles={customStyles}
                        className="students-datatable shadow-sm rounded"
                        noDataComponent="No hay conceptos que mostrar, por favor agregalos"   
                        noHeader
                        dense
                        highlightOnHover
                        pointerOnHover
                        fixedHeader
                          fixedHeaderScrollHeight="450px"
                    />

                    {/* Total de la factura */}
                    <div className="col-md-12 text-right mt-3" id="pnlTotal">
                        <h4>Total: {utils.formatNumber.new(calculateTotal(), "$")}</h4>
                    </div>
                </div>
                    <div className="row">
                        <div className="col-md-2 center">
                            {!ReadOnly  && (
                                <button type="submit" className="btn btn-primary">Generar recibo</button>
                            )}
                        </div>
                    </div>
                </form>
                )}

                {(billingId || showRecibo) && (
                    <div >
                       <ReciboPago recibo={formData} />
                    </div>
                )}

            </div>
        )}
        </>
    );
};

export default BillingForm;