import React, { useState } from 'react';
import authService from '../services/authService';
import '../css/login.css'
import Loading from '../components/Loading';
import { toast } from 'react-toastify';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const Login = ({ onLogin }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
	const [showLoading, setShowLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
		setShowLoading(true);
		try {
			const response = await authService.validateLogin(username, password);
			await onLogin(response);
		} catch (error) {
			toast.error("Usuario y/o contraseña incorrectos.", {
				position: "top-right",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "light",
			  });
			console.error("Error al loguearse:", error);
		}
		setShowLoading(false);
    };

    return (
    <div className="main d-flex justify-content-center w-100  ">
		<main className="content d-flex p-0 ">
			<div className="container d-flex flex-column ">
				<div className="row h-100">
					<div className="col-sm-10 col-md-8 col-lg-6 col-xl-5 mx-auto d-table h-100">
						<div className="d-table-cell align-middle">

							<div className="text-center mt-4">
								<h1 className="h2">Bienvenido!</h1>
								<p className="lead">
									Ingresa con tu cuenta para continuar
								</p>
							</div>

							<div className="card">
								<div className="card-body">
									<div className="m-sm-3">
										<div className="row">
										<div className="col d-flex justify-content-center">
											<LazyLoadImage
											src="https://colegiojerusalem.edu.co/wp-content/uploads/2021/09/Logo-Colegio-980x963.png"
											alt="Logo del Colegio"
											className="img-fluid img-logo-login"  // Conserva img-fluid si quieres que sea responsiva
											effect="blur"
											/>
										</div>

										</div>
										{showLoading && <Loading />}

										{!showLoading && (
										<form onSubmit={handleSubmit}>
											<div className="">
												<label className="col-form-label  text-sm-right">Usuario</label>
                                                <input className="form-control form-control-lg" value={username} onChange={e => setUsername(e.target.value)} placeholder="Usuario" />
											</div>
											<div className="mb-3">
												<label className="col-form-label  text-sm-right">Contraseña</label>
                                                <input  className="form-control form-control-lg" type="password" value={password} onChange={e => setPassword(e.target.value)} placeholder="Contraseña" />
											</div>
											<div className="d-grid gap-2 mt-3">
                                                <button className="btn btn btn-primary" type="submit">Iniciar sesión</button>
											</div>
										</form>
										)}
									</div>
								</div>
							</div>							
						</div>
					</div>
				</div>
			</div>
		</main>
	</div>
    );
};

export default Login;