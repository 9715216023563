import React, { useEffect, useState } from "react";
import "../../css/StudentForm.css";
import userProfileImage from "../../img/user-profile.png";
import collectionsServices from "../../services/collectionsService";
import types from "../../services/types";
import estudiantesService from "../../services/estudiantesService";
import gradesService from "../../services/gradesService";
import academicService from "../../services/academicService";
import Loading from "../Loading";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { LazyLoadImage } from "react-lazy-load-image-component";

function StudentForm(props) {
  const { student, onClose, hideBtnInscription, ReadOnly } = props; // Definir el estado para gestionar los datos del formulario.

  const currentDateAdult = new Date();
  const maxAdultDate = new Date(currentDateAdult);
  let gradesList = [];
  let yearsList = [];
  maxAdultDate.setFullYear(maxAdultDate.getFullYear() - 18); // 18 años antes de la fecha actual
  const initData = {
    registrationId: 0,
    showImageProfile: userProfileImage,
    profilePicture: null,
    aspirantId: "",
    schoolYearId: "",
    journeyId: "",
    grade: "",
    firstName: "",
    secondName: "",
    firstSurname: "",
    secondSurname: "",
    identificationType: "",
    placeOfIssue: "",
    birthDate: "",
    gender: "",
    phone: "",
    cellPhone: "",
    address: "",
    disability: "",
    email: "",
    institution: "",
    city: "",
    department: "",
    medicalDiagnostic: "",
    fatherReferenceId: 0,
    fatherId: "",
    fatherStatusId: "23",
    fatherPlaceOfIssue: "",
    fatherIdType: "",
    fatherAcademicLevel: "",
    fatherFirstName: "",
    fatherSecondName: "",
    fatherFirstSurname: "",
    fatherSecondSurname: "",
    fatherBirthDate: "",
    fatherCellphone: "",
    fatherPhone: "",
    fatherAddress: "",
    fatherEmail: "",
    fatherProfession: "",
    fatherWorkplace: "",
    fatherJobType: "",
    fatherPosition: "",
    fatherIsParentMain: true,
    motherReferenceId: 0,
    motherId: "",
    motherStatusId:"23",
    motherPlaceOfIssue: "",
    motherIdType: "",
    motherAcademicLevel: "",
    motherFirstName: "",
    motherSecondName: "",
    motherFirstSurname: "",
    motherSecondSurname: "",
    motherBirthDate: "",
    motherCellPhone: "",
    motherPhone: "",
    motherAddress: "",
    motherEmail: "",
    motherProfession: "",
    motherWorkplace: "",
    motherJobType: "",
    motherPosition: "",
    motherIsParentMain: false,
    studentLivingWith: "",
    enrollmentReason: "",
    recommendation: "",
  };
  const [years, setYears] = useState([]);
  const [yearsSelectList, setYearsSelectList] = useState([]);
  const [grades, setGrades] = useState([]);
  const [gradesSelectList, setGradesSelectList] = useState([]);
  const [identificationTypes, setIdentificationTypes] = useState([]);
  const [sexTypes, setSexTypes] = useState([]);
  const [jobTypes, setJobTypes] = useState([]);
  const [parentStatusType, setParentStatusType] = useState([]);
  const [journeyList, setJourneyList] = useState([]);
  const [formData, setFormData] = useState(initData);
  const [showLoading, setShowLoading] = useState(true);
  const [showMedicalDiagnostic, setShowMedicalDiagnostic] = useState(false);
  const [showFatherInfo, setShowFatherInfo] = useState(true);
  const [showMotherInfo, setShowMotherInfo] = useState(true);

  useEffect(() => {
    const fetchCollections = async () => {
      try {
        yearsList = await academicService.getYears();
        setYears(yearsList);
        const parentStatusResp = await collectionsServices.getCollection(
          types.collections.PARENT_STATUS
        );
        setParentStatusType(parentStatusResp);
        const jobTypesRes = await collectionsServices.getCollection(
          types.collections.JOB_TYPE
        );
        setJobTypes(jobTypesRes);
        const idTypesRes = await collectionsServices.getCollection(
          types.collections.IDENTIFICATION_TYPE
        );
        setIdentificationTypes(idTypesRes);
        const sexTypesRes = await collectionsServices.getCollection(
          types.collections.SEX
        );
        setSexTypes(sexTypesRes);
        gradesList = await gradesService.getGrades();
        setGrades(gradesList);
        const journeyResp = await academicService.getJourneys();
        setJourneyList(journeyResp);

        if (student) {
          const respStudent = await estudiantesService.getEstudiante(student);
          console.log(respStudent);
          if (respStudent.profilePicture) {
            setFormData((prevState) => ({
              ...prevState,
              showImageProfile: respStudent.profilePicture,
            }));
          }
          setFormData((prevState) => ({ ...prevState, ...respStudent }));
          LoadGrades(respStudent.journeyId.toString());
          LoadYears(respStudent.journeyId.toString());
          setShowFatherInfo((String(respStudent.fatherStatusId)==="23"));
          setShowMotherInfo((String(respStudent.motherStatusId)==="23"));
          setShowMedicalDiagnostic((respStudent.medicalDiagnostic??'')!=='');
        }
        setShowLoading(false);
      } catch (error) {
        console.error("Error al obtener listas de formulario:", error);
      }
    };

    fetchCollections();
  }, [student]);

  useEffect(() => {
    const setValidations = async () => {
      const txt = document.getElementById("txtDiagnostico");
      if(txt){
        txt.className = (showMedicalDiagnostic)?"form-control validarForm":"form-control";
        if(!showMedicalDiagnostic) setFormData((prevState) => ({ ...prevState, medicalDiagnostic: "" }));
      }
    };

    setValidations();
  }, [showMedicalDiagnostic]);

  const validarGroup = (grupo) => {
    var permiso = true;
    var elementos = document.querySelectorAll("." + grupo);
    for (var i = 0; i < elementos.length; i++) {
      switch (elementos[i].tagName) {
        case "INPUT":
          if (elementos[i].value.trim() == "") {
            permiso = false;
            elementos[i].setAttribute("style", "border:1px solid #e00000;");
          } else {
            elementos[i].setAttribute("style", "border:1px solid #A9A49D;");
          }
          break;
        case "TEXTAREA":
          if (elementos[i].value.trim() == "") {
            permiso = false;
            elementos[i].setAttribute("style", "border:1px solid #e00000;");
          } else {
            elementos[i].setAttribute("style", "border:1px solid #A9A49D;");
          }
          break;
        case "SELECT":
          if (
            elementos[i].value.trim() == "0" ||
            elementos[i].value.trim() == "Seleccione" ||
            elementos[i].value.trim() == "0-0" ||
            elementos[i].value.trim() == ""
          ) {
            permiso = false;
            elementos[i].setAttribute("style", "border:1px solid #e00000;");
          } else {
            elementos[i].setAttribute("style", "border:1px solid #A9A49D;");
          }
          break;
      }
    }
    return permiso;
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        document.getElementById("profileImage").src = event.target.result;
        setFormData((prevState) => ({ ...prevState, profilePicture: file }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
    if (name === "journeyId"){
      LoadGrades(document.getElementById("cmbJourney").value);
      LoadYears(document.getElementById("cmbJourney").value);
    }
    if(name === "fatherStatusId"){
        setShowFatherInfo((String(value)==="23"));
    }
    if(name === "motherStatusId"){
      setShowMotherInfo((String(value)==="23"));
  }
  };

  // Función para manejar el envío del formulario.
  const handleSubmit = async (event) => {
    console.log("handleSubmit ejecutado");
    console.log(formData);
    event.preventDefault();
    const permiso = validarGroup("validarForm");
    if (
      !formData.profilePicture ||
      !formData.aspirantId ||
      !formData.schoolYearId ||
      !formData.grade ||
      !formData.firstName ||
      !formData.firstSurname ||
      !formData.identificationType ||
      !formData.placeOfIssue ||
      !formData.birthDate ||
      !formData.gender ||
      !formData.phone ||
      !formData.cellPhone ||
      !formData.address ||
      !formData.disability ||
      !formData.email ||
      !formData.institution ||
      !formData.city ||
      !formData.department ||
      (showMedicalDiagnostic && (!formData.medicalDiagnostic)) ||

      (showFatherInfo && 
      (!formData.fatherId ||
      !formData.fatherPlaceOfIssue ||
      !formData.fatherIdType ||
      !formData.fatherAcademicLevel ||
      !formData.fatherFirstName ||
      !formData.fatherFirstSurname ||
      !formData.fatherBirthDate ||
      !formData.fatherCellphone ||
      !formData.fatherAddress ||
      !formData.fatherProfession ||
      !formData.fatherWorkplace ||
      !formData.fatherJobType ||
      !formData.fatherPosition)) ||
      
      (showMotherInfo &&
      (!formData.motherId ||
      !formData.motherPlaceOfIssue ||
      !formData.motherIdType ||
      !formData.motherAcademicLevel ||
      !formData.motherFirstName ||
      !formData.motherFirstSurname ||
      !formData.motherBirthDate ||
      !formData.motherCellPhone ||
      !formData.motherAddress ||
      !formData.motherProfession ||
      !formData.motherWorkplace ||
      !formData.motherJobType ||
      !formData.motherPosition ||
      !formData.studentLivingWith ||
      !formData.enrollmentReason ||
      !formData.recommendation)) ||
      !permiso
    ) {
      toast.error("Por favor, completa todos los campos obligatorios.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }

    if (!ReadOnly) {
      setShowLoading(true);
      try {
        let studentId = 0;
        if (formData.registrationId > 0) {
          const response = await estudiantesService.updateEstudiantes(formData);
          studentId = response.studentId;
          console.log(response);
        } else {
          const response = await estudiantesService.createEstudiantes(formData);
          studentId = response.studentId;
          console.log(response);
        }
        if (
          formData.profilePicture &&
          formData.profilePicture !== formData.showImageProfile &&
          studentId > 0
        ) {
      
            try {
              const imgResponse = await estudiantesService.subirImagenEstudiantes(
                formData.profilePicture,
                studentId
              );
              console.log(imgResponse);
            } catch (error) {
              console.error(
                "Hubo un error al subir imagen del estudiante:",
                error
              );
            }
        }
        Swal.fire(
          "Felicidades!",
          "Información de estudiante guardada con éxito!",
          "success",
          );
        if (onClose) onClose();
        setFormData(initData);
      } catch (error) {
        console.error("Hubo un error al inscribir al estudiante:", error);
        Swal.fire(
          "Ha ocurrido un error",
          "Por favor intentelo mas tarde",
          "error"
        );
      }
      setShowLoading(false);
    }
  };

  const validarFechaEstudiante = (date) => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Los meses son 0-indexed
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  };

  const ValidarFechaPadres = (date) => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Los meses son 0-indexed
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  };

  const ValidarAcudientePrincipal = (event) => {
    const { name, value } = event.target;
    const checkfather = document.getElementById("fatherGuardian");
    if (checkfather === event.target) {
      document.getElementById("motherGuardian").checked = false;
      setFormData((prevState) => ({ ...prevState, motherIsParentMain: false }));
      setFormData((prevState) => ({ ...prevState, fatherIsParentMain: true }));
    } else {
      setFormData((prevState) => ({ ...prevState, fatherIsParentMain: false }));
      setFormData((prevState) => ({ ...prevState, motherIsParentMain: true }));
      checkfather.checked = false;
    }
  };

  const LoadGrades = (journeyId) => {
    if (gradesList.length > 0) {
      const grs = gradesList.filter((op) => String(op.journeyId) === journeyId);
      setGradesSelectList(grs);
    } else if (grades.length > 0) {
      const grs = grades.filter((op) => String(op.journeyId) === journeyId);
      setGradesSelectList(grs);
    }
  };

  const LoadYears = (journeyId) => {
    if (yearsList.length > 0) {
      const yrs = yearsList.filter((op) => String(op.journeyId) === journeyId);
      setYearsSelectList(yrs);
    } else if (years.length > 0) {
      const yrs = years.filter((op) => String(op.journeyId) === journeyId);
      setYearsSelectList(yrs);
    }
  };

  return (
    <>
      {showLoading && <Loading />}

      {!showLoading && (
        <form onSubmit={handleSubmit}>
          <div className="col-md-12">
          <div className="card">
            <div className="card-header bg-light text-center">
              <h5 className="card-title">
                INFORMACIÓN DEL ESTUDIANTE
              </h5>
            </div>
            <div className="card-body">
              <div className="row">
              <div className="col-md-6 mb-3">
              <div class="mt-2 text-center">
                  <div
                    className="img-thumbnail rounded me-2 mb-2 profile-picture bg-light mb3"
                    style={{
                      width: "150px",
                      height: "150px",
                      overflow: "hidden",
                    }}
                  >
                    <LazyLoadImage
                      id="profileImage"
                      src={formData.showImageProfile}
                      alt="Foto de perfil"
                      width="150"   // Cambiado para adaptarse a un estilo responsive
                      height="150"  // Cambiado para adaptarse a un estilo responsive
                      style={{ objectFit: 'cover' }}  // Estilos adicionales pueden ser aplicados aquí
                      effect="blur"  // Puedes usar 'blur' o cualquier otro efecto disponible
                    />
                  </div></div>
                  
                  <label className="btn btn-primary  mt-1">
                  <i class="fas fa-upload" aria-hidden="true"></i> Subir*
                  <input
                      type="file"
                      className="form-control d-none"
                      onChange={handleFileChange}
                      readOnly={ReadOnly}
                    />
                      
                  </label>
                
                </div>
                <div className="col-md-6 mb-3">
                  <label className="col-form-label  text-sm-right">
                    Identificación del aspirante*
                  </label>
                  <input
                    type="text"
                    className="form-control validarForm"
                    name="aspirantId"
                    value={formData.aspirantId}
                    onChange={handleInputChange}
                    title="Por favor, ingrese solo números."
                    readOnly={ReadOnly}
                    required
                  />
                </div>
                <div className="col-md-4">
                  <label className="col-form-label  text-sm-right">Jornada a inscribirse*</label>
                  <select
                    id="cmbJourney"
                    className="form-control form-select validarForm"
                    name="journeyId"
                    value={formData.journeyId}
                    onChange={handleInputChange}
                    readOnly={ReadOnly}
                    required
                  >
                    <option value="" disabled={ReadOnly}>
                      Seleccione
                    </option>
                    {journeyList.map((option) => (
                      <option
                        key={option.journeyId}
                        value={option.journeyId}
                        disabled={ReadOnly}
                      >
                        {option.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-md-4">
                  <label className="col-form-label  text-sm-right">Año actual*</label>
                  <select
                    className="form-control form-select validarForm"
                    name="schoolYearId"
                    value={formData.schoolYearId}
                    onChange={handleInputChange}
                    readOnly={ReadOnly}
                    required
                  >
                     <option value="" disabled={ReadOnly}>
                      Seleccione
                    </option>
                    {yearsSelectList.map((option) => (
                      <option
                        key={option.schoolYearId}
                        value={option.schoolYearId}
                        disabled={ReadOnly}
                      >
                        {option.year}
                      </option>))}
                  </select>
                </div>
                <div className="col-md-4">
                  <label className="col-form-label  text-sm-right">Grado a inscribirse*</label>
                  <select
                    className="form-control form-select validarForm"
                    name="grade"
                    value={formData.grade}
                    onChange={handleInputChange}
                    readOnly={ReadOnly}
                    required
                  >
                    <option value="" disabled={ReadOnly}>
                      Seleccione
                    </option>
                    {gradesSelectList?.map((option) => (
                      <option
                        key={option.gradeId}
                        value={option.gradeId}
                        disabled={ReadOnly}
                      >
                        {option.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-md-3">
                  <label className="col-form-label  text-sm-right">Primer Nombre*</label>
                  <input
                    type="text"
                    className="form-control validarForm"
                    name="firstName"
                    title="Por favor, ingrese solo letras."
                    value={formData.firstName}
                    onChange={handleInputChange}
                    readOnly={ReadOnly}
                    required
                  />
                </div>
                <div className="col-md-3">
                  <label className="col-form-label  text-sm-right">Segundo Nombre</label>
                  <input
                    type="text"
                    className="form-control"
                    name="secondName"
                    title="Por favor, ingrese solo letras."
                    value={formData.secondName}
                    onChange={handleInputChange}
                    readOnly={ReadOnly}
                  />
                </div>
                <div className="col-md-3">
                  <label className="col-form-label  text-sm-right">Primer Apellido*</label>
                  <input
                    type="text"
                    className="form-control validarForm"
                    name="firstSurname"
                    value={formData.firstSurname}
                    onChange={handleInputChange}
                    readOnly={ReadOnly}
                    required
                  />
                </div>
                <div className="col-md-3">
                  <label className="col-form-label  text-sm-right">Segundo Apellido</label>
                  <input
                    type="text"
                    className="form-control"
                    name="secondSurname"
                    value={formData.secondSurname}
                    onChange={handleInputChange}
                    readOnly={ReadOnly}
                  />
                </div>
              </div>
            </div>
          </div>
          </div>
          <div className="col-md-12">

          <div className="card">
            <div className="card-header bg-light text-center">
              <h5 className="card-title">
                <br />
                INFORMACIÓN ADICIONAL
                <br />
              </h5>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-3">
                  <label className="col-form-label  text-sm-right">Tipo de Identidad*</label>
                  <select
                    className="form-control form-select validarForm"
                    name="identificationType"
                    value={formData.identificationType}
                    onChange={handleInputChange}
                    readOnly={ReadOnly}
                    required
                  >
                    <option value="" disabled={ReadOnly}>
                      Seleccione
                    </option>
                    {identificationTypes.map((option) => (
                      <option
                        key={option.id}
                        value={option.id}
                        disabled={ReadOnly}
                      >
                        {option.text}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-md-3">
                  <label className="col-form-label  text-sm-right">Lugar de Expedición*</label>
                  <input
                    type="text"
                    className="form-control validarForm"
                    name="placeOfIssue"
                    value={formData.placeOfIssue}
                    onChange={handleInputChange}
                    placeholder="Lugar de expedición"
                    readOnly={ReadOnly}
                    required
                  />
                </div>
                <div className="col-md-3">
                  <label className="col-form-label  text-sm-right">Fecha de Nacimiento*</label>
                  <input
                    type="date"
                    className="form-control validarForm"
                    name="birthDate"
                    value={formData.birthDate}
                    onChange={handleInputChange}
                    placeholder="dd/mm/aaaa"
                    readOnly={ReadOnly}
                    required
                  />
                </div>
                <div className="col-md-3">
                  <label className="col-form-label  text-sm-right">Sexo*</label>
                  <select
                    className="form-control form-select validarForm"
                    name="gender"
                    value={formData.gender}
                    onChange={handleInputChange}
                    readOnly={ReadOnly}
                    required
                  >
                    <option value="" disabled={ReadOnly}>
                      Seleccione
                    </option>
                    {sexTypes.map((option) => (
                      <option
                        key={option.id}
                        value={option.id}
                        disabled={ReadOnly}
                      >
                        {option.text}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-md-3">
                  <label className="col-form-label  text-sm-right">Teléfono*</label>
                  <input
                    type="tel"
                    className="form-control validarForm"
                    name="phone"
                    value={formData.phone}
                    onChange={handleInputChange}
                    readOnly={ReadOnly}
                    required
                  />
                </div>
                <div className="col-md-3">
                  <label className="col-form-label  text-sm-right">Celular*</label>
                  <input
                    type="tel"
                    className="form-control validarForm"
                    name="cellPhone"
                    value={formData.cellPhone}
                    onChange={handleInputChange}
                    readOnly={ReadOnly}
                    required
                  />
                </div>
                <div className="col-md-3">
                  <label className="col-form-label  text-sm-right">Dirección*</label>
                  <input
                    type="text"
                    className="form-control validarForm"
                    name="address"
                    value={formData.address}
                    onChange={handleInputChange}
                    placeholder="Dirección de residencia"
                    readOnly={ReadOnly}
                    required
                  />
                </div>
                <div className="col-md-3">
                  <label className="col-form-label  text-sm-right">Email*</label>
                  <input
                    type="email"
                    className="form-control validarForm"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    readOnly={ReadOnly}
                    placeholder="correo@gmail.com"
                  />
                  <small className="form-text text-muted">
                    NOTA: Asegúrese de escribir bien su e-mail.
                  </small>
                </div>
                <div className="col-md-3">
                  <label className="col-form-label  text-sm-right">Ciudad*</label>
                  <input
                    type="text"
                    className="form-control validarForm"
                    name="city"
                    value={formData.city}
                    onChange={handleInputChange}
                    readOnly={ReadOnly}
                    required
                  />
                </div>
                <div className="col-md-3">
                  <label className="col-form-label  text-sm-right">Departamento*</label>
                  <input
                    type="text"
                    className="form-control validarForm"
                    name="department"
                    value={formData.department}
                    onChange={handleInputChange}
                    readOnly={ReadOnly}
                    required
                  />
                </div>
                <div className="col-md-3">
                  <label className="col-form-label  text-sm-right">Discapacidad*</label>
                  <select
                    className="form-control form-select validarForm"
                    name="disability"
                    value={formData.disability}
                    onChange={handleInputChange}
                    readOnly={ReadOnly}
                    required
                  >
                    <option value="" disabled={ReadOnly}>
                      Seleccione
                    </option>
                    <option value="Ninguno" disabled={ReadOnly}>
                      Ninguno
                    </option>
                    <option value="Física" disabled={ReadOnly}>
                      Física
                    </option>
                    <option value="Auditiva" disabled={ReadOnly}>
                      Auditiva
                    </option>
                    <option value="Visual" disabled={ReadOnly}>
                      Visual
                    </option>
                    <option
                      value="Transtorno espectro autista"
                      disabled={ReadOnly}
                    >
                      Transtorno espectro autista
                    </option>
                    <option
                      value="Discapacidad psicosocial mental"
                      disabled={ReadOnly}
                    >
                      Discapacidad psicosocial mental
                    </option>
                    <option value="Otra" disabled={ReadOnly}>
                      Otra
                    </option>
                  </select>
                </div>
                
                <div className="col-md-3">
                  <label className="col-form-label  text-sm-right">
                    Institución de Procedencia*
                  </label>
                  <input
                    type="text"
                    className="form-control validarForm"
                    name="institution"
                    value={formData.institution}
                    onChange={handleInputChange}
                    readOnly={ReadOnly}
                    required
                  />
                </div>

                <div className="col-md-6">
                  <label className="col-form-label  text-sm-right">Estado del padre:</label>
                  <select
                    className="form-control form-select validarForm"
                    name="fatherStatusId"
                    value={formData.fatherStatusId}
                    onChange={handleInputChange}
                    readOnly={ReadOnly}
                    required
                  >
                    {parentStatusType.map((option) => (
                      <option
                        key={option.id}
                        value={option.id}
                        disabled={ReadOnly}
                      >
                        {option.text}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-md-6">
                  <label className="col-form-label  text-sm-right">Estado de la madre:</label>
                  <select
                    className="form-control form-select validarForm"
                    name="motherStatusId"
                    value={formData.motherStatusId}
                    onChange={handleInputChange}
                    readOnly={ReadOnly}
                    required
                  >
                    {parentStatusType.map((option) => (
                      <option
                        key={option.id}
                        value={option.id}
                        disabled={ReadOnly}
                      >
                        {option.text}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-md-12 mt-5 form-check mb-1 mr-sm-2">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="medicalDiagnosticCheck customControlInline"
                    name="medicalDiagnosticCheck"
                    value="mother"
                    onChange={(e)=>{ 
                      setShowMedicalDiagnostic(e.target.checked);
                      if(!e.target.checked)
                      setFormData((prevState) => ({
                        ...prevState,
                        medicalDiagnostic:'',
                      }));
                    }}
                    checked={showMedicalDiagnostic}
                    disabled={ReadOnly}
                  />
                  <label htmlFor="medicalDiagnosticCheck" className="col-form-label  text-sm-right form-check-label">
                    {" "}
                    El estudiante presenta algún diagnostico medico, psiquiátrico o psicológico
                  </label>
                </div>
                {(showMedicalDiagnostic && 
                <div className="col-md-12 mb-3" >
                  <label className="col-form-label  text-sm-right">Diagnostico*</label>
                  <input
                    id="txtDiagnostico"
                    type="text"
                    className="form-check-input form-control"
                    name="medicalDiagnostic"
                    value={formData.medicalDiagnostic}
                    onChange={handleInputChange}
                    readOnly={ReadOnly}
                    required
                  />
                </div>)}
              </div>
            </div>
          </div>
          </div>
          <br />
          {(showFatherInfo && 
          <>
                    <div className="col-md-12">
            <div id="fatherContainer" className="card">
              <div className="card-header bg-light text-center">
                <h5 className="card-title">
                  <br />
                  INFORMACIÓN DEL PADRE
                  <br />
                </h5>
              </div>
              <div className="card-body">
                <div className="row">
                <div className="col-md-3">
                    <label className="col-form-label  text-sm-right">Tipo ID*</label>
                    <select
                      className="form-control form-select validarForm"
                      name="fatherIdType"
                      value={formData.fatherIdType}
                      onChange={handleInputChange}
                      readOnly={ReadOnly}
                      required
                    >
                      <option value="" disabled={ReadOnly}>
                        Seleccione
                      </option>
                      {identificationTypes.map((option) => (
                        <option
                          key={option.id}
                          value={option.id}
                          disabled={ReadOnly}
                        >
                          {option.text}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-md-3">
                    <label className="col-form-label  text-sm-right">ID Padre*</label>
                    <input
                      type="text"
                      className="form-control validarForm"
                      name="fatherId"
                      value={formData.fatherId}
                      onChange={handleInputChange}
                      readOnly={ReadOnly}
                      required
                    />
                  </div>
                  <div className="col-md-3">
                    <label className="col-form-label  text-sm-right">Lugar de Exp.*</label>
                    <input
                      type="text"
                      className="form-control validarForm"
                      name="fatherPlaceOfIssue"
                      value={formData.fatherPlaceOfIssue}
                      onChange={handleInputChange}
                      readOnly={ReadOnly}
                      placeholder="Lugar de expedición"
                      required
                    />
                  </div>
                  <div className="col-md-3">
                    <label className="col-form-label  text-sm-right">
                      Fecha de Nacimiento*
                    </label>
                    <input
                      type="date"
                      className="form-control validarForm"
                      name="fatherBirthDate"
                      value={formData.fatherBirthDate}
                      onChange={handleInputChange}
                      placeholder="dd/mm/aaaa"
                      max={ValidarFechaPadres(maxAdultDate)}
                      readOnly={ReadOnly}
                      required
                    />
                  </div>
                  
                  
                  <div className="col-md-3">
                    <label className="col-form-label  text-sm-right">Primer Nombre*</label>
                    <input
                      type="text"
                      className="form-control validarForm"
                      name="fatherFirstName"
                      pattern="[A-Za-z\s]+"
                      title="Por favor, ingrese solo letras."
                      value={formData.fatherFirstName}
                      onChange={handleInputChange}
                      readOnly={ReadOnly}
                      required
                    />
                  </div>
                  <div className="col-md-3">
                    <label className="col-form-label  text-sm-right">Segundo Nombre</label>
                    <input
                      type="text"
                      className="form-control"
                      name="fatherSecondName"
                      pattern="[A-Za-z\s]+"
                      title="Por favor, ingrese solo letras."
                      value={formData.fatherSecondName}
                      onChange={handleInputChange}
                      readOnly={ReadOnly}
                    />
                  </div>
                  <div className="col-md-3">
                    <label className="col-form-label  text-sm-right">Primer Apellido*</label>
                    <input
                      type="text"
                      className="form-control validarForm"
                      name="fatherFirstSurname"
                      value={formData.fatherFirstSurname}
                      onChange={handleInputChange}
                      readOnly={ReadOnly}
                      required
                    />
                  </div>
                  <div className="col-md-3">
                    <label className="col-form-label  text-sm-right">Segundo Apellido</label>
                    <input
                      type="text"
                      className="form-control"
                      name="fatherSecondSurname"
                      value={formData.fatherSecondSurname}
                      onChange={handleInputChange}
                      readOnly={ReadOnly}
                    />
                  </div>
                  
                  <div className="col-md-3">
                    <label className="col-form-label  text-sm-right">Celular*</label>
                    <input
                      type="tel"
                      className="form-control validarForm"
                      name="fatherCellphone"
                      value={formData.fatherCellphone}
                      onChange={handleInputChange}
                      readOnly={ReadOnly}
                      required
                    />
                  </div>
                  <div className="col-md-3">
                    <label className="col-form-label  text-sm-right">Teléfono*</label>
                    <input
                      type="tel"
                      className="form-control validarForm"
                      name="fatherPhone"
                      value={formData.fatherPhone}
                      onChange={handleInputChange}
                      readOnly={ReadOnly}
                    />
                  </div>
                  <div className="col-md-3">
                    <label className="col-form-label  text-sm-right">Dirección residencia*</label>
                    <input
                      type="text"
                      className="form-control validarForm"
                      name="fatherAddress"
                      value={formData.fatherAddress}
                      onChange={handleInputChange}
                      placeholder="Dirección de residencia"
                      readOnly={ReadOnly}
                      required
                    />
                  </div>
                  <div className="col-md-3">
                    <label className="col-form-label  text-sm-right">Email*</label>
                    <input
                      type="email"
                      className="form-control validarForm"
                      name="fatherEmail"
                      value={formData.fatherEmail}
                      onChange={handleInputChange}
                      placeholder="correo@gmail.com"
                      readOnly={ReadOnly}
                    />
                  </div>
                  <div className="col-md-6">
                    <label className="col-form-label  text-sm-right">Nivel Académico*</label>
                    <input
                      type="text"
                      className="form-control validarForm"
                      name="fatherAcademicLevel"
                      value={formData.fatherAcademicLevel}
                      onChange={handleInputChange}
                      readOnly={ReadOnly}
                      required
                    />
                  </div>
                  <div className="col-md-6">
                    <label className="col-form-label  text-sm-right">Profesión*</label>
                    <input
                      type="text"
                      className="form-control validarForm"
                      name="fatherProfession"
                      value={formData.fatherProfession}
                      onChange={handleInputChange}
                      required
                      readOnly={ReadOnly}
                    />
                  </div>
                  <div className="col-md-3">
                    <label className="col-form-label  text-sm-right">Tipo de trabajo*</label>
                    <select
                      className="form-control form-select validarForm"
                      name="fatherJobType"
                      value={formData.fatherJobType}
                      onChange={handleInputChange}
                      readOnly={ReadOnly}
                      required
                    >
                      <option value="" disabled={ReadOnly}>
                        Seleccione
                      </option>
                      {jobTypes.map((option) => (
                        <option
                          key={option.id}
                          value={option.id}
                          disabled={ReadOnly}
                        >
                          {option.text}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-md-3">
                    <label className="col-form-label  text-sm-right">Nombre lugar trabajo*</label>
                    <input
                      type="text"
                      className="form-control validarForm"
                      name="fatherWorkplace"
                      value={formData.fatherWorkplace}
                      onChange={handleInputChange}
                      readOnly={ReadOnly}
                      required
                    />
                  </div>
                  
                  <div className="col-md-3">
                    <label className="col-form-label  text-sm-right">Cargo*</label>
                    <input
                      type="text"
                      className="form-control validarForm"
                      name="fatherPosition"
                      value={formData.fatherPosition}
                      onChange={handleInputChange}
                      readOnly={ReadOnly}
                      required
                    />
                  </div>
                  <div className="col-md-3 mt-5 form-check">
                    <input
                      className="form-check-input validarForm"
                      type="checkbox"
                      id="fatherGuardian"
                      value="father"
                      checked={formData.fatherIsParentMain}
                      onChange={ValidarAcudientePrincipal}
                      disabled={ReadOnly}
                    />
                    <label htmlFor="fatherGuardian" className="col-form-label  text-sm-right form-check-label">
                      {" "}
                      Acudiente principal
                    </label>
                    <small>
                      <br />
                      Nota: Asigna el responsable principal del estudiante
                    </small>
                  </div>
                </div>
              </div>
            </div>
            </div>


            <br />
          </>
          )}
          {(showMotherInfo && 
          <>
          <div className="col-md-12">
            <div id="motherContainer" className="card">
              <div className="card-header bg-light text-center">
                <h5 className="card-title">
                  <br />
                  INFORMACIÓN DE LA MADRE
                  <br />
                </h5>
              </div>
              <div className="card-body">
                <div className="row">
                <div className="col-md-3">
                    <label className="col-form-label  text-sm-right">Tipo ID*</label>
                    <select
                      className="form-control form-select validarForm"
                      name="motherIdType"
                      value={formData.motherIdType}
                      onChange={handleInputChange}
                      readOnly={ReadOnly}
                      required
                    >
                      <option value="" disabled={ReadOnly}>
                        Seleccione
                      </option>
                      {identificationTypes.map((option) => (
                        <option
                          key={option.id}
                          value={option.id}
                          disabled={ReadOnly}
                        >
                          {option.text}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-md-3">
                    <label className="col-form-label  text-sm-right">ID Madre*</label>
                    <input
                      type="text"
                      className="form-control validarForm"
                      name="motherId"
                      value={formData.motherId}
                      onChange={handleInputChange}
                      readOnly={ReadOnly}
                      required
                    />
                  </div>
                  <div className="col-md-3">
                    <label className="col-form-label  text-sm-right">Lugar de Exp.*</label>
                    <input
                      type="text"
                      className="form-control validarForm"
                      name="motherPlaceOfIssue"
                      value={formData.motherPlaceOfIssue}
                      onChange={handleInputChange}
                      readOnly={ReadOnly}
                      required
                    />
                  </div>
                  <div className="col-md-3">
                    <label className="col-form-label  text-sm-right">
                      Fecha de Nacimiento*
                    </label>
                    <input
                      type="date"
                      className="form-control validarForm"
                      name="motherBirthDate"
                      value={formData.motherBirthDate}
                      onChange={handleInputChange}
                      placeholder="dd/mm/aaaa"
                      max={ValidarFechaPadres(maxAdultDate)}
                      readOnly={ReadOnly}
                      required
                    />
                  </div>
                  <div className="col-md-3">
                    <label className="col-form-label  text-sm-right">Primer Nombre*</label>
                    <input
                      type="text"
                      className="form-control validarForm"
                      name="motherFirstName"
                      pattern="[A-Za-z\s]+"
                      title="Por favor, ingrese solo letras."
                      value={formData.motherFirstName}
                      onChange={handleInputChange}
                      readOnly={ReadOnly}
                      required
                    />
                  </div>
                  <div className="col-md-3">
                    <label className="col-form-label  text-sm-right">Segundo Nombre</label>
                    <input
                      type="text"
                      className="form-control"
                      name="motherSecondName"
                      pattern="[A-Za-z\s]+"
                      title="Por favor, ingrese solo letras."
                      value={formData.motherSecondName}
                      onChange={handleInputChange}
                      readOnly={ReadOnly}
                    />
                  </div>
                  <div className="col-md-3">
                    <label className="col-form-label  text-sm-right">Primer Apellido*</label>
                    <input
                      type="text"
                      className="form-control validarForm"
                      name="motherFirstSurname"
                      value={formData.motherFirstSurname}
                      onChange={handleInputChange}
                      readOnly={ReadOnly}
                      required
                    />
                  </div>
                  <div className="col-md-3">
                    <label className="col-form-label  text-sm-right">Segundo Apellido</label>
                    <input
                      type="text"
                      className="form-control"
                      name="motherSecondSurname"
                      value={formData.motherSecondSurname}
                      onChange={handleInputChange}
                      readOnly={ReadOnly}
                    />
                  </div>
                 
                  <div className="col-md-3">
                    <label className="col-form-label  text-sm-right">Celular*</label>
                    <input
                      type="tel"
                      className="form-control validarForm"
                      name="motherCellPhone"
                      value={formData.motherCellPhone}
                      onChange={handleInputChange}
                      readOnly={ReadOnly}
                      required
                    />
                  </div>
                  <div className="col-md-3">
                    <label className="col-form-label  text-sm-right">Teléfono*</label>
                    <input
                      type="tel"
                      className="form-control validarForm"
                      name="motherPhone"
                      value={formData.motherPhone}
                      onChange={handleInputChange}
                      readOnly={ReadOnly}
                    />
                  </div>
                  <div className="col-md-3">
                    <label className="col-form-label  text-sm-right">Dirección residencia*</label>
                    <input
                      type="text"
                      className="form-control validarForm"
                      name="motherAddress"
                      value={formData.motherAddress}
                      onChange={handleInputChange}
                      placeholder="Dirección de residencia"
                      readOnly={ReadOnly}
                      required
                    />
                  </div>
                  <div className="col-md-3">
                    <label className="col-form-label  text-sm-right">Email*</label>
                    <input
                      type="email"
                      className="form-control validarForm"
                      name="motherEmail"
                      value={formData.motherEmail}
                      onChange={handleInputChange}
                      placeholder="correo@gmail.com"
                      readOnly={ReadOnly}
                    />
                  </div>
                  <div className="col-md-6">
                    <label className="col-form-label  text-sm-right">Nivel Académico*</label>
                    <input
                      type="text"
                      className="form-control validarForm"
                      name="motherAcademicLevel"
                      value={formData.motherAcademicLevel}
                      onChange={handleInputChange}
                      readOnly={ReadOnly}
                      required
                    />
                  </div>
                  <div className="col-md-6">
                    <label className="col-form-label  text-sm-right">Profesión*</label>
                    <input
                      type="text"
                      className="form-control validarForm"
                      name="motherProfession"
                      value={formData.motherProfession}
                      onChange={handleInputChange}
                      readOnly={ReadOnly}
                      required
                    />
                  </div>
                  <div className="col-md-3">
                    <label className="col-form-label  text-sm-right">Tipo de trabajo*</label>
                    <select
                      className="form-control form-select validarForm"
                      name="motherJobType"
                      value={formData.motherJobType}
                      onChange={handleInputChange}
                      readOnly={ReadOnly}
                      required
                    >
                      <option value="" disabled={ReadOnly}>
                        Seleccione
                      </option>
                      {jobTypes.map((option) => (
                        <option
                          key={option.id}
                          value={option.id}
                          disabled={ReadOnly}
                        >
                          {option.text}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-md-3">
                    <label className="col-form-label  text-sm-right">Nombre lugar trabajo*</label>
                    <input
                      type="text"
                      className="form-control validarForm"
                      name="motherWorkplace"
                      value={formData.motherWorkplace}
                      onChange={handleInputChange}
                      readOnly={ReadOnly}
                      required
                    />
                  </div>

                  <div className="col-md-3">
                    <label className="col-form-label  text-sm-right">Cargo*</label>
                    <input
                      type="text"
                      className="form-control validarForm"
                      name="motherPosition"
                      value={formData.motherPosition}
                      onChange={handleInputChange}
                      readOnly={ReadOnly}
                      required
                    />
                  </div>
                  <div className="col-md-3 mt-5 form-check">
                    <input
                      className="form-check-input validarForm"
                      type="checkbox"
                      id="motherGuardian"
                      name="guardian"
                      value="mother"
                      onChange={ValidarAcudientePrincipal}
                      checked={formData.motherIsParentMain}
                      disabled={ReadOnly}
                    />
                    <label htmlFor="motherGuardian" className="col-form-label  text-sm-right form-check-label">
                      {" "}
                      Acudiente principal
                    </label>
                    <small>
                      <br />
                      Nota: Asigna el responsable principal del estudiante
                    </small>
                  </div>
                </div>
              </div>
            </div>
            </div>


            <br />
          </>
          )}
          <div className="col-md-12">
          <div className="card">
            <div className="card-header bg-light text-center">
              <h5 className="card-title">
                <br />
                DETALLES DE INSCRIPCIÓN
                <br />
              </h5>
            </div>
            <div className="card-body">
              <div className="row">
              
                <div className="col-md-12">
                  <label className="col-form-label  text-sm-right">
                    Explique en las siguientes líneas las razones por las cuales
                    desea ingresar a su hijo(a) en esta Institución Educativa*
                  </label>
                  <textarea
                    className="form-control textarea-form validarForm"
                    name="enrollmentReason"
                    value={formData.enrollmentReason}
                    onChange={handleInputChange}
                    readOnly={ReadOnly}
                    required
                  ></textarea>
                </div>
                <div className="col-md-6 mt-3">
                  <label className="col-form-label  text-sm-right">
                    ¿Con quién vive el estudiante?*
                  </label>
                  <input
                    type="text"
                    className="form-control validarForm"
                    name="studentLivingWith"
                    value={formData.studentLivingWith}
                    onChange={handleInputChange}
                    readOnly={ReadOnly}
                    required
                  />
                </div>
                <div className="col-md-6 mt-3">
                  <label className="col-form-label  text-sm-right">Señale si viene recomendado (a) por alguien en especial*
                  </label>
                  <input
                    type="text"
                    className="form-control validarForm"
                    name="recommendation"
                    value={formData.recommendation}
                    onChange={handleInputChange}
                    readOnly={ReadOnly}
                    required
                  />
                </div>
               
               
                

                {!hideBtnInscription && (
                  <small className="text-secondary mt-4">
                    Al hacer clic en "Agregar Pre-Inscripción", aceptas las
                    Condiciones y confirmas que has leído nuestra Política de
                    tratamiento de datos.
                  </small>
                )}

                <div className="d-flex justify-content-center mb-4">
                  {!hideBtnInscription && (
                    <button
                      type="submit"
                      className="btn btn-primary  mb-3"
                    >
                      Agregar Pre-Inscripción
                    </button>
                  )}
                  {!ReadOnly && hideBtnInscription && (
                    <button
                      type="submit"
                      className="btn btn-primary  mb-3"
                    >
                      Guardar estudiante
                    </button>
                  )}
                </div>
              </div>
            </div>
            </div>
            </div>

        </form>
      )}
    </>
  );
}

export default StudentForm;
