import groupDirector from "../services/groupDirector";
import { toast } from 'react-toastify';

const AsignacionTutores = ({
    titulo,
    cursos,
    tutores,
    asignaciones,
    handleTutorChange,
    loadTutores
  }) => {
    console.log(titulo); // Añade esto para depurar
  
        // Funciones para guardar cambios
    const guardarCambios = async (courseId) => {
      let saved = false;
      try{
      const element =asignaciones.find(a=>Number(a.courseId)===Number(courseId));
      if(element.groupDirectorId>0){
          if(element.changed){
            const respDirectores =  await groupDirector.updateDirectorGrupo(element);
            saved = (respDirectores && respDirectores.groupDirectorId>0);
          }
      }else{
        const respDirectores =  await groupDirector.createDirectorGrupo(element);
        saved = (respDirectores && respDirectores.groupDirectorId>0);
      }
      
      if(saved){
        toast.success("Tutor guardado exitosamente!!!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        await loadTutores();
      }
      }catch(ex){
        toast.error("Ocurrio un error guardando el tutor, por favor, intentalo nuevamente.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    };

    return (
      <>
      
      <div className="card">
        <div className="card-header">
          <h5 className="card-title">{titulo}</h5>
          <h6 className="card-subtitle text-muted">
            Selecciona un tutor para cada curso.
          </h6>
        </div>
        <div className="card-body">
          <div id={`${(titulo || "nivel").toLowerCase()}-courses`}>
            {cursos.map((curso) => (
              <div
                key={curso.courseId}
                className="card mb-3 bg-light cursor-grab border"
              >
                <div className="card-body p-3">
                  <h6 className="mb-2">Curso: {curso.name}</h6>
                  <div className="mb-3">
                    <label
                      htmlFor={`tutor-select-${curso.courseId}`}
                      className="col-form-label  text-sm-right"
                    >
                      Selecciona un tutor:
                    </label>
                    <select
                      className="form-select"
                      id={`tutor-select-${curso.courseId}`}
                      value={asignaciones.find(a=>a.courseId === curso.courseId)?.teacherId || ""}
                      onChange={(e) =>
                        handleTutorChange(curso.courseId, e.target.value)
                      }
                    >
                      <option value="">Elige un tutor...</option>
                      {tutores.map((tutor) => (
                        <option key={tutor.teacherId} value={tutor.teacherId}>
                          {tutor.fullName}
                        </option>
                      ))}
                    </select>
                    <div className="d-grid mt-3 ">
                      <button
                        className="btn btn-primary"
                        onClick={()=>{guardarCambios(curso.courseId)}}
                      >
                        Guardar Cambios
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      </>
    );
  };
  
  export default AsignacionTutores;
  