import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import "../../css/estudiantes.css";
import academicService from "../../services/academicService";
import courseService from '../../services/courseService';


const JornadaAnioNivelCurso = forwardRef((props, ref) => {

    const { callback, properties, ReadOnly } = props; 
    const [journeyList, setJourneyList] = useState([]);
    const [schoolYearList, setSchoolYearList] = useState([]);
    const [years, setYears] = useState([]);
    const [educationLevelsList, setEducationLevelsList] = useState([]);
    const [educationLevels, setEducationLevels] = useState([]);
    const [coursesList, setCoursesList] = useState([]);
    const [courses, setCourses] = useState([]);
    let yearsList = [];
    let allEducationLevels = [];
    let allCourses = [];
    const initData ={
        schoolYearId:'0',
        journeyId:'0',
        educationLevelId:'0',
        courseId:'0',
    };
    const [formData, setFormData] = useState(initData);

    useImperativeHandle(ref, () => ({
        setFormValues(data) {
            setFormData(prevState => ({
            ...prevState,
            ...data
        }));
        },
        getCursos(){
            return courses;
        },
        getEducationLeves(){
            return educationLevels;
        },
        getSchoolYears(){
            return years;
        },
        getJourneys(){
            return journeyList;
        }
    }));

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
        if (name.includes("journeyId")){    
          const cmbJourney = (properties && properties.id)?`cmbJourney-${properties.id}`:'cmbJourney';
          const journeyId = document.getElementById(cmbJourney).value;
          LoadYears(journeyId);
          LoadCourses(journeyId,"0");
          LoadEducationLevels(journeyId);
        }else if(name.includes("educationLevelId")){
            const cmbJourney = (properties && properties.id)?`cmbJourney-${properties.id}`:'cmbJourney';
            const journeyId = document.getElementById(cmbJourney).value;
            const cmbEducationLevelId = (properties && properties.id)?`educationLevelId-${properties.id}`:'educationLevelId';
            const educationLevelId = document.getElementById(cmbEducationLevelId).value;
            LoadCourses(journeyId,educationLevelId);
        }
    };

    useEffect(() => {
        if(callback){
            callback(formData);
        }
      }, [formData]);
      
    const fetchSchoolYears = async () => {
        try {
            const yearsResp = await academicService.getYears();
            setSchoolYearList(yearsResp);
            setYears(yearsResp);
        } catch (error) {
            console.error("Error al obtener listas de formulario:", error);
        }
    };

    const fetchEducationLevels = async () => {
        try {
            const educationLevelsResp = await courseService.getEducationLevels();
            setEducationLevelsList(educationLevelsResp);
            setEducationLevels(educationLevelsResp);
        } catch (error) {
            console.error("Error al obtener listas de formulario:", error);
        }
    };

    const fetchCourses = async () => {
        try {
            const coursesResp = await courseService.getCursos();
            setCoursesList(coursesResp);
            setCourses(coursesResp);
        } catch (error) {
            console.error("Error al obtener listas de formulario:", error);
        }
    };

    const fetchLoadFiltersSchoolYears = async () => {
      try {
          const journeyResp = await academicService.getJourneys();
          setJourneyList(journeyResp);
          
          yearsList  = await academicService.getYears();
          setSchoolYearList(yearsList);
          setYears(yearsList);

          allEducationLevels =  await courseService.getEducationLevels(); 
          setEducationLevelsList(allEducationLevels);
          setEducationLevels(allEducationLevels);

          allCourses =  await courseService.getCursos(); 
          setCoursesList(allCourses);
          setCourses(allCourses);
      } catch (error) {
          console.error("Error al obtener listas de formulario:", error);
      }
    };

    const LoadYears = (journeyId) => {
      if(journeyId !== "0"){

        if (yearsList.length > 0) {
          const yrs = yearsList.filter((op) => String(op.journeyId) === journeyId);
          setYears(yrs);
        } else if (schoolYearList.length > 0) {
          const yrs = schoolYearList.filter((op) => String(op.journeyId) === journeyId);
          setYears(yrs);
        }
      }else{
        fetchSchoolYears();
      }
      setFormData(prevState => ({
        ...prevState,
        schoolYearId: '0'
      }));
    };

    const LoadEducationLevels = (journeyId) => {
        if(journeyId !== "0"){

            let tempCouse=[];
            if (allCourses.length > 0) {
                tempCouse = allCourses.filter((op) => String(op.journeyId) === journeyId);
            } else if (coursesList.length > 0) {
                tempCouse = coursesList.filter((op) => String(op.journeyId) === journeyId);
            }

            if (allEducationLevels.length > 0) {
                const edFiltred = allEducationLevels.filter((ed) => tempCouse.filter((c) => c.educationLevelId === ed.educationLevelId).length > 0 );
                setEducationLevels(edFiltred);
            } else if (educationLevelsList.length > 0) {
                const edFiltred = educationLevelsList.filter((ed) => tempCouse.filter((c) => c.educationLevelId === ed.educationLevelId).length > 0);
                setEducationLevels(edFiltred);
            }
        }else{
            fetchEducationLevels();
        }
        setFormData(prevState => ({
        ...prevState,
        educationLevelId: '0'
        }));
    };

    const LoadCourses = (journeyId, educationLevelId) => {
        if(journeyId !== "0" || educationLevelId !== "0"){
          if (allCourses.length > 0) {
            let courseFiltred = (journeyId !== "0")? allCourses.filter((op) =>  (String(op.journeyId) === String(journeyId))) : allCourses;
            courseFiltred = (educationLevelId !== "0") ? courseFiltred.filter((op) => (String(op.educationLevelId) === String(educationLevelId))) : courseFiltred;
            setCourses(courseFiltred);
          } else if (coursesList.length > 0) {
            let courseFiltred = (journeyId !== "0")? coursesList.filter((op) =>  (String(op.journeyId) === String(journeyId))) : coursesList;
            courseFiltred = (educationLevelId !== "0") ? courseFiltred.filter((op) => (String(op.educationLevelId) === String(educationLevelId))) : courseFiltred;
            setCourses(courseFiltred);
          }
        }else{
          fetchCourses();
        }
        setFormData(prevState => ({
          ...prevState,
          courseId: '0'
        }));
    };

    useEffect(() => {
      fetchLoadFiltersSchoolYears();
    }, []);

  return (
    <>
        <div className={(properties && properties.className)?properties.className:""} >
            <label className="">Jornada:</label>
            <select
            id={(properties && properties.id)?`cmbJourney-${properties.id}`:"cmbJourney"}
            className="col-md-6 form-control form-select validarForm"
            name="journeyId"
            value={formData.journeyId}
            onChange={handleChange}
            disabled={ReadOnly}
            >
            <option value="0"> {(properties && !properties.filtro)?'Seleccione':'Todos'}</option>
            {journeyList.map((option) => (
                <option key={option.journeyId} value={option.journeyId}>
                {option.name}
                </option>
            ))}
            </select>
        </div>
        <div className={(properties && properties.className)?properties.className:""}>
                <label htmlFor={(properties && properties.id)?`schoolYearId-${properties.id}`:"schoolYearId"}
                 className="">Año:</label>
                <select className="form-control form-select" 
                id={(properties && properties.id)?`schoolYearId-${properties.id}`:"schoolYearId"}
                name="schoolYearId" 
                value={formData.schoolYearId} 
                onChange={handleChange} 
                disabled={ReadOnly}>
                    <option value="0" > {(properties && !properties.filtro)?'Seleccione':'Todos'}</option>
                    {years.map((schoolYear) => (
                        <option key={schoolYear.schoolYearId} value={schoolYear.schoolYearId} >
                            {schoolYear.year}
                        </option>
                    ))}
                </select>
        </div>
        <div className={(properties && properties.className)?properties.className:""}>
                <label htmlFor={(properties && properties.id)?`educationLevelId-${properties.id}`:"educationLevelId"} className="">Nivel educativo:</label>
                <select className="form-control form-select" 
                id={(properties && properties.id)?`educationLevelId-${properties.id}`:"educationLevelId"}
                name="educationLevelId" 
                value={formData.educationLevelId} 
                onChange={handleChange} 
                disabled={ReadOnly}>
                    <option value="0" > {(properties && !properties.filtro)?'Seleccione':'Todos'}</option>
                    {educationLevels.map((level) => (
                        <option key={level.educationLevelId} value={level.educationLevelId} >
                            {level.name}
                        </option>
                    ))}
                </select>
        </div>
        <div className={(properties && properties.className)?properties.className:""}>
                <label htmlFor={(properties && properties.id)?`courseId-${properties.id}`:"courseId"} className="">Curso:</label>
                <select className="form-control form-select" 
                id={(properties && properties.id)?`courseId-${properties.id}`:"courseId"}
                name="courseId" 
                value={formData.courseId} 
                onChange={handleChange} 
                disabled={ReadOnly}>
                    <option value="0" > {(properties && !properties.filtro)?'Seleccione':'Todos'}</option>
                    {courses.map((course) => (
                        <option key={course.courseId} value={course.courseId} >
                            {course.name}
                        </option>
                    ))}
                </select>
        </div>
    </>
    );
});

export default JornadaAnioNivelCurso;