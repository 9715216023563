import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faUser } from '@fortawesome/free-solid-svg-icons';
import { ProgressBar } from 'react-bootstrap';
import EvaluacionesDetalle from '../../components/tablaDimensiones'; // Asegúrate de importar correctamente el componente
import "../../css/tabla-notas-viewEstudiantes.css";
import DashboardCard from '../../components/TituloDashboard';
import studentPhoto from '../../img/user-profile.png';
import organizacionAcademicaService from '../../services/organizacionAcademicaService';
import collectionsService from '../../services/collectionsService';
import estudiantesService from '../../services/estudiantesService';
import evaluativeScaleService from '../../services/evaluativeScaleService';
import util from '../../utils/generalFunctions';
import reportsService from '../../services/reportsService';
import { toast } from 'react-toastify';
import FiltroPeriodoEscolar from '../../components/filtroperiodoescolarEstudianteNotasVista';


const NotasEstudiantesPcPreescolar = () => {
  const studentInfo = util.getStudentInformation();
  const [listaAsignaturas, setListaAsignaturas] = useState([]);
  const [listaNotas, setListaNotas] = useState([]);
  const [notas, setNotas] = useState(null);
  const [evaluationScale, setEvaluationScale] = useState([]);
  const filteReference = useRef(null);
  const [formData, setFormData] = useState({
    periodId:'',
    schoolSubjectId:'',
  });
  let listaNotasTemp = [];

  const [selectedSubject, setSelectedSubject] = useState(null);

  const fetchLoadAsignaturas = async () => {
    const courseId = studentInfo.courseId;
    const schoolYearId = studentInfo.schoolYearId;
    const evaluationTypeId = studentInfo.evaluationTypeId;
    try{
      
      const evaluationScale = await evaluativeScaleService.getEvaluativeScale(evaluationTypeId);
      setEvaluationScale(evaluationScale);

      await cargarListaNotas();
      const asignaturas = await organizacionAcademicaService.getAcademicOrganizationsByCourse(courseId, schoolYearId);
      setListaAsignaturas(asignaturas);

      if(asignaturas!=null && asignaturas.length>0){
        setFormData(prevState => ({
          ...prevState,
          schoolSubjectId:asignaturas[0].schoolSubjectId
        }));
      }
    }catch(ex){
      console.error("Error al obtener listas de asignaturas:", ex);
    }
  }

  const cargarListaNotas= async () =>{
    if(formData.periodId!==''){
      
      const result = await reportsService.getPermisosEstudiantes(
        studentInfo.studentId,
        studentInfo.schoolYearId,
        formData.periodId
      ); // Obtenemos los estudiantes de tu sistema
          const permiso = result.find(d=>d.reportTypeId===2 && d.visible===true);
          if(permiso){
          const studentId = studentInfo.studentId;
          const courseId = studentInfo.courseId;
          const schoolYearId = studentInfo.schoolYearId;
          try{
            const resultNotas = await estudiantesService.getEstudianteNotaDimension(studentId, courseId, "", schoolYearId, formData.periodId,0);
            if(resultNotas && resultNotas.length > 0 ){
              setListaNotas(resultNotas);
              listaNotasTemp=resultNotas;
            }else{
              setListaNotas([]);
            }
          }catch(ex){
            console.error("Error al obtener listas de asignaturas:", ex);
          }
        }else{
          showMensajePermiso();
          setNotas([]);
        } 
    }
  }

  const cargarNotas= async () =>{
    await cargarListaNotas();
    if(formData.schoolSubjectId!=='' && formData.periodId!==''){   
      const result = await reportsService.getPermisosEstudiantes(
        studentInfo.studentId,
        studentInfo.schoolYearId,
        formData.periodId
      ); // Obtenemos los estudiantes de tu sistema
          const permiso = result.find(d=>d.reportTypeId===2 && d.visible===true);
      if(permiso){
        const studentId = studentInfo.studentId;
        const courseId = studentInfo.courseId;
        const schoolYearId = studentInfo.schoolYearId;
        try{
          const resultNotas = await estudiantesService.getEstudianteNotaDimension(studentId, courseId, formData.schoolSubjectId, schoolYearId, formData.periodId, 0);
          if(resultNotas && resultNotas.length > 0 ){
            const item = resultNotas[0];
            setNotas(item);
            console.log(item);
          }else{
            setNotas([]);
          }
        }catch(ex){
          console.error("Error al obtener listas de asignaturas:", ex);
        }
      }
    }
  }

  const showMensajePermiso = () => {
    toast.info("Reporte no habilitado para este período", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
  }

  const seleccionarAsignatura = (asignatura) => {

    setFormData(prevState => ({
      ...prevState,
      schoolSubjectId:asignatura.schoolSubjectId
    }));
    setSelectedSubject(asignatura);
  };
  
  useEffect(() => {
    fetchLoadAsignaturas();
  }, []);

  useEffect(() => {
    cargarNotas();

  }, [formData]);

  useEffect(() => {
    const listaAsig = util.copy(listaAsignaturas);
    listaAsig.map(a=> {
      a.change = (a.change)?false:true;
      return a;
    });
    setListaAsignaturas(listaAsig);
  }, [notas]);

  const updateForm = async (data)  => {
    setFormData(prevState => ({
        ...prevState,
        periodId:data
      }));
  }

  return (
    <div className="">
      <div className="col-12 mb-3">
        <Link to="/dashboard" className="back-to-dashboard-pc">
                <FontAwesomeIcon icon={faArrowLeft} />
            </Link> 
      <DashboardCard
          studentPhoto={studentPhoto}
          title="Notas"
          subtitle="Detalles de tus notas"
        />

        </div>

        <div className='col-12 mb-3'>
              <div class="alert-message text-center">
                {evaluationScale.map((scale, index) => (
                  <>
                    <span className="margin-left-10">
                      {scale.name}{" "}
                      <strong style={{ color: scale.color }}>
                        {" "}{scale.abbreviation}{" "}
                      </strong>
                    </span>
                  </>
                  ))}
              </div>
          </div>

      <div className="d-flex justify-content-between">
        <div className="col-md-6 ">
        <div className="card">
        <FiltroPeriodoEscolar 
        schoolYearId={studentInfo.schoolYearId} 
        callback={updateForm}
        ref={filteReference} />
        <h3>Asignaturas</h3>
          {listaAsignaturas.map((subject, index) => (
            <div key={index} className="mb-3 p-3" style={{ cursor: "pointer" }} onClick={() => seleccionarAsignatura(subject)}>

              <div className="d-flex justify-content-between align-items-center">
                <h4>{subject.schoolSubjectName}</h4>
                <a href='#titulo-notasPanel'>
                <button className="btn " onClick={() => seleccionarAsignatura(subject)}>
                  {/* <i class="fa-solid fa-angles-right"></i> */}
                  <small> Ver detalle <i class="fa-solid fa-chevron-right"></i></small>
                </button>
                </a>
              </div>
              <a href='#titulo-notasPanel'>
                <ProgressBar
                  onClick={() => seleccionarAsignatura(subject)}
                  now={100}
                  label={""}
                  variant={"primary"}
                />
            </a>
          </div>
          ))}
        </div>
        </div>
        <div className="col-md-6">
          {selectedSubject && (
            <EvaluacionesDetalle 
            selectedSubject={selectedSubject}
            notas={notas} />
          )}
        </div>
      </div>
    </div>
  );
};

export default NotasEstudiantesPcPreescolar;
