import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import "../css/estudiantes.css";
import {
    MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
} from 'mdb-react-ui-kit';
import FormAcademicArea from "../components/Forms/FormAcademicArea"; // Asegúrate de tener este componente creado
import Swal from "sweetalert2";
import academicAreaService from "../services/academicAreaService"; // Asumiendo la existencia de este servicio
import useCustomDataTableStyles from "../components/estilosDeDataTable";
import types from '../services/types';

const ConfigureAcademicAreas = () => {
    const [academicAreas, setAcademicAreas] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedAcademicArea, setSelectedAcademicArea] = useState(null);
    const [filterText, setFilterText] = useState('');
    const [BtnVer, setBtnVer] = useState('');



    const toggleModal = () => {
        setModalOpen(!modalOpen);
        setSelectedAcademicArea(null);
    };

    const openEditModal = (academicArea) => {
        setSelectedAcademicArea(academicArea);
        setModalOpen(true);
        setBtnVer(false);
    };

    const openMaximizeModal = (academicArea) => {
      setSelectedAcademicArea(academicArea);
      setModalOpen(true);
      setBtnVer(true);

  };

    const deleteAcademicArea = async (academicArea) => {
      Swal.fire({
        title: '¿Está seguro de que desea eliminar esta área académica?',
        text: "",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sí, eliminar'
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await academicAreaService.deleteAcademicArea(academicArea.academicAreaId);
            Swal.fire(
              'Eliminado',
              'El área académica ha sido eliminada exitosamente.',
              'success'
            );
            fetchAcademicAreas();
          } catch (error) {
            Swal.fire(
              "Error",
              "Ha ocurrido un error al intentar eliminar el área académica.",
              "error"
            );
          }
        }
      });
    };

    const fetchAcademicAreas = async () => {
      try {
        const allData = await academicAreaService.getAllAcademicAreas();
        const data = allData.filter((areas) => areas.evaluationTypeId === types.evaluationTypes.ESCALA_VALORATIVA);
        setAcademicAreas(data);
      } catch (error) {
        console.error("Error al obtener las áreas académicas:", error);
      }
    };

    useEffect(() => {
        fetchAcademicAreas();
    }, []);

    const columns = [
      { name: "N°", selector: "consecutive",grow:0.05, sortable: true, 
      cell: (row, index) => <span>{index + 1}</span>, },        
        { name: "Nombre", selector: "name", grow:2, sortable: true },
        { name: "Abrev.", selector: "abbreviation", sortable: true },
        { name: "Orden", selector: "order", sortable: true },
        { name: "Activo", selector: "isActive", sortable: true, 
            cell: (row) => (row.isActive ? <span>Sí</span> : <span>No</span>),
        },
        {
            name: "Acciones",grow:"3",
            selector: "actions",
            cell: (row) => (
                <div className="btn-group btn-group-sm">
                    <button
                      onClick={() => openMaximizeModal(row)}
                      className="btn btn-outline-success me-1"
                      title="Maximizar información"
                    >
                      <i className="fas fa-eye"></i>
                    </button>
                    <button
                        onClick={() => openEditModal(row)}
                        className="btn btn-outline-info me-1"
                        title="Editar"
                    >
                        <i className="icon-edit fas fa-pencil-alt"></i>
                    </button>
                    <button
                        onClick={() => deleteAcademicArea(row)}
                        className="btn btn-outline-danger me-1"
                        title="Eliminar"
                    >
                        <i className="icon-deactivate fas fa-times"></i>
                    </button>
                </div>
            ),
        },
    ];

    const normalizeString = (str) => {
        return str
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toLowerCase();
    };

    const filteredItems = academicAreas.filter((item) => {
        const allData = `${item.name} ${item.abbreviation} ${item.order} ${item.isActive}`;
        const normalizedFilterText = normalizeString(filterText);
        const normalizedItemName = normalizeString(allData);
        return normalizedItemName.includes(normalizedFilterText);
    });

    const customStyles = useCustomDataTableStyles();

    const paginationPerPage = 50; // Ajusta este número según tus necesidades

    return (
      <div className="col-md-12">
      <div className="card">
        <div className="card-header">
          <h2 className="">Configuración de Áreas Académicas</h2>
          <h5 className="card-subtitle text-muted">Gestione las áreas académicas desde esta interfaz.</h5>
        </div>
        <div className="card-body">
          <form className="row mb-3">
            <div className="col-md-6 col-xl-4 mb-2 mb-md-0">
              <input
                type="text"
                placeholder="Buscar"
                value={filterText}
                onChange={e => setFilterText(e.target.value)}
                className="form-control"
              />
            </div>
            <div className="col-md-6 col-xl-8">
              <div className="text-end">
                <button onClick={() => openEditModal(null)} className="btn btn btn-primary mb-2">
                  Agregar Nueva Área <i className="fa-solid fa-circle-plus"></i>
                </button>
              </div>
            </div>
          </form>
        </div>

   
    
            <DataTable
                title="Lista de Áreas Académicas"
                noDataComponent="No hay información que mostrar, por favor añadela"   
                columns={columns}
                data={filteredItems}
                pagination
                fixedHeader
                fixedHeaderScrollHeight="450px"
                customStyles={customStyles}
                paginationPerPage={paginationPerPage} // Agrega esta propiedad para controlar las filas por página
                paginationRowsPerPageOptions={[50, 100]}

            />
            <MDBModal tabIndex='-1' show={modalOpen} setShow={setModalOpen}>
                <MDBModalDialog className="modal-xl-custom">
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>{selectedAcademicArea ? 'Editar Área' : 'Agregar Área'}</MDBModalTitle>
                            <MDBBtn className='btn-close' color='none' onClick={toggleModal}></MDBBtn>
                        </MDBModalHeader>
                        <MDBModalBody>
                        <div className='card'>
                                {selectedAcademicArea && (
                            <FormAcademicArea
                                key={Date.now()}
                                academicAreaItem={selectedAcademicArea ? selectedAcademicArea.academicAreaId : 0}
                                onClose={() => {
                                    setModalOpen(false);
                                    fetchAcademicAreas();
                                }}
                                ReadOnly={BtnVer}
                            />
                            )}
                              { !selectedAcademicArea && (
                                    <FormAcademicArea
                                        key={Date.now()}
                                        academicAreaItem={0}
                                        onClose={() =>{
                                            setModalOpen(false); 
                                            fetchAcademicAreas();
                                        }}
                                        ReadOnly={false}
                                    />
                                )}
                            </div>
                        </MDBModalBody>
                        <MDBModalFooter>
                            <button type='button' className='btn-footer-close btn-primary' onClick={toggleModal}>
                                Cerrar
                            </button>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
        </div>
        </div>
    );
};

export default ConfigureAcademicAreas;
