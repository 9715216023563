import { useRef, useState } from "react";
import JornadaAnioFilter from "../../components/Filters/JornadaAnioFilter";
import { Button } from "react-bootstrap";
import asistenciaService from "../../services/asistenciaService";
import moment from "moment";
import HeaderActa from "../../components/headerActas";
import utils from '../../utils/generalFunctions';
import academicService from "../../services/academicService";
import { toast } from 'react-toastify';
import Loading from "../../components/Loading";
import JornadaAnioPeriodoNivelCursoAsig from "../../components/Filters/JornadaAnioPeriodoNivelCursoAsig";
import JornadaAnioNivelCurso from "../../components/Filters/JornadaAnioNivelCurso";
import JornadaAnioPeriodoNivelCurso from "../../components/Filters/JornadaAnioPeriodoNivelCurso";
import FooterActa from "../../components/FooterActa";
import types from "../../services/types";
import EstudianteFilter from "../../components/Filters/EstudianteFilter";

const RepoAsistenciaTotalEstudiante = () => {
    const filteReference = useRef(null);
    const initData ={
        studentId:"0",
        schoolYearId: "0",
        journeyId:"0",
        startDate: "",
        periodId:"",
    };
    const [formData, setFormData] = useState(initData);
    const [asistencias, setAsistencias] = useState([]);
    const [subjects, setSubjects] = useState([]);
    const [datosBusqueda, setDatosBusqueda] = useState([]);
    const [periodos, setPeriodosList] = useState([]);
    const [showLoading, setShowLoading] = useState(false);
    
    
    const updateForm = async (data) => {
        if (data.schoolYearId!==formData.schoolYearId){   
          await cargarPeriodos(data.schoolYearId);
        }
        setFormData(prevState => ({
            ...prevState,
            ...data
          }));
    }

    const studentInfoChange = (student) => {
        if(student){
        filteReference?.current?.setFormValues({ 
            journeyId: student.journeyId,
            schoolYearId: student.schoolYearId,
            educationLevelId: student.educationLevelId,
            courseId: student.courseId});
        }else{
            filteReference?.current?.setFormValues({ 
                journeyId: '0',
                schoolYearId: '0',
                educationLevelId: '0',
                courseId: '0'});
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const cargarPeriodos = async (schoolYearId) => {
        try {
            const schoolYear = await academicService.getYearById(schoolYearId);              
            if(schoolYear){
                setPeriodosList(schoolYear.periods)
            }            
        } catch (error) {
             console.error("Error al obtener información de año escolar:", error);
        }
      }

    const generarCosolidadReporteTotal = async () =>{
        const requiredFields = ['schoolYearId', 'journeyId', 'startDate','periodId','educationLevelId','courseId','studentId'];
        for (let field of requiredFields) {
            if (!formData[field] || formData[field]==='0') {
                toast.error("Por favor, completa todos los campos obligatorios.", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                  });
                return;
            }
        }
        setDatosBusqueda([]);
        setShowLoading(true);
        try {
           const copyData = utils.copy(formData);
           copyData.periodName = periodos.find(p=>String(p.academicPeriodId)===String(copyData.periodId))?.periodName??"";
           const years = filteReference.current?.getSchoolYears();
           if(years){
                copyData.year = years.find(p=>String(p.schoolYearId)===String(copyData.schoolYearId))?.year??"";
           }
           const journeys = filteReference.current?.getJourneys();
           if(journeys){
                copyData.journey = journeys.find(p=>String(p.journeyId)===String(copyData.journeyId))?.name??"";
           }

           const courses = filteReference.current?.getCursos();
           if(courses){
                copyData.course = courses.find(p=>String(p.courseId)===String(copyData.courseId))?.name??"";
           }

           setDatosBusqueda(copyData);
           const date = moment(formData.startDate).format("yyyy-MM-DD");
           const asistenciasDB = await asistenciaService.getAsistenciasTotalReportStudent(formData.schoolYearId, formData.periodId, date, formData.courseId,formData.studentId);
            if(asistenciasDB && asistenciasDB.length>0){
                setAsistencias(asistenciasDB);

                const allSubjects = utils.removeDuplicates(asistenciasDB.map((o) => {return {schoolSubjectId: o.schoolSubjectId, name: o.schoolSubjectName, courseId: o.courseId, courseName: o.courseName, educationLevelId: o.educationLevelId, educationLevelName: o.educationLevelName}}));
                setSubjects(allSubjects);
                
            }else{
                setAsistencias([]);
                toast.error("No se encontraron asistencias para la fecha seleccionada.", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                  });
            }
        } catch (error) {
          console.error("Error al obtener asistencias:", error);
        }
        setShowLoading(false);
    }

    return (
        <>
          <div className="card p-3 filter-container">
            <h1>Consolidado total asistencias por estudiante</h1>
            <p>Selecciona los filtros para generar el reporte.</p>
            
                <div className="row">
                <EstudianteFilter 
                  callback={updateForm} 
                  callbackStudentInfo={studentInfoChange}
                  properties={
                      {
                          className:'col-md-12 mb-12',
                      }
                  }
                  />
                    <JornadaAnioPeriodoNivelCurso
                                callback={updateForm}
                                ref={filteReference}
                                loadTutoria={true}
                                loadCoordinators={true}
                                hideAsignatura={true}
                                properties={
                                    {
                                        className:'col-md-2 mb-2',
                                        id:'formReport2',
                                        filtro:false
                                    }
                                }
                            />
                        <div className="col-md-2 mb-3">
                            <label htmlFor="startDate" className="col-form-label  text-sm-right">Fecha de asistencia</label>
                            <input type="date" className="form-control" id="startDate" name="startDate" placeholder="Seleccione la fecha de asistencia" value={formData.startDate} onChange={handleChange} required />
                        </div>
                        <div className="col-md-2 mb-1 margin-top-45">
                                <button onClick={generarCosolidadReporteTotal} className="ml-2">Buscar</button>
                        </div>
                </div>
          </div>
          {showLoading && <Loading />}

          {!showLoading && asistencias.length>0 && (

                    <div className="">
                    <div className="card acta-container">
                        <div className="card-body">

                                {/* Cabezera */}
                                <HeaderActa />                
                                {/* Ficha de Incidencia */}
                                <section className="incidencia-info">
                                <div class="info-header">
                                    <h5 class="section-title">ASISTENCIA ESCOLAR</h5>
                                </div>
                                <div class="info-body">
                                    
                                    <div class="info-item">
                                        <span class="info-title-boletin">Año escolar </span>
                                        <span class="info-content-boletin"> {datosBusqueda.year}</span>
                                    </div>
                                    <div class="info-item">
                                        <span class="info-title-boletin">Fecha de asistencia </span>
                                        <span class="info-content-boletin"> {moment(datosBusqueda.startDate).format('DD-MM-YYYY')}</span>
                                    </div>
                                    <div class="info-item">
                                        <span class="info-title-boletin">Jornada </span>
                                        <span class="info-content-boletin"> {datosBusqueda.journey}</span>
                                    </div>
                                    <div class="info-item">
                                        <span class="info-title-boletin">Periodo </span>
                                        <span class="info-content-boletin"> {datosBusqueda.periodName}</span>
                                    </div>
                                    <div class="info-item">
                                        <span class="info-title-boletin">Fecha de impresion </span> 
                                        <span class="info-content-boletin"> {moment().format('DD-MM-YYYY')}</span>
                                    </div>
                                    <div class="info-item">
                                        <span class="info-title-boletin">Curso </span>
                                        <span class="info-content-boletin"> {datosBusqueda.course}</span>
                                    </div>
                                
                                </div>
                                </section>
                                {/* Implicados */}
                                <section className="incidencia-implicados">
                                        <h5 className="section-title">Consolidado de asistencia</h5>
                                        <table className="tabla-implicados">
                                        <thead>
                                            <tr>
                                                <th>No.</th>
                                                <th>Asignatura</th>
                                                <th>Total asistencias</th>
                                                <th>Total inasistencias</th>
                                                <th>Total excusas</th>
                                                <th>Total llegadas tarde</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {subjects?.map((subject, index) => (
                                            <tr key={index}>
                                                <td>{index+1}</td>
                                                <td>{subject.name}</td>
                                                <td>{asistencias.find(a=>a.schoolSubjectName === subject.name)?.assists??'0'}</td>
                                                <td>{asistencias.find(a=>a.schoolSubjectName === subject.name)?.noAssists??'0'}</td>
                                                <td>{asistencias.find(a=>a.schoolSubjectName === subject.name)?.excuses??'0'}</td>
                                                <td>{asistencias.find(a=>a.schoolSubjectName === subject.name)?.lates??'0'}</td>
                                            </tr>
                                            ))}
                                        </tbody>
                                        </table>
                                    </section>

                                <section className="incidencia-implicados">
                                    <h5 className="section-title">Total</h5>
                                    <table className="tabla-implicados">
                                    <thead>
                                        <tr>
                                            <th>Total asistencias</th>
                                            <th>Total inasistencias</th>
                                            <th>Total excusas</th>
                                            <th>Total llegadas tarde</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{(asistencias.reduce((sum, a) => {return sum + Number(a.assists??0);}, 0))}</td>
                                            <td>{(asistencias.reduce((sum, a) => {return sum + Number(a.noAssists??0);}, 0))}</td>
                                            <td>{(asistencias.reduce((sum, a) => {return sum + Number(a.excuses??0);}, 0))}</td>
                                            <td>{(asistencias.reduce((sum, a) => {return sum + Number(a.lates??0);}, 0))}</td>
                                        </tr>
                                    </tbody>
                                    </table>
                                </section>
                        
                        <FooterActa
                        reportTypeId={types.reportTypes.ASISTENCIA}></FooterActa></div>
                        
                    </div>
                    
                                <br />
                                    <button className="btn btn-primary center display-block" onClick={() => window.print()}>Imprimir</button>
                                <br />
                    </div>

          )}
        </>
    );
  };
  
  export default RepoAsistenciaTotalEstudiante;