import { round } from "mathjs";
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { toast } from "react-toastify";

const formatNumber = {
    separador: ".", // separador para los miles
    sepDecimal: ',', // separador para los decimales
    formatear: function (num) {

        const number = 123456.789;
        const formatter = new Intl.NumberFormat(navigator.language);
        const formattedNumber = formatter.format(number);
        const decimalSymbol = formattedNumber.match(/[\s\S](.)(?=\d{2}$)/)[1];
        this.sepDecimal = decimalSymbol;
        this.sepDecimal = (decimalSymbol==="7")?",":".";
        this.separador = (decimalSymbol===".")?",":".";
        num += '';
        var splitStr = num.split(this.separador);
        var splitLeft = splitStr[0];
        var splitRight = splitStr.length > 1 ? this.sepDecimal + (splitStr[1]+"  ").substring(0,2) : '';
        var regx = /(\d+)(\d{3})/;
        while (regx.test(splitLeft)) {
            splitLeft = splitLeft.replace(regx, '$1' + this.separador + '$2');
        }
        return this.simbol + splitLeft + splitRight;
    },
    new: function (num, simbol) {
        this.simbol = simbol || '';
        return this.formatear(num);
    }
}

const getUserInformation = ()=> {
    const token = localStorage.getItem('token');
    if(token){
        const base64Url = token.split('.')[1]; // Obtiene la parte del payload
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const payload = decodeURIComponent(atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
        const information = JSON.parse(payload);
        return information; 
    }
    return null;
}

const getStudentInformation = () => {
  const sdata = localStorage.getItem('_student');
	if(sdata){
		const ddata = atob(sdata);
		const student =  JSON.parse(ddata);
		return student;
	}
  return null;
}

function removeDuplicates(array) {
    const uniqueObjects = new Set();
    array.forEach(arr => uniqueObjects.add(JSON.stringify(arr)));
    const newArray = Array.from(uniqueObjects);
    return newArray.map((e)=> JSON.parse(e));
}

function copy (obj) {
    let result;
    if (obj instanceof Array) {
      result = [ ...obj ];
    } else if (typeof obj === 'object') {
      result = {...obj}
    } else {
      return obj;
    }
    for (let prop of Reflect.ownKeys (result)) {
      result[ prop ] = copy (result[ prop ]);
    }
    return result;
}

function roundNumber(number, decimals){
    var floating = parseFloat(number);
    var amount = 10 * parseInt(decimals);
    if (amount === 0) amount = 1;
    let result = Math.round(floating * amount) / amount;
    result = round(result, decimals);
    return result;
}

function getChildIndex(child) {
    var parent = child.parentNode;
    var children = parent.children;
    var i = children.length - 1;
    for (; i >= 0; i--) {
        if (child === children[i]) {
            break;
        }
    }
    return i;
}

function moveCursorToEnd(el) {
    if (typeof el.selectionStart == "number") {
        el.selectionStart = el.selectionEnd = el.value.length;
    } else if (typeof el.createTextRange != "undefined") {
        el.focus();
        var range = el.createTextRange();
        range.collapse(false);
        range.select();
    }
}

var exportTableToExcel = (function() {
    const uri = 'data:application/vnd.ms-excel;base64,'
      , template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><meta http-equiv="content-type" content="application/vnd.ms-excel; charset=UTF-8"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>'
      , base64 = function(s) { return window.btoa(unescape(encodeURIComponent(s))) }
      , format = function(s, c) { return s.replace(/{(\w+)}/g, function(m, p) { return c[p]; }) }
    return function(table, name) {
      if (!table.nodeType) table = document.getElementById(table)
      const data = table.innerHTML.replaceAll("$","").replaceAll(".","");
      const ctx = {worksheet: name || 'Hoja1', table: data}
      const downloadLink = document.createElement("a");
      downloadLink.href = uri + base64(format(template, ctx));
      downloadLink.download = name;
      downloadLink.click();
      //window.location.href = uri + base64(format(template, ctx));
    }
  })()

  var exportHtmlToExcel = (function() {
    const uri = 'data:application/vnd.ms-excel;base64,'
      , template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><meta http-equiv="content-type" content="application/vnd.ms-excel; charset=UTF-8"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>'
      , base64 = function(s) { return window.btoa(unescape(encodeURIComponent(s))) }
      , format = function(s, c) { return s.replace(/{(\w+)}/g, function(m, p) { return c[p]; }) }
    return function(html, name) {
      const data = html.replaceAll("$","").replaceAll(".","");
      const ctx = {worksheet: name || 'Hoja1', table: data}
      const downloadLink = document.createElement("a");
      downloadLink.href = uri + base64(format(template, ctx));
      downloadLink.download = name;
      downloadLink.click();
      //window.location.href = uri + base64(format(template, ctx));
    }
  })()

  var exportTableToExcelWithPoints = (function() {
    const uri = 'data:application/vnd.ms-excel;base64,'
      , template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><meta http-equiv="content-type" content="application/vnd.ms-excel; charset=UTF-8"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>'
      , base64 = function(s) { return window.btoa(unescape(encodeURIComponent(s))) }
      , format = function(s, c) { return s.replace(/{(\w+)}/g, function(m, p) { return c[p]; }) }
    return function(table, name) {
      if (!table.nodeType) table = document.getElementById(table)
      const data = table.innerHTML.replaceAll("$","");
      const ctx = {worksheet: name || 'Hoja1', table: data}
      const downloadLink = document.createElement("a");
      downloadLink.href = uri + base64(format(template, ctx));
      downloadLink.download = name;
      downloadLink.click();
      //window.location.href = uri + base64(format(template, ctx));
    }
  })()

function exportTableToExcel2(tableID, filename = ''){
    var downloadLink;
    var dataType = 'application/vnd.ms-excel';
    var tableSelect = document.getElementById(tableID);
    var tableHTML = tableSelect.outerHTML.replace(/ /g, '%20');

    // var base64data = "base64," + $.base64.encode(excelFile); OLD LINE
    //var base64data = "base64," + base64(excelFile); // New line with new base64

    
    // Specify file name
    filename = filename?filename+'.xls':'excel_data.xls';
    
    // Create download link element
    downloadLink = document.createElement("a");
    
    document.body.appendChild(downloadLink);
    
    if(navigator.msSaveOrOpenBlob){
        var blob = new Blob(['ufeff', tableHTML], {
            type: dataType
        });
        navigator.msSaveOrOpenBlob( blob, filename);
    }else{
        // Create a link to the file
        downloadLink.href = 'data:' + dataType + ', ' + tableHTML;
    
        // Setting the file name
        downloadLink.download = filename;
        
        //triggering the function
        downloadLink.click();
    }
}

const generateColumnsToExporExcel = (element)=> {
    const properties = Object.getOwnPropertyNames(element);
    const columnsAmount = properties.length;
    const abecedario = ["A","B","C","D","E","F","G","H","I","J","K","L","M","N","O","P","Q","R","S","T","U","V","W","X","Y","Z"];
    //["A1", "B1","C1","D1","E1", "F1","G1","H1"]; // Debes ajustar esto a la cantidad de columnas que tienes
    let result = [];
    let index=0;
    let reptir=1;
    for (let i = 0; i < columnsAmount; i++) {
        index=((index===columnsAmount)?0:index);
        let letra = "";
        for (let j = 0; j < reptir; j++) {
            letra = letra + abecedario[index];    
        }
        result.push(letra+"1");
        index=index+1;
        if(index===columnsAmount){
            reptir=reptir+1;
        }
    }
    return result;
}

const exportDataToExcel = (dataMapped, fileName) => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    
    if(dataMapped.length>0)
    {
      const firstElement = dataMapped[0];
      const ws = XLSX.utils.json_to_sheet(dataMapped);
      const header = generateColumnsToExporExcel(firstElement);
        header.forEach(cell => {
          if(ws[cell])
          ws[cell].s = {
            font: {
              bold: true
            }
          };
        });
      
        // Calcular el ancho máximo de cada columna (esto es un ejemplo simple)
        const colWidths = header.map(col => {
          return Math.max(
            ...dataMapped.map(row => row[ws[col].v.toString()].toString().length),
            ws[col].v.toString().length
          );
        });

        // Establecer el ancho de las columnas
        ws['!cols'] = colWidths.map(width => ({ wch: width }));
        // Agregar AutoFilter
        const rango = (header[0]+":"+header[header.length-1]);
        ws['!autofilter'] = { ref: rango + (dataMapped.length + 1) };
          
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, fileName + fileExtension);
    }else{
      toast.info("No se encontraron datos para exportar.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });
    }
}

export default {
    formatNumber,
    getUserInformation,
    getStudentInformation,
    removeDuplicates,
    copy,
    roundNumber,
    getChildIndex,
    moveCursorToEnd,
    exportTableToExcel,
    exportDataToExcel,
    exportHtmlToExcel,
    exportTableToExcelWithPoints
};