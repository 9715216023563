import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import "../css/estudiantes.css";
import {
    MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
} from 'mdb-react-ui-kit';
import AcademicOrganizationForm from "../components/Forms/organizacionAcademicaForm";  // Asegúrate de crear este componente basado en el formulario anterior
import Swal from "sweetalert2";
import organizacionAcademicaService from "../services/organizacionAcademicaService";
import useCustomDataTableStyles from "../components/estilosDeDataTable";

const AcademicOrganization = () => {

    const [fullscreenXlModal, setFullscreenXlModal] = useState(false);
    const [organizations, setOrganizations] = useState([]);
    const [selectedOrganization, setSelectedOrganization] = useState(null);
    const [filterText, setFilterText] = useState('');
    const [BtnVer, setBtnVer] = useState('');

    const toggleShow = () => {
        setFullscreenXlModal(!fullscreenXlModal);
        setSelectedOrganization(null); 
    }
    
    
    const openAddingModal = async (curso) => {
        setSelectedOrganization(curso);
        setFullscreenXlModal(true);
        setBtnVer(false);
    };
    const deleteAcademicOrganizationById = async(organization) => {
        Swal.fire({
            title: 'Esta seguro que desea eliminar este curso?',
            text: "",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, eliminar!'
          }).then(async(result) => {
            if (result.isConfirmed) {
                try{
            await organizacionAcademicaService.deleteAcademicOrganizationById(organization.academicSettingId);  // Asegúrate de tener un método deleteOrganizationById en academicOrganizationService
            Swal.fire(
                'Felicidades!',
                'Curso eliminado exitosamente!',
                'success'
              );

              fetchOrganizations();
        }catch(error){
          Swal.fire(
              "Ha ocurrido un error",
              "Por favor intentelo mas tarde",
              "error"
          );
        }
      }
    })
}

    const fetchOrganizations = async () => {
        try {
            const response = await organizacionAcademicaService.getAcademicOrganizations();  // Asegúrate de tener un método getOrganizations en academicOrganizationService
            setOrganizations(response);
        } catch (error) {
            console.error("Error al obtener las organizaciones:", error);
        }
    };

    useEffect(() => {
        fetchOrganizations();
    }, []);

    const columns = [
        { 
            name: "N°", 
            selector: "number", 
            sortable: true, 
            cell: (row, index) => <span>{index + 1}</span>, 
            grow:0.05,
        },
        {
            name: 'Asignatura',
            selector: row => row.schoolSubjectName, // Asegúrate de que `name` sea una propiedad de tus objetos de organización
            sortable: true,
            grow:3,
        },
        {
            name: 'Grado/Curso',
            selector: "gradeName", // Asegúrate de que `description` sea una propiedad de tus objetos de organización
            cell: (row, index) => <span>{row.gradeName} - {row.courseName}</span>, 
            sortable: true,
            grow:2,
        },
        {
            name: 'Año',
            selector: row => row.schoolYear, // Asegúrate de que `description` sea una propiedad de tus objetos de organización
            sortable: true,
        },
        {
            name: 'Docente',
            selector: row => row.teacherName, // Asegúrate de que `name` sea una propiedad de tus objetos de organización
            sortable: true,
            grow:3.5,
        },
        
        // ... más columnas según los datos que tienes ...
        {
            name: 'Acciones',grow:2.5,
            cell: (row) => (
                <>
                    <div className="btn-group btn-group-sm">
                   <button
                    onClick={() => deleteAcademicOrganizationById(row)}  // Cambié deletePeriodo a deleteCurso
                    className="btn btn-outline-danger me-1"
                    title="Eliminar"
                >
                    <i className="icon-deactivate fas fa-times"></i>
                </button></div>
                </>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];

    const normalizeString = (str) => {
        return str
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toLowerCase();
      };

    const filteredItems = organizations.filter((item) => {
        const allData = `${item.number} ${item.gradeName} ${item.name} ${item.isActive} ${item.teacherName}`; // Asegúrate de que estas propiedades coincidan con las de tus objetos de organización
        const normalizedFilterText = normalizeString(filterText);
        const normalizedItemName = normalizeString(allData);
        return normalizedItemName.includes(normalizedFilterText);
    });

    const customStyles = useCustomDataTableStyles();

      const paginationPerPage = 50; // Ajusta este número según tus necesidades

    return (
        <div className="col-md-12">
  <div className="card">
    <div className="card-header">
      <h2 className="">Organización Académica</h2>
      <h5 className="card-subtitle text-muted">Esta es la vista de la organización académica en donde se asigna un profesor a cada asignatura.</h5>
    </div>
    <div className="card-body">
      <form className="row mb-3">
        <div className="col-md-6 col-xl-4 mb-2 mb-md-0">
          <input
            type="text"
            placeholder="Buscar"
            value={filterText}
            onChange={e => setFilterText(e.target.value)}
            className="form-control"
          />
        </div>
        <div className="col-md-6 col-xl-8">
          <div className="text-end">
            <button onClick={() => openAddingModal(null)} className="btn btn btn-primary mb-2">
              Asignar asignatura <i className="fa-solid fa-circle-plus"></i>
            </button>
          </div>
        </div>
      </form>
    </div>

            <DataTable
                title="Lista de asignaturas asignadas"
                className="students-datatable shadow-sm rounded"
                columns={columns}
                noDataComponent="No hay información que mostrar, por favor añadela"   
                data={filteredItems}
                pagination
                  fixedHeader
                  fixedHeaderScrollHeight="450px"
                  customStyles={customStyles}
                  paginationPerPage={paginationPerPage} // Agrega esta propiedad para controlar las filas por página
                  paginationRowsPerPageOptions={[50, 100]}

            />
            <>
                <MDBModal tabIndex='-1' show={fullscreenXlModal} setShow={setFullscreenXlModal}>
                    <MDBModalDialog className="modal-xl-custom">
                        <MDBModalContent>
                            <MDBModalHeader>
                                <MDBModalTitle>DETALLES DE LA ORGANIZACIÓN</MDBModalTitle>
                                <MDBBtn
                                    type='button'
                                    className='btn-close'
                                    color='none'
                                    onClick={toggleShow}
                                ></MDBBtn>
                            </MDBModalHeader>
                            <MDBModalBody>
                                <div className='card'>
                                    {selectedOrganization && (
                                        <AcademicOrganizationForm
                                            key={Date.now()}
                                            organization={selectedOrganization.organizationId}
                                            onClose={() => {
                                                setFullscreenXlModal(false);
                                                fetchOrganizations();
                                            }}
                                            ReadOnly={BtnVer}
                                        />
                                    )}

                                    {!selectedOrganization && (
                                        <AcademicOrganizationForm
                                            key={Date.now()}
                                            organization={0}
                                            onClose={() => {
                                                setFullscreenXlModal(false);
                                                fetchOrganizations();
                                            }}
                                            ReadOnly={false}
                                        />
                                    )}
                                </div>
                            </MDBModalBody>
                            <MDBModalFooter>
                                <button type='button' className='btn-footer-close btn-primary' onClick={toggleShow}>
                                    Cerrar
                                </button>
                            </MDBModalFooter>
                        </MDBModalContent>
                    </MDBModalDialog>
                </MDBModal>
            </>
        </div></div>
    );
};

export default AcademicOrganization;
