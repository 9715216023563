import React, { useEffect, useState } from "react";
import "../../css/StudentForm.css";
import userProfileImage from "../../img/user-profile.png";
import collectionsServices from "../../services/collectionsService";
import types from "../../services/types";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { Await } from "react-router-dom";
import docentesService from "../../services/docentesService";
import Loading from "../Loading";
import documentService from "../../services/documentService";

function TeacherForm(props) {
  const { teacher, onClose, hideBtnInscription, ReadOnly } = props; // Definir el estado para gestionar los datos del formulario.
  
  const initData = {
    teacherId: 0,
    showImageProfile: userProfileImage,
    profilePicture: null,
    identification: "", // Identificación del docente
    idType: "", // Tipo de identificación
    firstName: "", // Primer nombre
    secondName: "", // Segundo nombre
    firstSurname: "", // Primer apellido
    secondSurname: "", // Segundo apellido
    gender: "", // Género
    email: "", // Email
    hiringDate: "", // Fecha de contratación
    phone: "", // Teléfono fijo
    cellPhone: "", // Celular 
    birthDate: "", // Fecha de nacimiento
    address: "", // Dirección
    city: "", // Ciudad
    isActive: true, // Estado activo o inactivo
    signatureImage:null,
    showSignatureImage:null,
};
const [formData, setFormData] = useState(initData); // Asumiendo que tienes una estructura de datos inicial para los docentes
const [showLoading, setShowLoading] = useState(true);
const [identificationTypes, setIdentificationTypes] = useState([]);
const [sexTypes, setSexTypes] = useState([]);
const [teacherSignature, setTeacherSignature] = useState(null);
const [hideSignarute, setHideSignature] = useState(true);

useEffect(() => {
  const fetchCollections = async () => {
    try {
      const idTypesRes = await collectionsServices.getCollection(
        types.collections.IDENTIFICATION_TYPE
      );
      setIdentificationTypes(idTypesRes);
      const sexTypesRes = await collectionsServices.getCollection(
        types.collections.SEX
      );
      setSexTypes(sexTypesRes);
      // Añadir aquí el código para recuperar cualquier dato inicial que necesites para el formulario de docentes.
      if (teacher) {
        const respTeacher = await docentesService.getDocente(teacher); // Asumiendo que tienes un servicio para recuperar detalles del docente
        const signatures = await documentService.getDocumentsToCustomId(types.documentTypes.TEACHER_SIGNATURE, teacher);
        if (respTeacher.profilePicture) {
          setFormData((prevState) => ({
            ...prevState,
            showImageProfile: respTeacher.profilePicture,
          }));
        }
        if(signatures && signatures.length > 0){
          hideSignatureImage(false);
          const item = signatures[0];
          setTeacherSignature(item);
          setFormData((prevState) => ({
            ...prevState,
            showSignatureImage: item.url,
          }));
        }else{
          hideSignatureImage(true);
        }
        setFormData((prevState) => ({ ...prevState, ...respTeacher }));
      }
      setShowLoading(false);
    } catch (error) {
      console.error("Error al obtener listas de formulario:", error);
    }
  };

  fetchCollections();
}, [teacher]);

const handleFileChange = (e) => {
  const file = e.target.files[0];
  if (file) {
    const reader = new FileReader();
    reader.onload = (event) => {
      if(e.target.id==="btnProfile"){
        document.getElementById("profileImage").src = event.target.result;
        setFormData((prevState) => ({ ...prevState, profilePicture: file }));
      }
      if(e.target.id==="btnSignature"){
        hideSignatureImage(false);
        document.getElementById("signatureImage").src = event.target.result;
        setFormData((prevState) => ({ ...prevState, signatureImage: file }));
      }
    };
    reader.readAsDataURL(file);
  }
}

const hideSignatureImage = (hideImage) =>{
  setHideSignature(hideImage);
  const signatureContent = document.getElementById("signatureContent");
  if(signatureContent){
    signatureContent.className =(hideImage)?"profile-picture mt-4 display-none":"profile-picture mt-4";
  }
}

const handleInputChange = (event) => {
  const { name, value } = event.target;
  setFormData((prevState) => ({ ...prevState, [name]: value }));
};

const handleSubmit = async (event) => {
  event.preventDefault();

    if (
      !formData.profilePicture ||
      !formData.identification || // Identificación del docente
      !formData.idType || // Tipo de identificación
      !formData.firstName || // Primer nombre
      !formData.firstSurname || // Primer apellido
      !formData.email || // Email
      !formData.hiringDate || // Fecha de contratación
      !formData.cellPhone // Celular 
    ) {
      toast.error("Por favor, completa todos los campos obligatorios.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }
    if (!ReadOnly) {
      setShowLoading(true);
      try {
        let teacherId = 0;
        if (formData.teacherId > 0) {
          const response = await docentesService.updateDocente(formData);
          teacherId = response.teacherId;
        } else {
          const response = await docentesService.createDocente(formData);
          teacherId = response.teacherId;
        }
        
        if (
          formData.profilePicture &&
          formData.profilePicture !== formData.showImageProfile &&
          teacherId > 0
        ) {
          
            try {
              const imgResponse = await docentesService.subirImagenDocente(
                formData.profilePicture,
                teacherId
              );
              console.log(imgResponse);
            } catch (error) {
              console.error(
                "Hubo un error al subir imagen del docente:",
                error
              );
            }
        }


        if(formData.signatureImage &&
          formData.signatureImage !== formData.showSignatureImage &&
          teacherId > 0){

          try {
            const imgResponse = await documentService.addDocument(
              types.documentTypes.TEACHER_SIGNATURE,
              formData.signatureImage,
              teacherId,
              formData.signatureImage.name,
              formData.signatureImage.size,
              formData.signatureImage.type
            );
            console.log(imgResponse);
          } catch (error) {
            console.error(
              "Hubo un error al subir la firma del docente:",
              error
            );
          }

          if(teacherSignature!=null){
            await documentService.removeDocument(teacherSignature.documentId);
          }
      }
        
        Swal.fire(
          "Felicidades!",
          "Información del docente guardada con éxito!",
          "success"
        );
        
        if (onClose) onClose();
        setFormData(initData); // Asumiendo que tienes un estado inicial para docentes
      } catch (error) {
        console.error("Hubo un error al guardar la información del docente:", error);
        Swal.fire(
          "Ha ocurrido un error",
          "Por favor intentelo mas tarde",
          "error"
        );
      }
      setShowLoading(false);
    };
  }
    
  return (
    <>
      {showLoading && <Loading />}

      {!showLoading && (
        <form onSubmit={handleSubmit}>
          <div className="card-inf containerForm">
            <div className="card-header bg-light text-center">
            <h5 className="card-title mb-0">
            <br />
                INFORMACIÓN DEL DOCENTE
                <br />
            </h5>
        </div>
        <div className="card-body">
            <div className="row">
                <div className="col-md-4">
                    <label className="col-form-label  text-sm-right">
                        Identificación del docente*
                    </label>
                    <input
                        type="text"
                        className="form-control validarForm"
                        name="identification"
                        value={formData.identification}
                        onChange={handleInputChange}
                        title="Por favor, ingrese solo números."
                        readOnly={ReadOnly}
                        required
                    />
                </div>
                {/* Tipo de identificación */}
                <div className="col-md-4">
                  <label className="col-form-label  text-sm-right">Tipo de Identidad*</label>
                  <select
                    className="form-control form-select validarForm"
                    name="idType"
                    value={formData.idType}
                    onChange={handleInputChange}
                    readOnly={ReadOnly}
                    required
                  >
                    <option value="" disabled={ReadOnly}>
                      Seleccione
                    </option>
                    {identificationTypes.map((option) => (
                      <option
                        key={option.id}
                        value={option.id}
                        disabled={ReadOnly}
                      >
                        {option.text}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="col-md-4 d-flex flex-column align-items-center">
                    <div
                        className="profile-picture bg-light mt-4"
                        style={{
                            width: "auto",
                            height: "150px",
                            overflow: "hidden",
                        }}
                    >
                        <img
                            id="profileImage"
                            src={formData.showImageProfile}
                            alt="Foto de perfil"
                            style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                            }}
                        />
                    </div>
                    {!ReadOnly  && (
                    <label className="btn btn-primary">
                        Subir Foto de perfil:*
                        <input
                            type="file"
                            id="btnProfile"
                            className="form-control d-none"
                            onChange={handleFileChange}
                            readOnly={ReadOnly}
                        />
                    </label>
                    )}
                </div>
                <div className="col-md-3">
                    <label className="col-form-label  text-sm-right">Primer Nombre*</label>
                    <input
                        type="text"
                        className="form-control validarForm"
                        name="firstName"
                        title="Por favor, ingrese solo letras."
                        value={formData.firstName}
                        onChange={handleInputChange}
                        readOnly={ReadOnly}
                        required
                    />
                </div>
                <div className="col-md-3">
                    <label className="col-form-label  text-sm-right">Segundo Nombre</label>
                    <input
                        type="text"
                        className="form-control validarForm"
                        name="secondName"
                        title="Por favor, ingrese solo letras."
                        value={formData.secondName}
                        onChange={handleInputChange}
                        readOnly={ReadOnly}
                    />
                </div>
                <div className="col-md-3">
                    <label className="col-form-label  text-sm-right">Primer Apellido*</label>
                    <input
                        type="text"
                        className="form-control validarForm"
                        name="firstSurname"
                        value={formData.firstSurname}
                        onChange={handleInputChange}
                        readOnly={ReadOnly}
                        required
                    />
                </div>
                <div className="col-md-3">
                    <label className="col-form-label  text-sm-right">Segundo Apellido</label>
                    <input
                        type="text"
                        className="form-control validarForm"
                        name="secondSurname"
                        value={formData.secondSurname}
                        onChange={handleInputChange}
                        readOnly={ReadOnly}
                    />
                </div>
                {/* Género */}
                <div className="col-md-3">
                  <label className="col-form-label  text-sm-right">Sexo*</label>
                  <select
                    className="form-control form-select validarForm"
                    name="gender"
                    value={formData.gender}
                    onChange={handleInputChange}
                    readOnly={ReadOnly}
                    required
                  >
                    <option value="" disabled={ReadOnly}>
                      Seleccione
                    </option>
                    {sexTypes.map((option) => (
                      <option
                        key={option.id}
                        value={option.id}
                        disabled={ReadOnly}
                      >
                        {option.text}
                      </option>
                    ))}
                  </select>
                </div>
                {/* Fecha de contratación */}
                <div className="col-md-3">
                    <label className="col-form-label  text-sm-right">Fecha de contratación*</label>
                    <input
                        type="date"
                        className="form-control validarForm"
                        name="hiringDate"
                        value={formData.hiringDate}
                        onChange={handleInputChange}
                        readOnly={ReadOnly}
                        required
                    />
                </div>
                {/* Celular */}
                <div className="col-md-3">
                    <label className="col-form-label  text-sm-right">
                        Celular*
                    </label>
                    <input
                        type="tel"
                        className="form-control validarForm"
                        name="cellPhone"
                        value={formData.cellPhone}
                        onChange={handleInputChange}
                        readOnly={ReadOnly}
                        required
                    />
                </div>
                
                 {/* Teléfono */}
                 <div className="col-md-3">
                    <label className="col-form-label  text-sm-right">
                        Teléfono
                    </label>
                    <input
                        type="tel"
                        className="form-control validarForm"
                        name="phone"
                        value={formData.phone}
                        onChange={handleInputChange}
                        readOnly={ReadOnly}
                    />
                </div>
                 {/* Dirección */}
                 <div className="col-md-3">
                    <label className="col-form-label  text-sm-right">
                        Dirección
                    </label>
                    <input
                        type="text"
                        className="form-control validarForm"
                        name="address"
                        value={formData.address}
                        onChange={handleInputChange}
                        readOnly={ReadOnly}
                    />
                </div>
                {/* Fecha de nacimiento */}
                <div className="col-md-3">
                    <label className="col-form-label  text-sm-right">
                        Fecha de nacimiento*
                    </label>
                    <input
                        type="date"
                        className="form-control validarForm"
                        name="birthDate"
                        value={formData.birthDate}
                        onChange={handleInputChange}
                        readOnly={ReadOnly}
                        required
                    />
                </div>
                {/* Email */}
                <div className="col-md-3">
                    <label className="col-form-label  text-sm-right">
                        Email*
                    </label>
                    <input
                        type="email"
                        className="form-control validarForm"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                        readOnly={ReadOnly}
                        required
                    />
                </div>
                 {/* Ciudad */}
                 <div className="col-md-3">
                    <label className="col-form-label  text-sm-right">
                        Ciudad*
                    </label>
                    <input
                        type="text"
                        className="form-control validarForm"
                        name="city"
                        value={formData.city}
                        onChange={handleInputChange}
                        readOnly={ReadOnly}
                        required
                    />
                </div>

                {/* Activo */}
                <div className="col-md-8 mb-3 padding-top-20 padding-left-10">
                    <input type="checkbox" className="form-check-input" id="isActive" name="isActive" checked={formData.isActive} onChange={e => setFormData({...formData, isActive: e.target.checked})}  disabled={ReadOnly} />
                    <label className="form-check-label" htmlFor="isActive">Activo</label>
                </div>

                <div className="col-md-4 d-flex flex-column align-items-center mt-4">
                    
                    <div id="signatureContent"
                        className={(hideSignarute)?"profile-picture mt-4 display-none":"profile-picture mt-4"}
                        style={{
                            
                            height: "150px",
                            overflow: "hidden",
                        }}
                    >
                        <img
                            id="signatureImage"
                            src={formData.showSignatureImage}
                            alt="Firma del docente"
                            style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                            }}
                        />
                    </div>

                    {!ReadOnly  && (
                    <label className="btn btn-primary">
                       Subir firma <i class="fa-solid fa-file-signature"></i> 
                        <input
                            type="file"
                            id="btnSignature"
                            className="form-control d-none"
                            onChange={handleFileChange}
                            readOnly={ReadOnly}
                        />
                    </label>
                    )}
                </div>


                <div className="d-flex justify-content-center mb-4">
                 
                  {!ReadOnly  && (
                    <button
                      type="submit"
                      className="btn btn-primary mb-3"
                    >
                      Guardar docente
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </form>
      )}
    </>
  );
}

export default TeacherForm;
