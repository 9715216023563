import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import "../../css/estudiantes.css";
import academicService from "../../services/academicService";


const JornadaAnioFilter = forwardRef((props, ref) => {

    const { callback, properties, ReadOnly } = props; 
    const [journeyList, setJourneyList] = useState([]);
    const [schoolYearList, setSchoolYearList] = useState([]);
    const [years, setYears] = useState([]);
    let yearsList = [];
    const initData ={
        schoolYearId:'0',
        journeyId:'0'
    };
    const [formData, setFormData] = useState(initData);

    useImperativeHandle(ref, () => ({
      setFormValues(data) {
        setFormData(prevState => ({
          ...prevState,
          ...data
      }));
      },
      getSchoolYears(){
        return years;
      },
      getJourneys(){
          return journeyList;
      }
    }));

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
        if (name.includes("journeyId")){    
          const cmbJourney = (properties && properties.id)?`cmbJourney-${properties.id}`:'cmbJourney';     
          LoadYears(document.getElementById(cmbJourney).value);
        }
    };

    useEffect(() => {
        if(callback){
            callback(formData);
        }
      }, [formData]);
      
    const fetchSchoolYears = async () => {
        try {
            const yearsResp = await academicService.getYears();
            setSchoolYearList(yearsResp);
            setYears(yearsResp);
        } catch (error) {
            console.error("Error al obtener listas de formulario:", error);
        }
    };

    const fetchLoadFiltersSchoolYears = async () => {
      try {
          const journeyResp = await academicService.getJourneys();
          setJourneyList(journeyResp);
          
          yearsList  = await academicService.getYears();
          setSchoolYearList(yearsList);
          setYears(yearsList);
      } catch (error) {
          console.error("Error al obtener listas de formulario:", error);
      }
    };

    const LoadYears = (journeyId) => {
      if(journeyId !== "0"){

        if (yearsList.length > 0) {
          const yrs = schoolYearList.filter((op) => String(op.journeyId) === journeyId);
          setYears(yrs);
        } else if (schoolYearList.length > 0) {
          const yrs = schoolYearList.filter((op) => String(op.journeyId) === journeyId);
          setYears(yrs);
        }
      }else{
        fetchSchoolYears();
      }
      setFormData(prevState => ({
        ...prevState,
        schoolYearId: '0'
      }));
    };

    useEffect(() => {
      fetchLoadFiltersSchoolYears();
    }, []);

  return (
    <>
        <div className={(properties && properties.className)?properties.className:"col-md-2 mb-2"} >
            <label className="col-form-label  text-sm-right">Jornada:</label>
            <select
            id={(properties && properties.id)?`cmbJourney-${properties.id}`:"cmbJourney"}
            className="form-control form-select validarForm"
            name="journeyId"
            value={formData.journeyId}
            onChange={handleChange}
            disabled={ReadOnly}
            >
            <option value="0"> {(properties && !properties.filtro)?'Seleccione':'Todos'}</option>
            {journeyList.map((option) => (
                <option key={option.journeyId} value={option.journeyId}>
                {option.name}
                </option>
            ))}
            </select>
        </div>
        <div className={(properties && properties.className)?properties.className:"col-md-2 mb-2"}>
                <label htmlFor="schoolYearId" className="col-form-label  text-sm-right">Año escolar</label>
                <select className="form-control form-select" 
                id={(properties && properties.id)?`schoolYearId-${properties.id}`:"schoolYearId"}
                name="schoolYearId" 
                value={formData.schoolYearId} 
                onChange={handleChange} 
                disabled={ReadOnly}>
                    <option value="0" > {(properties && !properties.filtro)?'Seleccione':'Todos'}</option>
                    {years.map((schoolYear) => (
                        <option key={schoolYear.schoolYearId} value={schoolYear.schoolYearId} >
                            {schoolYear.year}
                        </option>
                    ))}
                </select>
        </div>
    </>
    );
});

export default JornadaAnioFilter;