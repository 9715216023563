import React, { useState, useEffect } from 'react';
import academicAreaService from '../../services/academicAreaService'; // Asegúrate de que este servicio está implementado
import Swal from 'sweetalert2';
import Loading from '../Loading'; // Asumiendo que existe este componente
import { toast } from 'react-toastify';
import types from '../../services/types';

const FormAcademicArea = (props) => {
    const { academicAreaItem, onClose, ReadOnly } = props;
    const initData = {
        academicAreaId: 0,
        name: "",
        abbreviation: "",
        order: 0,
        isActive: false,
        evaluationTypeId: types.evaluationTypes.ESCALA_VALORATIVA // Valor predeterminado, ajustar según necesidad
    };

    const [formData, setFormData] = useState(initData);
    const [showLoading, setShowLoading] = useState(true);

    const handleChange = (e) => {
        const { name, value, checked, type } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    useEffect(() => {
        if (academicAreaItem) {
            const fetchAcademicArea = async () => {
                setShowLoading(true);
                try {
                    const response = await academicAreaService.getAcademicAreaById(academicAreaItem);
                    setFormData(response);
                } catch (error) {
                    console.error("Error al obtener información del área académica:", error);
                }
                setShowLoading(false);
            };

            fetchAcademicArea();
        } else {
            setFormData(initData);
            setShowLoading(false);
        }
    }, [academicAreaItem]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validación básica
        const requiredFields = ['name', 'order', 'evaluationTypeId'];
        for (let field of requiredFields) {
            if (!formData[field]) {
                toast.error("Por favor, completa todos los campos obligatorios.", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                return;
            }
        }

        setShowLoading(true);
        try {
            let response;
            if (formData.academicAreaId > 0) {
                response = await academicAreaService.updateAcademicArea(formData);
            } else {
                response = await academicAreaService.addAcademicArea(formData);
            }
            if (response && response.academicAreaId) {
                Swal.fire("¡Éxito!", "Área académica guardada con éxito.", "success");
                if (onClose) onClose();
                setFormData(initData);
            } else {
                Swal.fire("Error", "No se pudo guardar el área académica, inténtelo más tarde.", "error");
            }
        } catch (error) {
            console.error('Error al enviar el formulario:', error);
            Swal.fire("Error", "No se pudo guardar el área académica, inténtelo más tarde.", "error");
        }
        setShowLoading(false);
    };

    return (
        <>
            {showLoading && <Loading />}
            {!showLoading && (
                <div className="container-fluid mt-4">
                    <p className="text-muted">Por favor, completa todos los campos para registrar o editar un área académica.</p>
                    <form onSubmit={handleSubmit} className="mt-3 ">
                        <div className="row">
                            <div className="col-md-6 mb-3">
                                <label htmlFor="name" className="col-form-label  text-sm-right">Nombre</label>
                                <input type="text" className="form-control" id="name" name="name" 
                                    readOnly={ReadOnly} value={formData.name} onChange={handleChange} required />
                            </div>
                            <div className="col-md-6 mb-3">
                                <label htmlFor="abbreviation" className="col-form-label  text-sm-right">Abreviación</label>
                                <input type="text" className="form-control" id="abbreviation" name="abbreviation" 
                                    readOnly={ReadOnly} value={formData.abbreviation} onChange={handleChange} />
                            </div>
                            <div className="col-md-6 mb-3">
                                <label htmlFor="order" className="col-form-label  text-sm-right">Orden</label>
                                <input type="number" className="form-control" id="order" name="order" 
                                    readOnly={ReadOnly} value={formData.order} onChange={handleChange} required />
                            </div>
                            <div className="col-md-6 mb-3">
                                <div className="form-check">
                                    <input type="checkbox" className="form-check-input" id="isActive" name="isActive"
                                        checked={formData.isActive} onChange={handleChange} disabled={ReadOnly} />
                                    <label className="form-check-label" htmlFor="isActive">Activo</label>
                                </div>
                            </div>
                        </div>
                        {!ReadOnly && (
                            <div className="row">
                                <div className="col-md-1 center">
                                    <button type="submit" className="btn btn-primary">Guardar</button>
                                </div>
                            </div>
                        )}
                    </form>
                </div>
            )}
        </>
    );
}

export default FormAcademicArea;
