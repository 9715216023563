import React, { useEffect, useState } from 'react';
import asignaturaService from '../../services/asignaturaService';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import Loading from '../Loading';
import academicAreaService from '../../services/academicAreaService';
import types from '../../services/types';


const AsignaturaForm = (props) => {
    const { asignatura, onClose, ReadOnly }=props;
    const initData={
        schoolSubjectId:0,
        evaluationTypeId:0,
        academicAreaId:0,
        hourlyintensity:null,
        percentage:null,
        name: "",
        orden: "",
        abbreviation: "",
        isActive: false
    };

    const [formData, setFormData] = useState(initData);
    const [showLoading, setShowLoading] = useState(true);
    const [academicAreasList, setAcademicAreasList] = useState([]);
    const [academicAreas, setAcademicAreas] = useState([]);    
    let areasList = [];
    
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
        if(name==="evaluationTypeId"){
            LoadAcademicAreas(value);
            if(value!=="2"){
                setFormData(prevState => ({
                    ...prevState,
                    [name]: value,
                    hourlyintensity:null,
                    percentage:null,
                }));
            }
        }
    };

    const LoadAcademicAreas = async (evaluationTypeId) => {
        if(evaluationTypeId !== "0"){
            if(areasList.length>0){
                const data = areasList.filter((areas) =>  String(areas.evaluationTypeId) === evaluationTypeId);
                setAcademicAreas(data);   
            }else if(academicAreasList.length>0){
                const data = academicAreasList.filter((areas) =>  String(areas.evaluationTypeId) === evaluationTypeId);
                setAcademicAreas(data);   
            }
        }else{
            if(areasList.length>0){
                setAcademicAreas(areasList);   
            }else if(academicAreasList.length>0){
                setAcademicAreas(academicAreasList);   
            }
            setFormData(prevState => ({
            ...prevState,
            academicAreaId: '0'
            }));
        }
    };

    const fetchAcademicAreas = async () => {
        try{
            const allData = await academicAreaService.getAllAcademicAreas();
            setAcademicAreasList(allData);
            areasList = allData;
            LoadAcademicAreas('0');
        } catch (error) {
            console.error("Error al obtener listas de formulario:", error);
        }
    }

    const fetchCollections = async () => {
        await fetchAcademicAreas();
        try {
          if (asignatura) {
            const resAsignatura = await asignaturaService.getAsignatura(asignatura);
            LoadAcademicAreas(String(resAsignatura.evaluationTypeId));
            setFormData((prevState) => ({ ...prevState, ...resAsignatura }));
          }
        } catch (error) {
          console.error("Error al obtener listas de formulario:", error);
        }
        setShowLoading(false);
    };

    useEffect(() => {    
        fetchCollections();
      }, []);
    
      const handleSubmit = async (e) => {
        e.preventDefault();

        // Validación básica
        const requiredFields = ['name', 'orden', 'abbreviation','evaluationTypeId','academicAreaId'];
        if(String(formData.evaluationTypeId)===String(types.evaluationTypes.ESCALA_VALORATIVA)){
            requiredFields.push('hourlyintensity');
            requiredFields.push('percentage');
        }
        for (let field of requiredFields) {
            if (!formData[field]) {
                toast.error("Por favor, completa todos los campos obligatorios.", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                  });
                return;
            }
        }

        if(!ReadOnly)
        {
          setShowLoading(true);
          try {
              let asignaturaId = 0;
              if (formData.schoolSubjectId > 0) {
                  const response = await asignaturaService.updateAsignatura(formData);
                  asignaturaId = response.schoolSubjectId;
              } else{
                  const response = await asignaturaService.createAsignatura(formData);
                  asignaturaId = response.schoolSubjectId;
              }
              if (asignaturaId) {
                  Swal.fire(
                      "Felicidades!",
                      "Información guardada con éxito!",
                      "success",
                      );
                    if (onClose) onClose();
                    setFormData(initData);
              } else {
                  Swal.fire(
                      "Ha ocurrido un error",
                      "Por favor intentelo mas tarde",
                      "error"
                    );
              }
          } catch (error) {
              console.error('Error al enviar el formulario:', error);
              Swal.fire(
                  "Ha ocurrido un error",
                  "Por favor intentelo mas tarde",
                  "error"
                );
          }
        }
        setShowLoading(false);
    };

    return (
         <>
        {showLoading && <Loading />}

        {!showLoading && (
            <div className="container-fluid mt-4">
            <p className="text-muted">Por favor, llena todos los campos para registrar la nueva asignatura académica.</p>
            
            <form onSubmit={handleSubmit} className="mt-3">
                <div className="row">
                <div className="col-md-3 mb-3">
                        <label htmlFor="name" className="col-form-label  text-sm-right">Nombre</label>
                        <input type="text" readOnly={ReadOnly} className="form-control" id="name" name="name" placeholder="Ingrese el nombre de la asignatura" value={formData.name} onChange={handleChange} required />
                    </div>

                    <div className="col-md-3 mb-3">
                        <label htmlFor="evaluationTypeId" className="col-form-label  text-sm-right">Tipo de evaluación:</label>
                        <select className="form-control form-select" id="evaluationTypeId" name="evaluationTypeId" 
                            value={formData.evaluationTypeId} onChange={handleChange}>
                            <option value="0" >Seleccione</option>
                            <option value="1" >DIMENSIÓN VALORATIVA</option>
                            <option value="2" >ESCALA VALORATIVA</option>
                        </select>
                    </div>
                    
                    <div className="col-md-3 mb-3">
                        <label htmlFor="academicAreaId" className="col-form-label  text-sm-right">{formData.evaluationTypeId==="1"?"Dimension academica":"Area academica"} :</label>
                        <select className="form-control form-select" 
                                id="academicAreaId"
                                name="academicAreaId" 
                                value={formData.academicAreaId} 
                                onChange={handleChange} 
                                disabled={ReadOnly}>
                                    <option value="0" >Seleccione</option>
                                    {academicAreas.map((academicArea) => (
                                        <option key={academicArea.academicAreaId} value={academicArea.academicAreaId} >
                                            {academicArea.name}
                                        </option>
                                    ))}
                        </select>
                    </div>
                    <div className="col-md-3 mb-3">
                        <label htmlFor="orden" className="col-form-label  text-sm-right">Orden</label>
                        <input type="number" readOnly={ReadOnly} className="form-control" id="orden" name="orden" placeholder="Ingrese el orden" value={formData.orden} onChange={handleChange} required />
                    </div>

                    <div className="col-md-3 mb-3">
                        <label htmlFor="startDate" className="col-form-label  text-sm-right">Abreviatura</label>
                        <input type="text" readOnly={ReadOnly} className="form-control" id="abbreviation" name="abbreviation" placeholder="Ingrese la abreviación de la asignatura" value={formData.abbreviation} onChange={handleChange} required />
                    </div>

                    {String(formData.evaluationTypeId)===String(types.evaluationTypes.ESCALA_VALORATIVA)  && (
                        <div className="col-md-3 mb-3">
                            <label htmlFor="orden" className="col-form-label  text-sm-right">Intensidad Horaria</label>
                            <input type="number" readOnly={ReadOnly} className="form-control" id="hourlyintensity" name="hourlyintensity" placeholder="Intensidad horaria" value={formData.hourlyintensity} onChange={handleChange} required />
                        </div>
                    )}

                    {String(formData.evaluationTypeId)===String(types.evaluationTypes.ESCALA_VALORATIVA)  && (
                        <div className="col-md-3 mb-3">
                            <label htmlFor="orden" className="col-form-label  text-sm-right">Procentaje del area academica</label>
                            <input type="number" readOnly={ReadOnly} className="form-control" id="percentage" name="percentage" placeholder="Porcentaje del area academica" value={formData.percentage} onChange={handleChange} required />
                        </div>
                    )}

                    <div className="col-md-3 chk-container">
                        <div className="form-check margin-top-30">
                                <input type="checkbox" disabled={ReadOnly} className="form-check-input" id="isActive" name="isActive" checked={formData.isActive} onChange={e => setFormData({...formData, isActive: e.target.checked})} />
                                <label className="form-check-label" htmlFor="isActive">Activo</label>
                         </div>
                    </div>
                </div>

                
                {!ReadOnly  && (
                    <div className='row'>
                        <div className='col-md-1 center padding-top-20 padding-bottom-20'>
                            <button type="submit" className="btn btn-primary">Guardar</button>
                        </div>
                    </div>
                )}
            </form>
            </div>
        )}
        </>
    );
}

export default AsignaturaForm;
