import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import Loading from '../Loading';
import organizacionAcademicaService from '../../services/organizacionAcademicaService';
import courseService from '../../services/courseService';
import asignaturaService from '../../services/asignaturaService';
import docentesService from "../../services/docentesService";
import gradesService from "../../services/gradesService";
import academicService from '../../services/academicService';

const AcademicOrganizationForm = (props) => {
    const { onClose, ReadOnly } = props;
    const initData = {
        schoolSubjectId: "",
        schoolYearId:"",
        courseId: "",
        teacherId: "",
        gradeId: "",
    };

    const [formData, setFormData] = useState(initData);
    const [schoolYearList, setSchoolYearList] = useState([]);
    const [gradesList, setGradesList] = useState([]);
    const [subjectsList, setSubjectsList] = useState([]);
    const [coursesList, setCoursesList] = useState([]);
    const [courses, setCourses] = useState([]);
    const [teachersList, setTeachersList] = useState([]);
    const [showLoading, setShowLoading] = useState(true);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value,
        }));
        if(name==="courseId"){
            const course = courses.find(c=> String(c.courseId) === value);
            if(course){
                setFormData(prevState => ({
                    ...prevState,
                    [name]: value,
                    gradeId:course.gradeId
                }));    
            }
        }
        if(name==="gradeId"){
            const cursos = courses.filter(c=> String(c.gradeId) === value);
            setCoursesList(cursos);
            setFormData(prevState => ({
                ...prevState,
                [name]: value,
                courseId:((cursos.length===1)?cursos[0].courseId:"")
            }));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validación básica
        const requiredFields = ['gradeId', 'schoolSubjectId','courseId','teacherId', 'schoolYearId'];
        for (let field of requiredFields) {
            if (!formData[field]) {
                Swal.fire(
                    "Campos Obligatorios",
                    "Por favor, completa todos los campos obligatorios.",
                    "error"
                );
                return;
            }
        }

        if (!ReadOnly) {
            setShowLoading(true);
            try {
                const response = await organizacionAcademicaService.createAcademicOrganization(formData);
                const academicSettingId = response.academicSettingId;
                if (academicSettingId) {
                    Swal.fire(
                        "Felicidades!",
                        "Curso guardado con éxito!",
                        "success"
                    );
                    if (onClose) onClose();
                    setFormData(initData);
                } else {
                    Swal.fire(
                        "Ha ocurrido un error",
                        "Por favor intentelo mas tarde",
                        "error"
                    );
                }
            } catch (error) {
                console.error('Error al enviar el formulario:', error);
                Swal.fire(
                    "Ha ocurrido un error",
                    "Por favor intentelo mas tarde",
                    "error"
                );
            }
            setShowLoading(false);
        }
    };
    useEffect(() => {
        const fetchCollections = async () => {
          try {
            const yearsResp = await academicService.getYears();
            setSchoolYearList(yearsResp);
            
            const repCoursesList = await courseService.getCursos();            
            setCourses(repCoursesList);
            setCoursesList(repCoursesList);

            const repGradesList = await gradesService.getAllGrades();
            setGradesList(repGradesList);

            const respDocente = await docentesService.getDocentes();
            setTeachersList(respDocente);

            const respAsignatura = await asignaturaService.getAsignaturas();
            setSubjectsList(respAsignatura);



            setShowLoading(false);
          } catch (error) {
            console.error("Error al obtener listas de formulario:", error);
          }
        };
    
        fetchCollections();
      }, []);
      

    return (
        <>
        {showLoading && <Loading />}
        {!showLoading && (
            <div className="container-fluid mt-4">
                <p className="text-muted">
                Por favor, llena todos los campos para registrar una nueva organización académica.
                </p>
                <form onSubmit={handleSubmit} className="mt-3">
                    <div className="row">

                        <div className="col-md-6 mb-3">
                            <label className="col-form-label  text-sm-right">Seleccione Grado:*</label>
                            <select
                                className="form-control form-select"
                                name="gradeId"
                                value={formData.gradeId}
                                onChange={handleChange}
                                required
                            >
                                <option value="" disabled>Seleccione un grado</option>
                                {gradesList.map((grade) => (
                                    <option key={grade.gradeId} value={grade.gradeId}>
                                        {grade.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="col-md-6 mb-3">
                            <label className="col-form-label  text-sm-right">Seleccione Curso:*</label>
                            <select
                                className="form-control form-select"
                                name="courseId"
                                value={formData.courseId}
                                onChange={handleChange}
                                required
                            >
                                <option value="" disabled>Seleccione un curso</option>
                                {coursesList.map((course) => (
                                    <option key={course.courseId} value={course.courseId}>
                                        {course.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="col-md-6 mb-3">
                            <label className="col-form-label  text-sm-right">Seleccione Asignatura:*</label>
                            <select
                                className="form-control form-select"
                                name="schoolSubjectId"
                                value={formData.schoolSubjectId}
                                onChange={handleChange}
                                required
                            >
                                <option value="" disabled>Seleccione una asignatura</option>
                                {subjectsList.map((subject) => (
                                    <option key={subject.schoolSubjectId} value={subject.schoolSubjectId}>
                                        {subject.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="col-md-2 mb-2">
                                <label htmlFor="schoolYearId" className="col-form-label  text-sm-right">Año escolar:</label>
                                <select className="form-control form-select"  id="schoolYearId" name="schoolYearId" 
                                value={formData.schoolYearId} 
                                onChange={handleChange} 
                                disabled={ReadOnly}>
                                    <option value="" >Seleccione un año escolar</option>
                                    {schoolYearList.map((schoolYear) => (
                                        <option key={schoolYear.schoolYearId} value={schoolYear.schoolYearId} >
                                            {schoolYear.year}
                                        </option>
                                    ))}
                                </select>
                        </div>

                        <div className="col-md-6 mb-3">
                            <label className="col-form-label  text-sm-right">Seleccione Docente:*</label>
                            <select
                                className="form-control form-select"
                                name="teacherId"
                                value={formData.teacherId}
                                onChange={handleChange}
                                required
                            >
                                <option value="" disabled>Seleccione un docente</option>
                                {teachersList.map((teacher) => (
                                    <option key={teacher.teacherId} value={teacher.teacherId}>
                                        {teacher.fullName}
                                    </option>
                                ))}
                            </select>
                        </div>

                  
                    </div>

                    {!ReadOnly && (
                        <div className='row'>
                            <div className='col-md-1 center padding-top-20 padding-bottom-20'>
                                <button type="submit" className="btn btn-primary">Guardar</button>
                            </div>
                        </div>
                    )}
                </form>
            </div>
         )}
        </>
    );
}

export default AcademicOrganizationForm;
